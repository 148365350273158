import React, { useContext, useState } from "react";
import { useTheme } from "@mui/material/styles";
import { useNavigate } from "react-router-dom";
import { Snackbar, Alert } from "@mui/material";
import Consultant from "../components/SelectEndUserComponents/Consultant";
import NewOrgDialog from "../components/SelectEndUserComponents/NewOrgDialog";
import { UserProfileContext } from "../context/UserContext";
import {
  apiService,
  claimAllThePackages,
  createPurchaseNode,
} from "../utils/utils";
import Loader from "../components/Loader";

const SelectEndUser = ({ prefix }) => {
  const theme = useTheme();
  const navigate = useNavigate();
  //const { state } = useLocation();
  const {
    userProfile,
    orgList,
    interestedBuyerGuides,
    isProfileLoading,
    notification,
    handleCloseNotification,
    setNotification,
  } = useContext(UserProfileContext);
  const [, setMessage] = useState("");
  const [isLoading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [orgName, setOrgName] = useState("");
  const [industryName, setIndustryName] = useState("");
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [updateUi, setUpdateUi] = useState(false);
  const user = userProfile || {};

  const handleIndustryInputChange = (event) => {
    setIndustryName(event.target.value);
  };

  const handleOrgInputChange = (event) => {
    setOrgName(event.target.value);
  };

  const handleOrgSubmit = async () => {
    let know = false;
    orgList.forEach((value) => {
      if (value?.orgname === orgName) {
        know = true;
      }
    });

    if (!know) {
      await AddNewEndUserOrganization();
      setOrgName("");
      setIndustryName("");
      setOpen(false);
    } else {
      setNotification({
        message: "Organization name already exists or is empty.",
        severity: "warning",
        open: true,
      });
    }
  };

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  const handleChangeProfile = () => {
    navigate(`${prefix}/users`);
  };
  const AddNewEndUserOrganization = async () => {
    apiService(
      `https://newtestfuncpython.azurewebsites.net/api/AddEndUserOrganisation?`,
      {
        method: "POST",
        data: {
          email: user?.email,
          new_organisation: orgName,
          new_industry: industryName,
        },
      },
      setLoading,
      setMessage,
      (err) => {
        console.log("Error in adding new EndUserOrganization", err);
      }
    ).then((data) => {
      if (prefix === "/demo") {
        apiService(
          "https://newtestfuncpython.azurewebsites.net/api/saveAndContinueBuyerGuides?",
          {
            method: "POST",
            data: {
              email: userProfile?.email,
              currentOrganisation: data?.currentOrganisationId,
              available_tab_checkbox_states: {
                "4:525b76d3-e272-4bc1-b9a4-573178e548ed:2078": true,
              },
            },
          }
        );

        const packages = [
          {
            packageType: "All Available",
            customerId: 1552,
            packageQuantity: 1,
            subscriptionDuration: "Monthly",
            subscriptionIDs: ["sub6T7U8V"],
            discount: 10,
            purchaseDate: "15/12/2024",
            coupon: "SAVE10",
            promotion: "Holiday Sale",
            discountedPricePerUnit: 45.0,
            listPricePerUnit: 50.0,
          },
        ];

        createPurchaseNode(
          "https://newtestfuncpython.azurewebsites.net/api/createPurchasePackageNode",
          userProfile?.email,
          packages
        ).then((res) => {
          console.log("response", res);
          const combination = [
            {
              id: "4:525b76d3-e272-4bc1-b9a4-573178e548ed:2078",
              PackageDetails: { PackageType: "All Available", Match: "NO" },
            },
          ];
          claimAllThePackages(
            userProfile?.email,
            data?.currentOrganisationId,
            combination,
            "https://newtestfuncpython.azurewebsites.net/api/claimPurchasedPackage?"
          );
        });
      }
      setNotification({
        open: true,
        message: data?.message,
        severity: "success",
      });
      setUpdateUi(!updateUi);
    });
  };
  const profileType = user?.profileType;
  if (profileType === "endUser") {
    if (!isProfileLoading) {
      if (interestedBuyerGuides && interestedBuyerGuides.length > 0) {
        navigate(`${prefix}/select-buyer-guide/buyer-guide-of-interest`);
      } else {
        navigate(`${prefix}/select-buyer-guide/available-buyer-guides`);
      }
    } else {
      return (
        // <Box mt={4} textAlign="center">
        <Loader data-testid="loading" open={isProfileLoading} />
        // </Box>
      );
    }
  }

  if (profileType !== "endUser" && !isProfileLoading) {
    return (
      <>
        <Consultant
          theme={theme}
          handleChangeProfile={handleChangeProfile}
          setOpen={setOpen}
          orgName={orgName}
          industryName={industryName}
          updateUi={updateUi}
          setUpdateUi={setUpdateUi}
          prefix={prefix}
        />

        <NewOrgDialog
          open={open}
          onClose={() => setOpen(false)}
          orgName={orgName}
          industryName={industryName}
          onOrgInputChange={handleOrgInputChange}
          onIndustryInputChange={handleIndustryInputChange}
          onOrgSubmit={handleOrgSubmit}
          AddNewEndUserOrganization={AddNewEndUserOrganization}
          isLoading={isLoading}
        />

        <Snackbar
          open={snackbarOpen}
          autoHideDuration={6000}
          onClose={handleSnackbarClose}
          anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
        >
          <Alert
            onClose={handleSnackbarClose}
            severity="warning"
            sx={{ width: "100%" }}
          >
            Organization name already exists or is empty.
          </Alert>
        </Snackbar>
        <Snackbar
          open={notification.open}
          autoHideDuration={6000}
          onClose={handleCloseNotification}
        >
          <Alert
            onClose={handleCloseNotification}
            severity={notification.severity}
          >
            {notification.message}
          </Alert>
        </Snackbar>
      </>
    );
  } else {
    return (
      // <Box mt={4} textAlign="center">
      //   <CircularProgress />
      // </Box>
      <Loader open={true} />
    );
  }
};

export default SelectEndUser;

//  [
//   {
//     emailID: "user1@gmail.com",
//     bgid1: [vendorid(s)],
//     bg2: [vendorid(s)],
//   },
//   {
//     emailID: "user2@gmail.com",
//     bgid: [vendorid(s)],
//     bg2: [vendorid(s)],
//   },
// ];
