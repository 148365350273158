import React, { useContext, useEffect, useMemo, useCallback } from "react";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import { Button, Tooltip } from "@mui/material";
import theme from "../../theme/theme";
import { AllocationContext } from "./AllocationContext";
import { calculateOptionList, getPlaceholder } from "./DropDownUtils.js";

const DropDown = React.memo(({ params, isDisabled, setSectionData, inputsLabel, setPopover, setPopoverContent, setAllocatedData }) => {
  const [optionList, setOptionList] = React.useState([]);
  const [maxLimit, setMaxLimit] = React.useState(null);
  const [placeholder, setPlaceHolder] = React.useState("#");
  const [value, setValue] = React.useState(null);
  const { setSelectedRowData } = useContext(AllocationContext);

  useEffect(() => {
    setPlaceHolder(getPlaceholder(params));
  }, [params]);

  useEffect(() => {
    const { options, maxValue } = calculateOptionList(params);
    setOptionList(options);
    setMaxLimit(maxValue);
  }, [params]);

  const handleChange = useCallback(
    (e, newValue) => {
      setValue(newValue);
      if (params?.field === "totalUnits" && !!newValue) {
        setAllocatedData((prevData) => {
          const updatedData = [...prevData];
          const index = updatedData.findIndex((item) => item.id === params?.row?.id);
          const purchasedPackageName = params?.row?.purchasedPackageName;
          const allocationType = params?.row?.allocationType;

          if (index !== -1) {
            updatedData[index] = {
              ...updatedData[index],
              totalUnits: newValue,
              purchasedPackages: purchasedPackageName,
              allocationType: allocationType,
            };
          } else {
            updatedData.push({ id: params?.row?.id, totalUnits: newValue, purchasedPackages: purchasedPackageName, allocationType: allocationType });
          }
          return updatedData;
        });
      }
      setSectionData((prevData) => prevData.map((packag) => (packag.id === params?.row?.id ? { ...packag, [inputsLabel]: newValue } : packag)));
    },
    [params, inputsLabel, setAllocatedData, setSectionData]
  );

  const handleClearIndicatorClick = useCallback(() => {
    setValue(null);
    setAllocatedData((prevData) => {
      const updatedData = [...prevData];
      const index = updatedData.findIndex((item) => item.id === params?.row?.id);

      if (index !== -1) {
        updatedData[index] = {
          ...updatedData[index],
          [fieldMapForAllocatedData[inputsLabel]]: inputsLabel === "totalUnitsInput" ? null : [],
        };
      }

      return updatedData;
    });

    setSectionData((prevData) => prevData.map((packag) => (packag.id === params?.row?.id ? { ...packag, [inputsLabel]: null } : packag)));
  }, [params, inputsLabel, setAllocatedData, setSectionData]);

  const renderOption = useCallback(
    (props, option) => {
      const { key, ...optionProps } = props;

      return (
        <li key={key} {...optionProps} style={{ padding: "0px" }}>
          <Button
            style={{
              width: "100%",
              backgroundColor: "transparent",
              font: theme.typography.fontFamily,
              color: "black",
            }}
            disableRipple
            onClick={() => {
              if (params?.field !== "totalUnits" && !!option) {
                setPopoverContent({ ...params, option });
                setPopover(true);
                if (params?.field === "buyerGuideUnitsTotal") {
                  setSelectedRowData(params?.row);
                }
              }
            }}
          >
            {option.toString()}
          </Button>
        </li>
      );
    },
    [params, setPopover, setPopoverContent, setSelectedRowData]
  );

  const autocompleteProps = useMemo(
    () => ({
      disablePortal: true,
      disabled: isDisabled,
      options: optionList,
      getOptionLabel: (option) => option.toString(),
      value,
      onChange: handleChange,
      renderOption,
      renderInput: (params) => <TextField {...params} label={!!maxLimit ? `Max value upto ${maxLimit}` : ""} placeholder={placeholder} />,
      ListboxProps: {
        sx: {
          maxHeight: "15vh",
        },
      },
      slotProps: {
        clearIndicator: {
          onClick: handleClearIndicatorClick,
        },
      },
    }),
    [isDisabled, optionList, value, maxLimit, placeholder, handleChange, renderOption, handleClearIndicatorClick]
  );

  return (
    <Tooltip
      title={isDisabled ? "Please Enter Highlighted Field First" : ""}
      slotProps={{
        tooltip: {
          sx: { background: theme.palette.secondary.main },
        },
      }}
    >
      <Autocomplete
        {...autocompleteProps}
        sx={{
          flex: 1,
          "& .MuiInputLabel-root": {
            color: isDisabled ? "gray" : "black",
          },
        }}
      />
    </Tooltip>
  );
});

export default DropDown;

const fieldMapForAllocatedData = {
  buyerGuideUnitsInput: "bgList",
  endUserOrganizationUnitsInput: "euoList",
  totalUnitsInput: "totalUnits",
  userUnitsInput: "userList",
};
