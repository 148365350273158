import React, { useContext, useState } from "react";
import {
  Box,
  Typography,
  Button,
  Snackbar,
  ThemeProvider,
  useTheme,
} from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import theme from "../theme/theme";
// import { UserProfileContext } from "../context/UserContext";
import { DataTable } from "./reusable/DataTable"; // Updated import for DataTable
import { UserProfileContext } from "../context/UserContext";

const BuyerGuideMappingOneToOne = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const muiTheme = useTheme();
  const { prefix } = useContext(UserProfileContext);
  const { buyerGuides } = location.state;
  // const { userProfile } = useContext(UserProfileContext);

  const [selectedRow, setSelectedRow] = useState(null);
  // const [collaboratorEmail, setCollaboratorEmail] = useState('');
  // const [openInvite, setOpenInvite] = useState(false);
  const [openSnackbar, setOpenSnackbar] = useState(false);

  // Define columns configuration
  const columns = [
    { field: "select", headerName: "Select", width: 100 },
    { field: "name", headerName: "Buyer Guide Name", flex: 1 },
    { field: "version", headerName: "Version", flex: 1 },
    { field: "status", headerName: "Status", flex: 1 },
    { field: "created_by", headerName: "Created By", flex: 1.5 },
  ];

  // Prepare row data
  const rows = buyerGuides.map((guide, index) => ({
    id: index,
    name: guide.buyerGuideName,
    version: guide.version || "N/A",
    status: guide.status || "N/A",
    created_by: guide.createdBy,
    element_id: guide.buyerGuideId,
  }));

  // Navigate to the new page
  const handleSelectBuyerGuide = () => {
    console.log("selected buyer guide:", buyerGuides[selectedRow]);
    localStorage.setItem(
      "currentbuyerGuideData",
      JSON.stringify(buyerGuides[selectedRow])
    );
    navigate(`${prefix}/vendor-offer-page-mapping`);
  };
  console.log(selectedRow);
  return (
    <ThemeProvider theme={theme}>
      <Box sx={{ height: 400, width: "100%" }}>
        <Typography
          variant="h4"
          sx={{ mb: 2 }}
          data-test-id="Buyer Guides (Analyst View)"
        >
          Buyer Guides (Analyst View)
        </Typography>
        <Box>
          {/* Use DataTable to render buyer guides */}
          <DataTable
            rows={rows}
            columns={columns}
            selectedRowId={selectedRow}
            onSelectRow={setSelectedRow} // Function to handle row selection
            getRowId={(row) => row.id} // Ensure unique row ID
            hideFooter
            sx={{
              width: "100%",
              height: "100%",
              "& .super-app-theme--header": {
                backgroundColor: muiTheme.palette.primary.main,
                color: "white",
                fontSize: "14px",
                ".MuiSvgIcon-root": { color: "white" },
              },
            }}
          />
        </Box>
        {/* Single Button for Selecting Buyer Guide */}
        <Box mt={2} display="flex" justifyContent="flex-start">
          <Button
            data-test-id="button-Select Buyer Guide"
            variant="contained"
            color="primary"
            sx={{ width: "250px" }}
            onClick={handleSelectBuyerGuide}
          >
            Select Buyer Guide
          </Button>
        </Box>
      </Box>

      {/* Snackbar for invitation success message */}
      <Snackbar
        data-test-id="message"
        open={openSnackbar}
        autoHideDuration={6000}
        onClose={() => setOpenSnackbar(false)}
        message="Invitation sent successfully!"
      />
    </ThemeProvider>
  );
};

export default BuyerGuideMappingOneToOne;
