import React, { useState, useContext, useEffect, useCallback } from "react";
import Box from "@mui/material/Box";
import { Radio, Button, IconButton, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useTheme } from "@emotion/react";
//import { reportSettingsData } from "./Utils/dummyReportSettings.js";
import VisibilityIcon from "@mui/icons-material/Visibility";
import DataGridTable from "../components/DataGridTable.jsx";
import HeaderNaming from "../components/HeaderNaming/HeaderNaming.jsx";
import { UserProfileContext } from "../context/UserContext.jsx";
import { StaticReportContext } from "../context/StaticReportContext.jsx";
import { apiService } from "../utils/utils.js";
import Loader from "../components/Loader.jsx";
const ReportSettings = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const [selectedRow, setSelectedRow] = useState(null);
  const [rowData, setRowData] = useState([]);
  const [unlockingAllowed, setUnlockingAllowed] = useState(false);
  const [loading, setLoading] = useState(true);
  const {
    userProfile,
    currentOrganization,
    buyerGuideName,
    currentPackage,
    currentOrganizationId,
    currentBuyerGuideId,
    setScenarioName,
    setCurrentScenarioId,
    prefix,
  } = useContext(UserProfileContext);
  const {
    fetchSmartDataForStaticReport,
    fetchBudgetDataForStaticReport,
    fetchPremiumDataForStaticReport,
    fetchLowDataForStaticReport,
    setValyouMatrixData,
    setInitialDataForStatic,
  } = useContext(StaticReportContext);
  const data = {
    "End-user Organisation":
      userProfile?.profileType === "endUser"
        ? userProfile?.companyName
        : currentOrganization,
    "Buyer Guide Name": buyerGuideName,
    "Purchased Package": currentPackage?.package_type,
  };

  const getData = useCallback(async () => {
    try {
      await apiService(
        "https://newtestfuncpython.azurewebsites.net/api/getScenarioListForReportSetting?",
        {
          method: "POST",
          data: {
            email: userProfile?.email,
            currentOrganisation: currentOrganizationId,
            currentBuyerGuide: currentBuyerGuideId,
            currentPackage: currentPackage?.id,
          },
        },
        setLoading,
        (reponse) => {
          setRowData(reponse);
        },
        (error) => {
          console.error("Failed to create/update users:", error);
        }
      );
    } catch (error) {
      console.log("reponse error ", error);
    } finally {
      setLoading(false);
    }
  }, [currentOrganizationId, currentBuyerGuideId, currentPackage, userProfile]);
  useEffect(() => {
    getData();
  }, [getData]);
  const fetchScenarioData = async (rowData) => {
    try {
      setLoading(true);
      const data = await apiService(
        "https://newtestfuncpython.azurewebsites.net/api/valyouMatrixWidgetComparativeAnalysis?",
        {
          method: "POST",
          data: {
            email: userProfile?.email,
            currentOrganisation: currentOrganizationId,
            currentBuyerGuide: currentBuyerGuideId,
            currentScenario: rowData?.id,
            currentPackage: currentPackage?.id,
          },
        }
      );
      localStorage.setItem("valyouMatrixData", JSON.stringify(data));
      setValyouMatrixData(data);
      setUnlockingAllowed(
        (data?.OverallOffers || []).some(
          (offer) => offer?.OfferName === "Unlock Vendor"
        ) ||
          (data?.MandatoryOffers || []).some(
            (offer) => offer?.OfferName === "Unlock Vendor"
          )
      );
      const headers = [
        ...(unlockingAllowed ? ["Select"] : []),
        "Vendor Name",
        "Status",
        "Offer Name",
        "Mandatory Status",
        "Offer Score",
        "Offer Relevance",
        "Offer Utilization",
      ];
      //data for chart (X and Y axis)
      const overallData = data?.OverallOffers?.map((offer) => {
        return unlockingAllowed
          ? [
              {
                type: offer.OfferName !== "Unlock Vendor" ? "" : "lock",
              },
              offer.VendorName,
              "MandatoryOfferRelevance" in offer ? "mandatory" : "overall",
              offer.OfferName,
              "Overall Use Cases",
              `${offer?.OverallOfferScore?.toFixed(2)}%`,
              `${offer?.OverallOfferRelevance?.toFixed(2)}%`,
              `${offer?.OverallOfferUtilisation?.toFixed(2)}%`,
              offer?.offerId,
              offer?.vendorId,
            ]
          : [
              offer.VendorName,
              offer.OfferName,
              "MandatoryOfferRelevance" in offer ? "mandatory" : "overall",
              "Overall Use Cases",
              `${offer?.OverallOfferScore?.toFixed(2)}%`,
              `${offer?.OverallOfferRelevance?.toFixed(2)}%`,
              `${offer?.OverallOfferUtilisation?.toFixed(2)}%`,
              offer?.offerId,
              offer?.vendorId,
            ];
      });
      const mandatoryData = data?.MandatoryOffers?.map((offer) => {
        return unlockingAllowed
          ? [
              {
                type: offer?.OfferName !== "Unlock Vendor" ? "" : "lock",
              },
              offer?.VendorName,
              "MandatoryOfferRelevance" in offer ? "mandatory" : "overall",
              offer?.OfferName,
              "Mandatory Use Cases",
              `${offer?.MandatoryOfferScore?.toFixed(2)}%`,
              `${offer?.MandatoryOfferRelevance?.toFixed(2)}%`,
              `${offer?.MandatoryOfferUtilisation?.toFixed(2)}%`,
              offer?.offerId,
              offer?.vendorId,
            ]
          : [
              offer?.VendorName,
              offer?.OfferName,
              "MandatoryOfferRelevance" in offer ? "mandatory" : "overall",
              "Mandatory Use Cases",
              `${offer?.MandatoryOfferScore?.toFixed(2)}%`,
              `${offer?.MandatoryOfferRelevance?.toFixed(2)}%`,
              `${offer?.MandatoryOfferUtilisation?.toFixed(2)}%`,
              offer?.offerId,
              offer?.vendorId,
            ];
      });

      const combinedData = [
        headers,
        ...(overallData ? overallData : []),
        ...(mandatoryData ? mandatoryData : []),
      ];

      const response = await apiService(
        "https://newtestfuncpython.azurewebsites.net/api/getPreviewReportData?",
        {
          method: "POST",
          data: {
            email: userProfile?.email,
            currentOrganisation: currentOrganizationId,
            currentBuyerGuide: currentBuyerGuideId,
            currentScenario: rowData?.id,
            currentPackage: currentPackage?.id,
          },
        }
      );
      await fetchSmartDataForStaticReport();
      await fetchBudgetDataForStaticReport();
      await fetchPremiumDataForStaticReport();
      await fetchLowDataForStaticReport();
      localStorage.setItem("initialDataStatic", JSON.stringify(combinedData));
      setInitialDataForStatic(combinedData);
      navigate(`${prefix}/view-reports`, {
        state: { reportdata: response[0], generateReport: false },
      });
    } catch (error) {
      console.log("error massage:", error);
    } finally {
      setLoading(false);
    }
  };
  const handleNavigation = async (rowData) => {
    if (rowData?.reportStatus === true && rowData?.urlPreview) {
      navigate(`${prefix}/show-static-report`, {
        state: { urlPreview: rowData?.urlPreview },
      });
    } else if (rowData?.reportStatus === true && !rowData?.urlPreview) {
      await fetchScenarioData(rowData);
    }
  };
  const handleRadioChange = (id) => {
    setSelectedRow(id);
    setRowData((prevRows) =>
      prevRows.map((row) => ({
        ...row,
        select: row.id === id,
      }))
    );
  };
  const handleGenerateReport = async () => {
    try {
      setLoading(true);
      await apiService(
        "https://newtestfuncpython.azurewebsites.net/api/generateReport?",
        {
          method: "POST",
          data: {
            email: userProfile?.email,
            currentOrganisation: currentOrganizationId,
            currentBuyerGuide: currentBuyerGuideId,
            currentPackage: currentPackage?.id,
            currentScenario: selectedRow,
          },
        }
      );

      const data = await apiService(
        "https://newtestfuncpython.azurewebsites.net/api/valyouMatrixWidgetComparativeAnalysis?",
        {
          method: "POST",
          data: {
            email: userProfile?.email,
            currentOrganisation: currentOrganizationId,
            currentBuyerGuide: currentBuyerGuideId,
            currentScenario: selectedRow,
            currentPackage: currentPackage?.id,
          },
        }
      );
      localStorage.setItem("valyouMatrixData", JSON.stringify(data));
      setValyouMatrixData(data);

      setUnlockingAllowed(
        (data?.OverallOffers || []).some(
          (offer) => offer?.OfferName === "Unlock Vendor"
        ) ||
          (data?.MandatoryOffers || []).some(
            (offer) => offer?.OfferName === "Unlock Vendor"
          )
      );
      const headers = [
        ...(unlockingAllowed ? ["Select"] : []),
        "Vendor Name",
        "Status",
        "Offer Name",
        "Mandatory Status",
        "Offer Score",
        "Offer Relevance",
        "Offer Utilization",
      ];
      //data for chart (X and Y axis)
      const overallData = data?.OverallOffers?.map((offer) => {
        return unlockingAllowed
          ? [
              {
                type: offer.OfferName !== "Unlock Vendor" ? "" : "lock",
              },
              offer.VendorName,
              "MandatoryOfferRelevance" in offer ? "mandatory" : "overall",
              offer.OfferName,
              "Overall Use Cases",
              `${offer?.OverallOfferScore?.toFixed(2)}%`,
              `${offer?.OverallOfferRelevance?.toFixed(2)}%`,
              `${offer?.OverallOfferUtilisation?.toFixed(2)}%`,
              offer?.offerId,
              offer?.vendorId,
            ]
          : [
              offer.VendorName,
              offer.OfferName,
              "MandatoryOfferRelevance" in offer ? "mandatory" : "overall",
              "Overall Use Cases",
              `${offer?.OverallOfferScore?.toFixed(2)}%`,
              `${offer?.OverallOfferRelevance?.toFixed(2)}%`,
              `${offer?.OverallOfferUtilisation?.toFixed(2)}%`,
              offer?.offerId,
              offer?.vendorId,
            ];
      });
      const mandatoryData = data?.MandatoryOffers?.map((offer) => {
        return unlockingAllowed
          ? [
              {
                type: offer?.OfferName !== "Unlock Vendor" ? "" : "lock",
              },
              offer?.VendorName,
              "MandatoryOfferRelevance" in offer ? "mandatory" : "overall",
              offer?.OfferName,
              "Mandatory Use Cases",
              `${offer?.MandatoryOfferScore?.toFixed(2)}%`,
              `${offer?.MandatoryOfferRelevance?.toFixed(2)}%`,
              `${offer?.MandatoryOfferUtilisation?.toFixed(2)}%`,
              offer?.offerId,
              offer?.vendorId,
            ]
          : [
              offer?.VendorName,
              offer?.OfferName,
              "MandatoryOfferRelevance" in offer ? "mandatory" : "overall",
              "Mandatory Use Cases",
              `${offer?.MandatoryOfferScore?.toFixed(2)}%`,
              `${offer?.MandatoryOfferRelevance?.toFixed(2)}%`,
              `${offer?.MandatoryOfferUtilisation?.toFixed(2)}%`,
              offer?.offerId,
              offer?.vendorId,
            ];
      });

      const combinedData = [
        headers,
        ...(overallData ? overallData : []),
        ...(mandatoryData ? mandatoryData : []),
      ];
      localStorage.setItem("initialDataStatic", JSON.stringify(combinedData));
      setInitialDataForStatic(combinedData);

      const response = await apiService(
        "https://newtestfuncpython.azurewebsites.net/api/getPreviewReportData?",
        {
          method: "POST",
          data: {
            email: userProfile?.email,
            currentOrganisation: currentOrganizationId,
            currentBuyerGuide: currentBuyerGuideId,
            currentScenario: selectedRow,
            currentPackage: currentPackage?.id,
          },
        }
      );
      await fetchSmartDataForStaticReport();
      await fetchBudgetDataForStaticReport();
      await fetchPremiumDataForStaticReport();
      await fetchLowDataForStaticReport();

      navigate(`${prefix}/view-reports`, {
        state: { reportdata: response[0], generateReport: true },
      });
      setLoading(false);
    } catch (error) {
      console.log("reponse error ", error);
    } finally {
      setLoading(false);
    }
  };
  const columns = [
    {
      field: "select",
      headerName: "",
      flex: 0.5,
      headerClassName: "super-app-theme--header",

      renderCell: (params) => {
        return params.row?.reportStatus ? (
          <IconButton
            onClick={async () => {
              localStorage.setItem("scenarioName", params.row?.["name"]);
              localStorage.setItem("currentScenarioId", params?.row["id"]); // passing scenario id
              localStorage.setItem(
                "currentScenario",
                JSON.stringify({
                  id: params.row?.["id"],
                  name: params.row?.["name"],
                  status: params.row?.["status"],
                })
              );
              console.log("PrintRow", params?.row);
              setCurrentScenarioId(params?.row.id);
              console.log("row data", params.row);
              setScenarioName(params.row?.["name"]);
              await handleNavigation(params?.row);
            }}
          >
            <VisibilityIcon color="secondary" />
          </IconButton>
        ) : (
          <Radio
            checked={params.id === selectedRow}
            onChange={() => {
              localStorage.setItem(
                "currentScenario",
                JSON.stringify({
                  id: params.row?.["id"],
                  name: params.row?.["name"],
                  status: params.row?.["status"],
                })
              );

              setCurrentScenarioId(params?.row.id);
              setScenarioName(params.row?.["name"]);
              handleRadioChange(params.id);
            }}
            value={params.id}
          />
        );
      },
    },
    {
      field: "name",
      headerClassName: "super-app-theme--header",
      headerName: "Scenario Name",
      flex: 1,
    },
    {
      field: " ",
      headerClassName: "super-app-theme--header",
      headerName: "Report Generation Status",
      flex: 1,
      renderCell: (params) => {
        return (
          <Typography variant="h7">
            {params?.row?.reportStatus
              ? params?.row?.urlPreview
                ? "Static Report"
                : "Local Report"
              : "Not Generated"}
          </Typography>
        );
      },
    },
  ];

  return (
    <Box
      flexGrow={1}
      sx={{
        "& .super-app-theme--header": {
          backgroundColor: theme.palette.primary.main,
          height: "fit-content",
        },
      }}
    >
      <HeaderNaming data={data} />
      {loading ? (
        // If loading is true, display a loading spinner inside a box.
        // <Box
        //   sx={{
        //     height: "70px",
        //     display: "flex",
        //     justifyContent: "center",
        //     alignItems: "center",
        //   }}
        // >
        //   <CircularProgress />
        // </Box>
        <Loader open={loading} />
      ) : (
        <>
          <DataGridTable
            rows={rowData}
            columns={columns}
            autoHeight
            hideFooter
          />
          <Box mt={2} display="flex" flexDirection="column" gap={2}>
            <Button
              variant="contained"
              color="primary"
              sx={{ width: "250px" }}
              onClick={
                loading
                  ? null
                  : async () => {
                      await handleGenerateReport();
                    }
              }
              disabled={selectedRow ? false : true}
            >
              {loading ? "Loading..." : "Generate Report"}
            </Button>
          </Box>
        </>
      )}
    </Box>
  );
};

export default ReportSettings;
