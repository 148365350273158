import ELK from "elkjs/lib/elk.bundled";

const elk = new ELK();

export const useElkLayout = () => {
  const getLayoutedElements = async (nodes, edges, setNodes) => {
    const graph = {
      id: "root",
      layoutOptions: {
        "elk.algorithm": "mrtree",
        "elk.direction": "DOWN", // Vertical tree layout
        "elk.mrtree.spacing.nodeNodeBetweenLayers": "120",
        "elk.interactive": true,
        "elk.mrtree.edgeRoutingMode": "AVOID_OVERLAP",
        "elk.topdown.scaleFactor": 5,
        "elk.animate": true,
      },
      children: nodes.map((node) => ({
        id: node.id,
        width: 270, // Ensure proper node width
        height: 500, // Ensure proper node height
      })),
      edges: edges.map((edge) => ({
        id: edge.id,
        sources: [edge.source],
        targets: [edge.target],
      })),
    };

    try {
      const layoutedGraph = await elk.layout(graph);

      const layoutedNodes = layoutedGraph.children.map((node) => {
        const originalNode = nodes.find((n) => n.id === node.id);
        return { ...originalNode, position: { x: node.x, y: node.y } };
      });

      setNodes(layoutedNodes);
    } catch (error) {
      console.error("ELK layout error:", error);
    }
  };

  return { getLayoutedElements };
};
