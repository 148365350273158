import React, { useState, useEffect, useContext } from "react";
import {
  Box,
  Button,
  useTheme,
  Snackbar,
  Alert as MuiAlert,
  // CircularProgress,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import CheckBox from "@mui/material/Checkbox";
import { handleFilterChange, handleFilterClose, getColumnUniqueValues, apiService } from "../utils/utils";
import { UserProfileContext } from "../context/UserContext";
import HeaderNaming from "../components/HeaderNaming/HeaderNaming.jsx";
import FilterMenu from "../components/FilterMenu";
import DataGridTable from "../components/DataGridTable.jsx";
import Loader from "../components/Loader.jsx";

import NoDataAvailable from "../components/NoDataAvilable.jsx";
const SelectBuyer = ({ prefix }) => {
  const theme = useTheme();
  const navigate = useNavigate();
  const {
    userProfile,
    currentOrganization,
    // updateUI,
    // setUpdateUI,
    // currentOrganizationId,
    notification,
    handleCloseNotification,
    currentOrg,
    fetchInterestedBuyerGuides,
  } = useContext(UserProfileContext);
  const [isLoading, setIsLoading] = useState(true);
  const [availableBuyerGuides, setAvailableBuyerGuides] = useState([]);
  const [selectedGuides, setSelectedGuides] = useState([]);
  const [selectAllChecked, setSelectAllChecked] = useState(false);
  const [filters, setFilters] = useState({});
  const [filterAnchorEl, setFilterAnchorEl] = useState(null);
  const [filterColumn, setFilterColumn] = useState("");
  const [snackbarOpen, setSnackbarOpen] = useState(false);

  const data = {
    "End-user Organisation": userProfile?.profileType === "endUser" ? userProfile?.companyName : currentOrganization,
  };

  const handleSnackbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbarOpen(false);
  };

  useEffect(() => {
    const allChecked = availableBuyerGuides.length > 0 && availableBuyerGuides.every((guide) => selectedGuides.includes(guide.id));
    setSelectAllChecked(allChecked);
  }, [availableBuyerGuides, selectedGuides]);

  const handleCheckboxChange = (id) => {
    setSelectedGuides((prevState) => (prevState.includes(id) ? prevState.filter((guideId) => guideId !== id) : [...prevState, id]));
  };

  const handleSelectAllChange = (event) => {
    const isChecked = event.target.checked;
    if (isChecked) {
      const allGuideIds = availableBuyerGuides.map((guide) => guide.id);
      setSelectedGuides(allGuideIds);
    } else {
      setSelectedGuides([]);
    }
    setSelectAllChecked(isChecked);
  };

  const returnSelectedGuides = () => {
    let available_tab_checkbox_states = {};
    for (let val of availableBuyerGuides) {
      available_tab_checkbox_states[val?.id] = selectedGuides.includes(val?.id);
    }
    return available_tab_checkbox_states;
  };

  const handleMarkAsInterested = async () => {
    if (selectedGuides.length === 0) {
      setSnackbarOpen(true);
      return;
    }

    await apiService("https://newtestfuncpython.azurewebsites.net/api/saveAndContinueBuyerGuides?", {
      method: "POST",
      data: {
        email: userProfile?.email,
        currentOrganisation: currentOrg,
        available_tab_checkbox_states: returnSelectedGuides(),
      },
    });
    await fetchInterestedBuyerGuides();

    setSelectedGuides([]); // Reset selection
    setSelectAllChecked(false); // Uncheck "Select All"
    // setUpdateUI(!updateUI);
    navigate(`${prefix}/select-buyer-guide/buyer-guide-of-interest`);
  };

  useEffect(() => {
    if (currentOrg) {
      apiService(
        `https://newtestfuncpython.azurewebsites.net/api/getAvailableBuyerGuides?`,
        {
          method: "POST",
          data: { email: userProfile?.email, currentOrganisation: currentOrg },
        },
        setIsLoading,
        (response) => {
          if (response && Array.isArray(response) && response.length > 0) {
            setAvailableBuyerGuides(response);
          }
        },
        (err) => {
          console.log(err);
        }
      );
    } else {
      return;
    }
  }, [userProfile, currentOrg]);

  useEffect(() => {
    if (Array.isArray(availableBuyerGuides) && availableBuyerGuides.length > 0) {
      setIsLoading(false);
    }
  }, [availableBuyerGuides]);

  const columns = [
    {
      field: "check",
      headerClassName: "super-app-theme--header",
      renderHeader: () => <CheckBox checked={selectAllChecked} onChange={handleSelectAllChange} color="secondary" sx={{ color: "white" }} />,
      renderCell: (params) => <CheckBox checked={selectedGuides.includes(params.row.id)} onChange={() => handleCheckboxChange(params.row.id)} color="secondary" />,
    },
    {
      field: "name",
      headerName: "Buyer Guide Name",
      headerClassName: "super-app-theme--header",
      flex: 1,
      renderHeader: () => <div style={{ whiteSpace: "normal", wordWrap: "break-word" }}>Buyer Guide Name</div>,
    },
    {
      field: "description",
      headerName: "Description",
      headerClassName: "super-app-theme--header",
      flex: 1,
      renderHeader: () => <div style={{ whiteSpace: "normal", wordWrap: "break-word" }}>Description</div>,
    },
  ];

  if (isLoading) {
    return (
      // <Box mt={4} textAlign="center">
      //   <CircularProgress />
      // </Box>
      <Loader data-testid="loading" open={isLoading} />
    );
  }

  return availableBuyerGuides.length > 0 ? (
    <>
      <Box sx={{ width: "100%", marginTop: 4, borderRadius: "10px" }}>
        <HeaderNaming data={data} />
        <Box
          flexGrow={1}
          sx={{
            outline: "0.1px solid gray",
            width: "100%",
            borderRadius: "20px",
            overflow: "hidden",
            "& .super-app-theme--header": {
              backgroundColor: theme.palette.primary.main,

              height: "fit-content",
            },
          }}
        >
          <DataGridTable
            columns={columns}
            rows={availableBuyerGuides}
            getRowId={(row) => row.id}
            disableSelectionOnClick
            sx={{
              minWidth: "1080px",
              "& .MuiDataGrid-columnHeaders": {
                backgroundColor: theme.palette.primary.main,
                color: "white",
                whiteSpace: "normal",
                wordWrap: "break-word",
              },
              "& .MuiDataGrid-cell": {
                wordWrap: "break-word",
                whiteSpace: "normal",
              },
              "& .MuiDataGrid-cell--editing": {
                backgroundColor: "transparent !important",
                border: "3px solid #000", // Thicker border when in edit mode
              },
              "& .MuiDataGrid-cell--editable:hover": {
                border: "2px solid #000", // Thicker border when editable cell is hovered
              },
            }}
          />
        </Box>
        <FilterMenu
          filterAnchorEl={filterAnchorEl}
          filterColumn={filterColumn}
          filters={filters}
          onFilterChange={(column, value) => handleFilterChange(column, value, setFilters)}
          onClose={() => handleFilterClose(setFilterAnchorEl, setFilterColumn)}
          getColumnUniqueValues={(column) => getColumnUniqueValues(column, availableBuyerGuides)}
        />
        <Button data-testid="save&continue" variant="contained" color="primary" sx={{ marginTop: "16px", width: "250px" }} onClick={handleMarkAsInterested}>
          Save & Continue
        </Button>
        <Snackbar open={snackbarOpen} autoHideDuration={6000} onClose={handleSnackbarClose} anchorOrigin={{ vertical: "bottom", horizontal: "left" }}>
          <MuiAlert onClose={handleSnackbarClose} severity="warning" sx={{ width: "100%" }}>
            Please select at least one guide to proceed.
          </MuiAlert>
        </Snackbar>
      </Box>
      <Snackbar open={notification.open} autoHideDuration={6000} onClose={handleCloseNotification}>
        <MuiAlert onClose={handleCloseNotification} severity={notification.severity}>
          {notification.message}
        </MuiAlert>
      </Snackbar>
    </>
  ) : (
    // If no data (rows.length === 0), display a message.
    <NoDataAvailable />
  );
};

export default SelectBuyer;
