export default function useMenu(setSelectedNode, setMenuPosition) {
  const onNodeContextMenu = (event, node) => {
    event.preventDefault();
    setSelectedNode(node);
    setMenuPosition({ x: event.clientX, y: event.clientY });
  };

  const closeMenu = () => {
    setMenuPosition(null);
  };
  return { onNodeContextMenu, closeMenu, };
}
