import React, { createContext, useCallback, useEffect, useState } from "react";
import { apiService } from "../utils/utils";
import { useMsal } from "@azure/msal-react";
import { useLocation } from "react-router-dom";

// Create the context
const UserProfileContext = createContext();

// Create a provider component
const UserProfileProvider = ({ children }) => {
  const [updateUI, setUpdateUI] = useState(false);
  const { instance, accounts, inProgress } = useMsal();
  const location = useLocation();
  const { pathname } = location;
  const pathParts = pathname.split("/");
  const prefix = `/${pathParts[1]}`;

  //profile
  const [userProfile, setUserProfile] = useState(
    JSON.parse(localStorage.getItem("user_data")) || null
  );
  const [isProfileLoading, setIsProfileLoading] = useState(true);
  const [unlockVendorCount, setUnlockVendorCount] = useState(0);
  const [isConsultant, setIsConsultant] = useState(false); //
  const [azureProfile, setAzureProfile] = useState(null);
  const [isUpdated, setUpdating] = useState(false);
  const [primaryMetric, setPrimaryMetric] = useState("offerScore");
  const [numVendors, setNumVendors] = useState(3);
  const [numOffers, setNumOffers] = useState(3);
  const [offerName, setOfferName] = useState(localStorage.getItem("offerName"));
  const [vendorName, setVendorName] = useState(
    localStorage.getItem("vendorName")
  );
  const [offerId, setOfferId] = useState(null);
  const [vendorId, setVendorId] = useState(null);
  const [thresholdType, setThresholdType] = useState("maximumNumberOfVendors");
  const [currentOrganization, setCurrentOrganization] = useState("");
  const [currentOrganizationId, setCurrentOrganizationId] = useState(null);
  const [currentBuyerGuideId, setCurrentBuyerGuideId] = useState(null);
  const [currentScenarioId, setCurrentScenarioId] = useState(null);
  const [currentPackage, setCurrentPackage] = useState(null);
  const [interestedBuyerGuides, setInterestedBuyerGuides] = useState(
    JSON.parse(localStorage.getItem("intrestedbuyer")) || []
  );
  const [interestedBuyerGuidesLoading, setInterestedBuyerGuidesLoading] =
    useState(false); //
  const [updateInterestedBuyerGuides, setUpdateInterestedBuyerGuides] =
    useState(true);
  const [loadingBuyerGuide, setIsLoading] = useState(true);
  const [buyerGuideName, setBuyerGuideName] = useState("");
  const [scenarioName, setScenarioName] = useState("");
  const [userUnits, setUserUnits] = useState(1);
  const [finalcrumbs, setFinalCrumbs] = useState([]);
  const [notification, setNotification] = useState({
    open: false,
    message: "",
    severity: "success",
  });
  const [packageCount, setPackageCount] = useState(0);
  const [orgList, setOrgList] = useState([]);

  const [availableUnitsForAllocation, setAvailableUnitsForAllocation] =
    useState([]);

  const fetchUserProfile = useCallback(
    async (email) => {
      try {
        setIsProfileLoading(true);
        const res = await apiService(
          `https://newtestfuncpython.azurewebsites.net/api/getUserProfile?`,
          { method: "POST", data: { email: email } },
          null,
          null,
          (error) => {
            console.log("Failed to fetch profile:", error);
          }
        );
        setUserProfile(res);

        localStorage.setItem("user_data", JSON.stringify(res));
        if (res?.profileType === "endUser") {
          setCurrentOrganizationId(res?.endUser_companyId);
        }
        return res;
      } finally {
        setIsProfileLoading(false);
      }
    },
    [setIsProfileLoading, setUserProfile]
  );

  const updateUserProfile = async (profileData) => {
    setIsProfileLoading(true);
    await apiService(
      "https://newtestfuncpython.azurewebsites.net/api/CreateUpdateProfile?",
      {
        method: "POST",
        data: {
          displayName: profileData?.displayName,
          email: profileData?.email,
          givenName: profileData?.givenName,
          surname: profileData?.surname,
          profileType: profileData?.profileType,
          companyName: profileData?.companyName,
          jobTitle: profileData?.jobTitle,
        },
      },
      setUpdating,
      setUserProfile,
      (error) => {
        // Handle error if needed
        console.error("Failed to create/update users:", error);
      }
    );
    const respone = await fetchUserProfile(profileData?.email);
    setIsProfileLoading(false);
    return respone;
  };

  const handleCloseNotification = () => {
    setNotification({ ...notification, open: false });
  };

  const currentOrg =
    userProfile && userProfile.profileType === "endUser"
      ? userProfile.endUser_companyId
      : currentOrganizationId;

  useEffect(() => {
    const fetchData = async () => {
      if (inProgress === "none" && accounts.length > 0) {
        try {
          const accessToken = await instance.acquireTokenSilent({
            scopes: ["https://graph.microsoft.com/User.Read"],
            account: accounts[0],
          });
          const response = await fetch("https://graph.microsoft.com/v1.0/me", {
            headers: {
              Authorization: `Bearer ${accessToken.accessToken}`,
            },
          });
          const data = await response.json();
          if (data && data.mail) {
            setAzureProfile(data);
            fetchUserProfile(data?.mail);
          }
        } catch (error) {
          console.error("Error fetching user profile from Graph API:", error);
          instance.logoutRedirect().catch((logoutError) => {
            console.error("Error during logout:", logoutError);
          });
        }
      }
    };
    fetchData();
  }, [instance, accounts, inProgress, updateUI, fetchUserProfile]);

  useEffect(() => {
    setCurrentOrganization(localStorage?.getItem("currentOrganization"));
    setBuyerGuideName(localStorage?.getItem("buyerGuideName"));
    setOfferName(localStorage?.getItem("offerName"));
    setVendorName(localStorage?.getItem("vendorName"));
    setScenarioName(localStorage?.getItem("scenarioName"));
    setOfferName(localStorage?.getItem("currentOffer"));
    setOfferId(localStorage?.getItem("currentOfferId"));
    setVendorId(localStorage.getItem("currentVendorId"));
    setCurrentOrganizationId(localStorage?.getItem("currentOrganizationId"));
    setCurrentPackage(JSON.parse(localStorage?.getItem("currentPackage")));
    setCurrentBuyerGuideId(localStorage?.getItem("currentBuyerGuideId"));
    setCurrentScenarioId(localStorage?.getItem("currentScenarioId"));
    setFinalCrumbs(JSON.parse(localStorage?.getItem("breadcrumbs")) || []);
  }, [currentOrg, scenarioName]);

  const fetchInterestedBuyerGuides = async (versionStatus) => {
    try {
      setInterestedBuyerGuidesLoading(true);
      const response = await apiService(
        `https://newtestfuncpython.azurewebsites.net/api/getInterestedBuyerGuides?`,
        {
          method: "POST",
          data: {
            email: userProfile?.email,
            currentOrganisation:
              userProfile && userProfile?.profileType === "endUser"
                ? userProfile?.endUser_companyId
                : currentOrganizationId,
            versionStatus,
          },
        },
        setIsLoading,
        null,
        (err) => {
          console.log(err);
        }
      );
      setInterestedBuyerGuides(response);

      localStorage.setItem("interestedbuyer", JSON.stringify(response));
      return response;
    } catch (e) {
      console.error("Intrested buyer guide error:", e);
    } finally {
      setInterestedBuyerGuidesLoading(false);
    }
  };

  useEffect(() => {
    if (
      currentOrganizationId ||
      (userProfile?.profileType === "endUser" && userProfile?.endUser_companyId)
    ) {
      if (prefix === "/demo") {
        fetchInterestedBuyerGuides("Demo");
      } else {
        fetchInterestedBuyerGuides();
      }
    }
    //do not remove the below to avoid warning for not including fetchInterestedBuyerGuides in dependency
    // eslint-disable-next-line
  }, [currentOrganizationId, userProfile]);

  const fetchAvailableUnitsForAllocation = async () => {
    apiService(
      `https://newtestfuncpython.azurewebsites.net/api/getAvailableUnitsForAdminAllocation?`,
      {
        method: "POST",
        data: {
          // email: "babluv613@gmail.com",
          email: userProfile?.email,
        },
      },
      setIsLoading,
      setAvailableUnitsForAllocation,
      (err) => {
        console.log(err);
      }
    );
  };
  // useEffect(() => {
  //   if (userProfile) {
  //     fetchAvailableUnitsForAllocation();
  //   }
  //   //do not remove the below to avoid warning for not including fetchInterestedBuyerGuides in dependency
  //   // eslint-disable-next-line
  // }, [userProfile]);

  // Use effect for smart data

  // Use effect for relevant vendors data

  return (
    <UserProfileContext.Provider
      value={{
        userProfile,
        updateUserProfile,
        isProfileLoading,
        azureProfile,
        updateUI,
        setUpdateUI,
        primaryMetric,
        setPrimaryMetric,
        setCurrentOrganization,
        currentOrganization,
        // purchasePackage,
        // setPurchasePackage,
        interestedBuyerGuides,
        setUpdateInterestedBuyerGuides,
        updateInterestedBuyerGuides,
        buyerGuideName,
        setBuyerGuideName,
        scenarioName,
        setScenarioName,
        numVendors,
        setNumVendors,
        numOffers,
        setNumOffers,
        thresholdType,
        setThresholdType,
        unlockVendorCount,
        setUnlockVendorCount,
        userUnits,
        setUserUnits,
        packageCount,
        setPackageCount,
        orgList,
        setOrgList,
        vendorName,
        setVendorName,
        setIsConsultant,
        isConsultant,
        interestedBuyerGuidesLoading,
        setInterestedBuyerGuides,
        setInterestedBuyerGuidesLoading,
        currentOrg,
        offerId,
        setOfferId,
        vendorId,
        setVendorId,
        fetchInterestedBuyerGuides,
        finalcrumbs,
        setFinalCrumbs,
        loadingBuyerGuide,
        offerName,
        setOfferName,
        setCurrentOrganizationId,
        currentOrganizationId,
        fetchAvailableUnitsForAllocation,
        availableUnitsForAllocation,
        isUpdated,
        notification,
        setNotification,
        handleCloseNotification,
        currentPackage,
        setCurrentPackage,
        currentBuyerGuideId,
        setCurrentBuyerGuideId,
        currentScenarioId,
        setCurrentScenarioId,
        prefix,
      }}
    >
      {children}
    </UserProfileContext.Provider>
  );
};

export { UserProfileContext, UserProfileProvider };
