import BuyerAndEUO from "./SpecificAllocation/BuyerAndEUO";
import UserUnits from "./SpecificAllocation/UserUnits";

const restrictUser = ({ row, field }) => {
  const { allocationType, totalUnitsInput, userUnitsInput, buyerGuideUnitsInput, endUserOrganizationUnitsInput } = row;

  const allocationRules = {
    UnPrescribed: {
      totalUnits: false,
      userUnitsTotal: () => !totalUnitsInput,
      endUserOrganizationUnitsTotal: () => !userUnitsInput || !totalUnitsInput,
      buyerGuideUnitsTotal: () => {
        return !userUnitsInput || !totalUnitsInput;
      },
    },
    "Fully Prescribed": {
      buyerGuideUnitsTotal: false,
      endUserOrganizationUnitsTotal: false,
      totalUnits: () => !buyerGuideUnitsInput || !endUserOrganizationUnitsInput,
      userUnitsTotal: () => !buyerGuideUnitsInput || !endUserOrganizationUnitsInput || !totalUnitsInput,
    },
    "Partially Prescribed (UCBG)": {
      buyerGuideUnitsTotal: false,
      totalUnits: () => !buyerGuideUnitsInput,
      userUnitsTotal: () => !buyerGuideUnitsInput || !totalUnitsInput,
      endUserOrganizationUnitsTotal: () => !buyerGuideUnitsInput || !totalUnitsInput || !userUnitsInput,
    },
    "Partially Prescribed (EUO)": {
      endUserOrganizationUnitsTotal: false,
      totalUnits: () => !endUserOrganizationUnitsInput,
      userUnitsTotal: () => !totalUnitsInput || !endUserOrganizationUnitsInput,
      buyerGuideUnitsTotal: () => !endUserOrganizationUnitsInput || !totalUnitsInput || !userUnitsInput,
    },
  };

  const rule = allocationRules[allocationType]?.[field];

  if (rule === undefined) return true;
  return typeof rule === "boolean" ? rule : rule();
};
const popoverMap = {
  userUnitsTotal: <UserUnits />,
  ...Object.fromEntries(
    ["endUserOrganizationUnitsTotal", "buyerGuideUnitsTotal"].map((key) => [key, <BuyerAndEUO />]) //    this component is taking  (isTableVisible, widgetLabels, widgetKey, toggleTableVisibility, handleCopyWidget, handleRemoveWidget, setLayout) as prop
  ),
};

const titles = {
  userUnitsTotal: "User Units",
  endUserOrganizationUnitsTotal: "End User Organization",
  buyerGuideUnitsTotal: "Buyer Guide",
};
const unitCheck = (popoverContent, setAllocatedData, handleClose, allocatedData,handelUserResponse) => {
  if (popoverContent?.field === "userUnitsTotal") {
    return <UserUnits id={popoverContent?.row?.id} setAllocatedData={setAllocatedData} maxFields={popoverContent.option} handleClose={handleClose} allocatedData={allocatedData} />;
  } else if (popoverContent?.field === "endUserOrganizationUnitsTotal") {
    return (
      <BuyerAndEUO
        id={popoverContent?.row?.id}
        setAllocatedData={setAllocatedData}
        quantity={popoverContent?.option}
        list={popoverContent?.row?.euoList}
        label={"End-User List"}
        handleClose={handleClose}
      />
    );
  } else if (popoverContent?.field === "buyerGuideUnitsTotal") {
    return (
      <BuyerAndEUO
        id={popoverContent?.row?.id}
        setAllocatedData={setAllocatedData}
        quantity={popoverContent?.option}
        list={popoverContent?.row?.buyerlist}
        label={"Buyer Guide list"}
        handleClose={handleClose}
        handelUserResponse={handelUserResponse}
      />
    );
  }
};

const allocationType = {
  UnPrescribed: "UnPrescribed",
  "Partially Prescribed (EUO)": "Partially Prescribed (End User Org.)",
  "Partially Prescribed (UCBG)": "Partially Prescribed (Buyer Guide)",
  "Fully Prescribed": "Fully Prescribed",
};

const limitAsPerPackage = {
  "All Available": 999,
  "Duel Dynamics": 2,
  "Focus Five": 5,
  "Solo Starter": 1,
};

export { restrictUser, popoverMap, titles, unitCheck, allocationType, limitAsPerPackage };
