import React, { useState, useEffect, useContext, useMemo, useRef } from "react";
import { useNavigate } from "react-router-dom";
import DataGridTable from "../../../components/DataGridTable";
import {
  Box,
  Chip,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  // CircularProgress,
  Divider,
} from "@mui/material";
import LockIcon from "@mui/icons-material/Lock";
import CheckIcon from "@mui/icons-material/Check";
import CheckBox from "@mui/icons-material/CheckBox";
import CheckBoxOutlineBlank from "@mui/icons-material/CheckBoxOutlineBlank";
import ChartComponent from "./ChartComponent";
import { apiService, modifySubscriptionBillingCycle } from "../../../utils/utils";
import { ComparitiveContext } from "../../../context/ComparitiveContext";
import { UserProfileContext } from "../../../context/UserContext";
import "./ComponentStyle.css";
import NoDataAvilable from "../../../components/NoDataAvilable";
import Loader from "../../../components/Loader";

import { ComparativeTableMap } from "../../Utils/helper";
// Function to convert percentage strings to integers
const parsePercentage = (value) => {
  if (typeof value === "string" && value.endsWith("%")) {
    return parseInt(value.slice(0, -1), 10);
  }
  return value;
};

const DataGridComponent = React.forwardRef(({ isTableVisible, widgetKey, setLayout, isViewReport, generateReport, setCommentary, table }, chartRef) => {
  const [columns, setColumns] = useState([]);
  const [rows, setRows] = useState([]);
  const [chartType, setChartType] = useState("Bubble Chart");
  const [remainingVendors, setRemainingVendors] = useState(null);
  const [data, setData] = useState(null);
  const [, setCommentryLoading] = useState(true);
  const [, setError] = useState(null);
  const [, setCommentryData] = useState("");
  const {
    currentOrg,
    userProfile,
    currentScenarioId,
    currentBuyerGuideId,
    currentPackage,
    setNotification,
    setVendorId,
    setOfferId,
    setOfferName,
    setVendorName,
    setUnlockVendorCount,
    buyerGuideName,
    scenarioName,
    currentOrganizationId,
    prefix,
  } = useContext(UserProfileContext);
  const { initialData, setInitialData } = useContext(ComparitiveContext);
  const staticData =
    "### Summary of Top 5 Unused Features ('Not Required' Use Cases)\r\n\r\nThe widget titled \"Top 5 Unused Features ('Not Required' Use Cases)\" identifies the five least utilized features within a given application, specifically highlighting areas where these features are deemed unnecessary by users. The data represents an analysis of feature usage within the Zscaler Private Access platform. Each feature listed contributes equally to the total share of unused features, with a contribution score of 11.11%.\r\n\r\n#### Detailed Breakdown:\r\n\r\n1. **Secure Workforce**\r\n   - **Feature Contribution Score:** 11.11%\r\n   - **Lineage:** Zscaler Private Access > Secure Workforce\r\n\r\n2. **Secure Hybrid Workforce**\r\n   - **Feature Contribution Score:** 11.11%\r\n   - **Lineage:** Zscaler Private Access > Secure Workforce > Secure Hybrid Workforce\r\n\r\n3. **Branch & Remote Office Connectivity**\r\n   - **Feature Contribution Score:** 11.11%\r\n   - **Lineage:** Zscaler Private Access > Branch & Remote Office Connectivity\r\n\r\n4. **App Segmentation**\r\n   - **Feature Contribution Score:** 11.11%\r\n   - **Lineage:** Zscaler Private Access > App Segmentation\r\n\r\n5. **Digital Experience Monitoring**\r\n   - **Feature Contribution Score:** 11.11%\r\n   - **Lineage:** Zscaler Private Access > Monitoring & Optimization > Digital Experience Monitoring\r\n\r\nEach feature’s lineage indicates its hierarchical placement within the broader Zscaler Private Access system, providing context on its primary function and relationship to other functionalities within the platform. By identifying these unused features, stakeholders can gain insights into possible areas for optimization, user education, or feature redesign, aligning product offerings more closely with user needs and preferences.";
  const [loading, setLoading] = useState(true);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [dialogLink, setDialogLink] = useState("");
  const [gridLines, setGridLines] = useState({});
  const [isNewUser, setIsNewUser] = useState(false);
  const navigate = useNavigate();
  const [unlockingAllowed, setUnlockingAllowed] = useState(false);
  const [filterData, setFilterData] = useState(initialData);
  const localData = JSON.parse(localStorage.getItem(`disPlayLocked-${currentBuyerGuideId}-${currentScenarioId}`));
  const displayLocked = localData?.displayLocked;
  const mainData = useRef(null);
  const handleToggleColumn = (columnField) => {
    const updatedColumns = columns.map((col) => (col.field === columnField ? { ...col, isVisible: !col.isVisible } : col));
    setColumns(updatedColumns);
  };
  // const offsetEnabled = true; //important for height
  const handleToggleIcon = async (rowId, iconType) => {
    setRows((prevRows) =>
      prevRows.map((row) =>
        row.id === rowId && row.column0.type !== "checkbox"
          ? {
              ...row,
              column0: row.column0.type === "lock" ? { type: "check" } : { type: "lock" },
            }
          : row
      )
    );
    if (iconType === "lock" && !isNewUser) {
      await modifySubscriptionBillingCycle(userProfile?.email);
      setIsNewUser(true);
      localStorage.setItem("isNewUser", true);
    }
  };

  const filteredData = useMemo(() => {
    // Filter rows where column0 type is "check" and return their vendorIds
    return rows.filter((row) => row?.column0?.type === "check").map((row) => row.column8);
  }, [rows]);

  //unlock vedor button handeler ---------------------
  const handleUnlock = async () => {
    if (!!currentPackage && !!remainingVendors) {
      if (filteredData.length <= remainingVendors) {
        // unlockVendor
        try {
          const data = await apiService("https://newtestfuncpython.azurewebsites.net/api/unlockVendor?", {
            method: "POST",
            data: {
              email: userProfile?.email,
              currentOrganisation: currentOrg,
              currentBuyerGuide: currentBuyerGuideId,
              currentPackage: currentPackage?.id,
              currentVendors: filteredData,
            },
          });

          if (data.messages[0] !== "Vendor unlocked successfully.") {
            setNotification({
              open: true,
              message: "Failed to Unlock Vendor",
              severity: "error",
            });
          }
          setRows((prevRows) => prevRows.map((row) => (row.column0.type === "check" ? { ...row, column0: {} } : row)));
        } catch (err) {
          console.error("Error in Unlocking Vendor", err);
        } finally {
          fetchData();
        }
      } else {
        setNotification({
          open: true,
          message: remainingVendors > 0 ? `You Can Unlock Only ${remainingVendors} Vendors` : "Maximum Limit of Unlock Vendor reached", //remainingVendor will be null when there no current package
          severity: "info",
        });
      }
    } else {
      setNotification({
        open: true,
        message: "Please purchase a package to unlock vendors.",
        severity: "error",
      });
      navigate(`${prefix}/packages`);
    }
  };

  const handleCheckboxToggle = (rowId) => {
    setRows((prevRows) =>
      prevRows.map((row) =>
        row.id === rowId
          ? {
              ...row,
              column0: { ...row.column0, checked: !row.column0.checked },
            }
          : row
      )
    );
  };

  const updateChartType = (visibleColumnNames) => {
    const hasOfferScore = visibleColumnNames.includes("Offer Score");
    const hasOfferUtilization = visibleColumnNames.includes("Offer Utilization");
    // const hasOfferRelevance = visibleColumnNames.includes("Offer Relevance");
    // console.log(visibleColumnNames)

    if (hasOfferScore && hasOfferUtilization) {
      return "Bubble"; //Both selected
    } else if (hasOfferUtilization && !hasOfferScore) {
      return "scatter"; //Only utilization
    } else if (!hasOfferUtilization && hasOfferScore) {
      return "Bar offer metric"; //Only score
    } else if (!hasOfferUtilization && !hasOfferScore) {
      return "Bar"; // Both not selected
    } else {
      return "Bubble";
    }
  };

  /**
   *
   *   ‘Bubble Chart’ if Both ‘Offer Score’ and ‘Offer Utilisation’ is selected
   *
   *   ‘Scatter chart’ if ‘Offer Score’ is NOT selected
   *
   *    Bar chart (Offer Score & Offer Relevance) if ‘Offer Utilisation’ is NOT selected.
   *
   *    Bar chart (Offer Relevance) is both are NOT selected. --
   *
   */

  // eslint-disable-next-line
  function countUnlockedVendors(data) {
    // Filter vendors in OverallOffers and MandatoryOffers where VendorName is not "Unlock Vendor"
    const overallOffersFiltered = data?.OverallOffers?.filter((offer) => offer.VendorName !== "Unlock Vendor");
    const mandatoryOffersFiltered = data?.MandatoryOffers?.filter((offer) => offer.VendorName !== "Unlock Vendor");
    setUnlockVendorCount(overallOffersFiltered?.length + mandatoryOffersFiltered?.length);
  }

  const fetchData = async () => {
    try {
      const response = await apiService("https://newtestfuncpython.azurewebsites.net/api/valyouMatrixWidgetComparativeAnalysis?", {
        method: "POST",
        data: {
          email: userProfile?.email,
          currentOrganisation: currentOrg,
          currentBuyerGuide: currentBuyerGuideId,
          currentScenario: currentScenarioId,
          currentPackage: currentPackage?.id,
        },
      });

      if (response && !displayLocked) {
        setData(() => {
          return {
            ...response,
            OverallOffers:
              response?.OverallOffers?.filter((data) => {
                return data?.VendorName !== "Unlock Vendor";
              }) || [],
            MandatoryOffers:
              response?.MandatoryOffers?.filter((data) => {
                return data?.VendorName !== "Unlock Vendor";
              }) || [],
          };
        });
      } else {
        setData(response);
      }
    } catch (error) {
      error("Error fetching data:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line
  }, [userProfile, currentOrg, currentScenarioId, currentBuyerGuideId, setInitialData, setLayout, widgetKey, currentPackage?.id, remainingVendors]);

  useMemo(() => {
    if (!!data) {
      countUnlockedVendors(data);
      setGridLines(data.Gridlines);
      setRemainingVendors(data.remainingVendors);
      // Transform and update data
      setUnlockingAllowed((data?.OverallOffers || []).some((offer) => offer?.OfferName === "Unlock Vendor") || (data?.MandatoryOffers || []).some((offer) => offer?.OfferName === "Unlock Vendor"));
      const headers = [...(unlockingAllowed ? ["Select"] : []), "Vendor Name", "Status", "Offer Name", "Mandatory Status", "Offer Score", "Offer Relevance", "Offer Utilization"];
      //data for chart (X and Y axis)
      const overallData = data?.OverallOffers?.map((offer) => {
        return unlockingAllowed
          ? [
              {
                type: offer.OfferName !== "Unlock Vendor" ? "" : "lock",
              },
              offer.VendorName,
              "MandatoryOfferRelevance" in offer ? "mandatory" : "overall",
              offer.OfferName,
              "Overall Use Cases",
              `${offer?.OverallOfferScore?.toFixed(1)}`,
              `${offer?.OverallOfferRelevance?.toFixed(1)}`,
              `${offer?.OverallOfferUtilisation?.toFixed(1)}`,
              offer?.offerId,
              offer?.vendorId,
            ]
          : [
              offer.VendorName,
              offer.OfferName,
              "MandatoryOfferRelevance" in offer ? "mandatory" : "overall",
              "Overall Use Cases",
              `${offer?.OverallOfferScore?.toFixed(1)}`,
              `${offer?.OverallOfferRelevance?.toFixed(1)}`,
              `${offer?.OverallOfferUtilisation?.toFixed(1)}`,
              offer?.offerId,
              offer?.vendorId,
            ];
      });
      const mandatoryData = data?.MandatoryOffers?.map((offer) => {
        return unlockingAllowed
          ? [
              {
                type: offer?.OfferName !== "Unlock Vendor" ? "" : "lock",
              },
              offer?.VendorName,
              "MandatoryOfferRelevance" in offer ? "mandatory" : "overall",
              offer?.OfferName,
              "Mandatory Use Cases",
              `${offer?.MandatoryOfferScore?.toFixed(1)}`,
              `${offer?.MandatoryOfferRelevance?.toFixed(1)}`,
              `${offer?.MandatoryOfferUtilisation?.toFixed(1)}`,
              offer?.offerId,
              offer?.vendorId,
            ]
          : [
              offer?.VendorName,
              offer?.OfferName,
              "MandatoryOfferRelevance" in offer ? "mandatory" : "overall",
              "Mandatory Use Cases",
              `${offer?.MandatoryOfferScore?.toFixed(1)}`,
              `${offer?.MandatoryOfferRelevance?.toFixed(1)}`,
              `${offer?.MandatoryOfferUtilisation?.toFixed(1)}`,
              offer?.offerId,
              offer?.vendorId,
            ];
      });

      const combinedData = [headers, ...(overallData ? overallData : []), ...(mandatoryData ? mandatoryData : [])];
      setInitialData(combinedData);
      const initialColumns = headers
        .filter((header) => header !== "Status")
        .map((header, index) => ({
          field: `column${index}`,
          headerName: header,
          flex: ["Offer Score","Offer Utilization","Offer Relevance"].includes(header)? "initial":1,
          isVisible: header === "Offer Score" ? false : header === "Offer Utilization" ? data?.DisplayOfferOptions?.offerUtilization : true,
        }));
      // data?.DisplayOfferOptions

      const rows = combinedData.slice(1).map((row, index) => {
        const rowData = { id: index + 1 };
        row.forEach((cell, cellIndex) => {
          if (cellIndex !== 2) {
            rowData[`column${cellIndex < 2 ? cellIndex : cellIndex - 1}`] = parsePercentage(cell);
          }
        });
        return rowData;
      });
      setColumns(initialColumns);
      setRows(rows);
    }
    // eslint-disable-next-line
  }, [data, setInitialData, unlockingAllowed]);

  useMemo(() => {
    if (columns.length > 0) {
      const visibleColumns = columns.filter((col) => col.isVisible).map((col) => col.headerName);
      const newChartType = updateChartType(visibleColumns);
      setChartType(newChartType);
    }
  }, [columns]);

  //link hander (navigation)
  const handleLinkClick = (link, params) => {
    if (params.formattedValue !== "Unlock Vendor") {
      setVendorId(unlockingAllowed ? params.row.column8 : params.row.column7);
      localStorage.setItem("currentVendorId", unlockingAllowed ? params.row.column8 : params.row.column7);
      setOfferId(unlockingAllowed ? params.row.column7 : params.row.column6);
      localStorage.setItem("currentOfferId", unlockingAllowed ? params.row.column7 : params.row.column6);
      setOfferName(unlockingAllowed ? params.row.column2 : params.row.column1);
      localStorage.setItem("offerName", unlockingAllowed ? params.row.column2 : params.row.column1);
      setVendorName(unlockingAllowed ? params.row.column1 : params.row.column0);
      localStorage.setItem("vendorName", unlockingAllowed ? params.row.column1 : params.row.column0);
      setDialogLink(link);
      setDialogOpen(true);
    } else {
      setNotification({
        open: true,
        message: "Please unlock vendors.",
        severity: "info",
      });
    }
  };

  const handleDialogClose = (confirmed) => {
    setDialogOpen(false);
    if (confirmed) {
      navigate(dialogLink);
    }
  };

  // Columns configs.
  const renderSelectCell = (params) => {
    const iconType = params.value.type;
    if (iconType === "") {
      return <></>;
    } else if (iconType === "checkbox") {
      return <Box onClick={() => handleCheckboxToggle(params.id)}>{params.value.checked ? <CheckBox data-test-id="CheckBox" /> : <CheckBoxOutlineBlank data-test-id="CheckBoxOutlineBlank" />}</Box>;
    }
    return (
      <Box
        onClick={() => handleToggleIcon(params.id, iconType)}
        sx={{
          display: "flex",
          justifyContent: "center",
          height: "100%",
          alignItems: "center",
        }}
      >
        {iconType === "lock" ? <LockIcon data-test-id="LockIcon" /> : <CheckIcon data-test-id="CheckIcon" />}
      </Box>
    );
  };

  const renderVendorNameCell = (params) => (
    <Box
      style={{
        display: "flex",
        justifyContent: "start",
        alignItems: "center",
        overflowY: "hidden",
        height: "100%",
        cursor: "pointer",
      }}
      onClick={() => {
        handleLinkClick(`${prefix}/results/vendor-details`, params);
      }}
    >
      {params.value}
    </Box>
  );

  const renderOfferDetailsCell = (params) => {
    const justifyContent = ["Offer Score", "Offer Relevance", "Offer Utilization"].includes(params.colDef.headerName) ? "center" : "start";
    const cursor = ["Offer Score", "Offer Relevance", "Offer Utilization"].includes(params.colDef.headerName) ? "default" : "pointer";

    return (
      <Box
        style={{
          display: "flex",
          justifyContent: justifyContent,
          alignItems: "center",
          overflowY: "hidden",
          height: "100%",
          cursor: cursor,
        }}
        onClick={() => {
          if (["Offer Score", "Offer Relevance", "Offer Utilization"].includes(params.colDef.headerName)) {
            return;
          }
          handleLinkClick(`${prefix}/results/offer-details`, params);
        }}
      >
        {params.value}
      </Box>
    );
  };

  const renderMandatoryStatusCell = (params) => (
    <Box
      style={{
        display: "flex",
        justifyContent: "flex-start",
        alignItems: "center",
        overflowY: "hidden", // This will hide overflow along the Y-axis
        height: "100%",
      }}
    >
      {params.value}
    </Box>
  );

  // Use a map to avoid repeating the same checks in renderCell
  const renderCellByHeader = {
    Select: renderSelectCell,
    "Vendor Name": renderVendorNameCell,
    "Offer Name": renderOfferDetailsCell,
    "Offer Score": renderOfferDetailsCell,
    "Offer Relevance": renderOfferDetailsCell,
    "Offer Utilization": renderOfferDetailsCell,
    "Mandatory Status": renderMandatoryStatusCell,
  };

  useEffect(() => {
    setIsNewUser(localStorage.getItem("isNewUser") || false);
  }, []);

  useEffect(() => {
    if (isViewReport && data) {
      const reportData = JSON.parse(localStorage.getItem(`${currentOrg}-${buyerGuideName}-${scenarioName}-ComparativeSummary-${table}`));

      const fetchData = async (data) => {
        const payload = {
          email: userProfile?.email,
          currentOrganisation: currentOrganizationId,
          currentBuyerGuide: currentBuyerGuideId,
          currentPackage: currentPackage.id,
          currentScenario: currentScenarioId,
          reportComparativeTable: ComparativeTableMap[table],
          comparativeTableJson: data,
        };
        if (table && data) {
          try {
            const endPoint = generateReport ? "generateComparativeSummary" : "getComparativeSummary";
            const response = await apiService(
              `https://newtestfuncpython.azurewebsites.net/api/${endPoint}?`,
              {
                method: "POST",
                data: payload,
              },
              setCommentryLoading,
              setCommentryData,
              setError
            );

            setCommentary((prev) => {
              return {
                ...prev,
                ComparativeSummary: {
                  ...prev?.ComparativeSummary,
                  [table]: {
                    ...prev?.ComparativeSummary[table],
                    commentary: response?.comparativeSummary,
                    generateCount: response.maxGenerationLimit - response?.currentGenerationCount,
                    tableData: data,
                    isLoading: false,
                  },
                },
              };
            });

            localStorage.setItem(`${currentOrg}-${buyerGuideName}-${scenarioName}-ComparativeSummary-${table}`, JSON.stringify(response));
          } catch (error) {
            console.error("Failed to fetch data:", error);
            setError(error);
            setCommentary((prev) => {
              return {
                ...prev,
                ComparativeSummary: {
                  ...prev?.ComparativeSummary,
                  [table]: {
                    ...prev?.ComparativeSummary[table],
                    commentary: staticData,
                    tableData: data,
                    isLoading: false,
                  },
                },
              };
            });
            localStorage.setItem(
              `${currentOrg}-${buyerGuideName}-${scenarioName}-ComparativeSummary-${table}`,
              JSON.stringify({
                comparativeSummary: staticData,
                maxGenerationLimit: 3,
                currentGenerationCount: 1,
              })
            );
          }
        }
      };

      if (table && data && !reportData?.comparativeSummary) {
        if (data) {
          fetchData(data);
        } else {
          return;
        }
      } else if (table && data) {
        setCommentary((prev) => {
          return {
            ...prev,
            ComparativeSummary: {
              ...prev?.ComparativeSummary,
              [table]: {
                ...prev?.ComparativeSummary[table],
                commentary: reportData?.comparativeSummary,
                generateCount: reportData.maxGenerationLimit - reportData?.currentGenerationCount,
                tableData: data,
                isLoading: false,
              },
            },
          };
        });
      } else {
        return;
      }
    } else {
      return;
    }
  }, [
    table,
    setCommentary,
    data,
    currentOrg,
    scenarioName,
    isViewReport,
    buyerGuideName,
    currentBuyerGuideId,
    currentOrganizationId,
    currentPackage.id,
    currentScenarioId,
    userProfile,
    generateReport,
  ]);

  useEffect(() => {
    if (!isViewReport) {
      const updateLayout = () => {
        setLayout((prev) => {
          const newLayout = prev.map((item) => {
            if (item.i !== widgetKey) {
              return item;
            } else {
              const newItem = {
                ...item,
                h: (mainData.current?.clientHeight ?? 0) / 70, // Update based on the data length
              };
              return newItem;
            }
          });
          return newLayout;
        });
      };
      const rafId = requestAnimationFrame(updateLayout);
      return () => {
        // Clean up on unmount - cancel the requestAnimationFrame if it is still pending
        cancelAnimationFrame(rafId);
      };
    } else {
      return;
    }
    // eslint-disable-next-line
  }, [mainData?.current?.clientHeight, isTableVisible]);

  return loading ? (
    <Loader open={true} />
  ) : rows.length > 0 ? (
    <Box ref={mainData}>
      <Box
        className="widget-header-container"
        sx={{
          flexDirection: "column",
          width: "100%",
          height: "100%", // Fill the available space
          overflow: "hidden", // Prevent overflow
        }}
        ref={chartRef}
      >
        {/* Divider */}
        <Divider sx={{ marginY: 1, width: "95%" }} />
        {!isViewReport && (
          <Box
            className="chart-container"
            sx={{
              width: "100%",
              height: "100%",
              paddingX: "10px",
            }}
          >
            <ChartComponent data-test-id="chartcomponent" chartType={chartType} initialData={filterData} gridLines={gridLines} flag={unlockingAllowed} prefix={prefix} />
          </Box>
        )}
        {isTableVisible && (
          <Box
            data-test-id="chartTable"
            gap={"10px 0px"}
            flexDirection={"column"}
            sx={{
              width: "100%",
              height: "100%", // Fill the available space
              overflow: "hidden", // Prevent overflow
              gap: "20px 0px",
              display: "flex",
            }}
          >
            {!isViewReport && (
              <Box
                sx={{
                  padding: "10px",
                  gap: "10px 0px",
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <Box sx={{ gap: "8px", display: "flex", flexWrap: "wrap" }}>
                  {columns
                    .filter((column) => ["Offer Score", "Offer Utilization"].includes(column.headerName))
                    .map((column) => (
                      <Chip
                        key={column.field}
                        label={column.headerName}
                        data-test-id={`chip-${column.headerName}`}
                        onClick={() => handleToggleColumn(column.field)}
                        variant={column.isVisible ? "outlined" : "default"}
                        color={column.isVisible ? "primary" : "default"}
                        sx={{
                          marginRight: "5px",
                          ":hover": {
                            color: "#54c4bf",
                            borderColor: "#54c4bf",
                          },
                        }}
                      />
                    ))}
                </Box>

                {unlockingAllowed && (
                  <Button data-test-id="unlock" sx={{ maxWidth: "100px" }} variant="contained" color="primary" onClick={handleUnlock}>
                    Unlock
                  </Button>
                )}
              </Box>
            )}
            <Box data-test-id="chartTable" className="thinner-scrollbar" sx={{ height: "100%", overflow: "auto" }}>
              {/* Table */}
              <DataGridTable
                data-test-id="table"
                rows={rows}
                columns={columns.map((col) => ({
                  ...col,
                  renderCell: renderCellByHeader[col.headerName] || null,
                  headerClassName: "super-app-theme--header",
                  minWidth: 180,
                }))}
                hideFooter
                setFilterData={setFilterData}
                flag={unlockingAllowed}
              />
            </Box>
          </Box>
        )}

        <Dialog open={dialogOpen} onClose={() => handleDialogClose(false)} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
          <DialogTitle data-test-id="Confirm Navigation" id="alert-dialog-title">
            {"Confirm Navigation"}
          </DialogTitle>
          <DialogContent>
            <DialogContentText data-test-id="You will be navigating to a new page." id="alert-dialog-description">
              You will be navigating to a new page.
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button data-test-id="Cancel" onClick={() => handleDialogClose(false)} color="primary">
              Cancel
            </Button>
            <Button data-test-id="Confirm" onClick={() => handleDialogClose(true)} color="primary" autoFocus>
              Confirm
            </Button>
          </DialogActions>
        </Dialog>
      </Box>
    </Box>
  ) : (
    <NoDataAvilable message="No Data Avilable" ref={mainData} />
  );
});

export default DataGridComponent;
