export default function useParentChange(setEdges, selectedNodes, setLayouted) {
  const reparentNodeWithDescendants = () => {
    const selectedNodeId = selectedNodes?.[1]?.id;
    const newParentId = selectedNodes?.[0]?.id;
    // Remove the edge that connects the selected node to its current parent
    setEdges((prevEdges) => prevEdges.filter((edge) => edge.target !== selectedNodeId));

    // Add a new edge connecting the selected node to the new parent
    setEdges((prevEdges) => [...prevEdges, { id: `${newParentId}-${selectedNodeId}`, source: newParentId, target: selectedNodeId, animated: true, type: "default" }]);

    // No changes needed for descendants as their parent-child structure remains intact

    setLayouted(false);
    // setUnderProcess(false);
  };

  const reassignDirectAndIndirectDescendants = () => {
    const selectedNodeId = selectedNodes?.[1]?.id;
    const newParentId = selectedNodes?.[0]?.id;

    setEdges((prevEdges) => {
      const directChildren = new Set(); // Collect direct children
      const updatedEdges = []; // Final updated edges

      //Identify direct children and reassign them to newParentId
      prevEdges.forEach((edge) => {
        if (edge.source === selectedNodeId) {
          directChildren.add(edge.target); // Collect direct children
          updatedEdges.push({
            ...edge,
            id: `${newParentId}-${edge.target}`, // Update edge ID
            source: newParentId, // Assign new parent to direct children
            animated: true,
            type: "default",
          });
        } else {
          updatedEdges.push(edge); // Keep all other edges unchanged for now
        }
      });

      //Reassign indirect children to selectedNodeId
      const finalEdges = updatedEdges.map((edge) => {
        if (directChildren.has(edge.source)) {
          // Update source for indirect children to selectedNodeId
          return {
            ...edge,
            id: `${selectedNodeId}-${edge.target}`, // Update edge ID
            source: selectedNodeId,
            animated: true,
            type: "default",
          };
        }
        return edge; // Keep edges unrelated to indirect children unchanged
      });

      return finalEdges;
    });

    setLayouted(false);
  };

  return { reparentNodeWithDescendants, reassignDirectAndIndirectDescendants };
}
