import React, { useContext } from "react";
import { Box, Typography } from "@mui/material";
import PopupAction from "../../components/PopupAction";
import CustomButton from "../../components/CustomButton";
import PreviewAllocation from "./PreviewAllocation";
import Vendor from "./SpecificAllocation/Vendor";
import { titles, unitCheck } from "./helper";
import theme from "../../theme/theme";
import { AllocationContext } from "./AllocationContext";
import { apiService } from "../../utils/utils";

export const Popups = ({
  popover,
  previewPopover,
  vendorPopup,
  vendorTab,
  popoverContent,
  allocatedData,
  handelClose,
  handlePreviewClose,
  setAllocatedData,
  setVendorTab,
  handelAllocationOfPrescribedUnits,
}) => {
  const { selectedVendors, setSelectedVendors,setVendorList, setVendorPopup, selectedBG } = useContext(AllocationContext);
  const handelUserResponse = async (decision, bgId = null) => {
    setVendorPopup(false);
    if (decision) {
      const response = await apiService(`https://newtestfuncpython.azurewebsites.net/api/getVendorsOnAdminAllocation`, {
        method: "POST",
        data: {
          buyer_guide_element_id: bgId ? bgId : selectedBG?.id,
        },
      });
      setVendorList(response);
      setVendorTab(true);
    }
  };

  return (
    <>
      <PopupAction
        handleClose={handelClose}
        open={popover}
        title={titles[popoverContent?.field]}
        extraCss={popoverContent?.field?.includes("endUser") || popoverContent?.field?.includes("buyerGuide") ? { minHeight: "50%", width: "80%" } : { width: "50%" }}
      >
        {unitCheck(popoverContent, setAllocatedData, handelClose, allocatedData,handelUserResponse)}
      </PopupAction>

      <PreviewAllocation open={previewPopover} handleClose={handlePreviewClose} data={allocatedData} allocateUnits={handelAllocationOfPrescribedUnits} />

      <PopupAction open={vendorPopup} title={"Vendor Allocation"} extraCss={{ width: "30%", height: "20%" }} showCross={false}>
        <Box style={{ textAlign: "center", padding: "10px", position: "relative", display: "flex", flexDirection: "column", alignItems: "center", width: "100%" }}>
          <Typography variant="h6" gutterBottom sx={{ width: "100%" }}>
            Do you want to allocate Vendors for this Buyer Guide ?
          </Typography>
          <Box sx={{ gap: "10px", position: "absolute", bottom: 0, width: "100%", display: "flex", justifyContent: "center" }}>
            <CustomButton onClick={() => handelUserResponse(true)} sx={{ backgroundColor: theme.palette.primary.main, width: "120px" }} text={"Yes"} />
            <CustomButton onClick={() => handelUserResponse(false)} sx={{ backgroundColor: theme.palette.secondary.main, width: "120px" }} text={"No"} />
          </Box>
        </Box>
      </PopupAction>

      <PopupAction
        handleClose={() => {
          setVendorTab(false);
        }}
        open={vendorTab}
        title={"Vendor Allocation"}
        extraCss={{ width: "50%" }}
      >
        <Vendor selectedVendors={selectedVendors} setSelectedVendors={setSelectedVendors} />
      </PopupAction>
    </>
  );
};
