import React, { useContext, useState, useEffect } from "react";
import {
  Typography,
  Grid,
  FormControlLabel,
  Checkbox,
  Button,
  Box,
  CircularProgress,
} from "@mui/material";
import { useNavigate } from "react-router-dom";

import HeaderNaming from "../../components/HeaderNaming/HeaderNaming.jsx";
import { apiService } from "../../utils/utils.js";
import { UserProfileContext } from "../../context/UserContext.jsx";
import SaveAndExit from "../../components/reusableButton/SaveAndExit.jsx";
const BundleSelection = ({ prefix }) => {
  const {
    userProfile,
    currentOrganization,
    currentScenarioId,
    currentBuyerGuideId,
    buyerGuideName,
    scenarioName,
    currentOrg,
    currentPackage,
    setNotification,
  } = useContext(UserProfileContext);
  const [limitedBundlesChecked, setLimitedBundlesChecked] = useState(false);
  const [addOnBundlesChecked, setAddOnBundlesChecked] = useState(false);
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const data = {
    "End-user Organisation":
      userProfile?.profileType === "endUser"
        ? userProfile?.companyName
        : currentOrganization,
    "Buyer Guide Name": buyerGuideName,
    "Scenario Name": scenarioName,
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await apiService(
          "https://newtestfuncpython.azurewebsites.net/api/getBundleOption?",
          {
            method: "POST",
            data: {
              email: userProfile?.email,
              currentOrganisation: currentOrg,
              currentBuyerGuide: currentBuyerGuideId,
              currentScenario: currentScenarioId,
              currentPackage: currentPackage?.id,
            },
          }
        );
        setAddOnBundlesChecked(
          response?.offerEvaluation?.includeFeaturesAddOns
        );
        setLimitedBundlesChecked(
          response?.offerEvaluation?.includeFeaturesLimitedScope
        );
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, [
    userProfile,
    currentOrg,
    currentScenarioId,
    currentPackage,
    currentBuyerGuideId,
  ]);

  const handleLimitedBundlesChange = (event) => {
    setLimitedBundlesChecked(event.target.checked);
  };

  const handleAddOnBundlesChange = (event) => {
    setAddOnBundlesChecked(event.target.checked);
  };

  const saveAndContinue = async () => {
    try {
      const response = await apiService(
        "https://newtestfuncpython.azurewebsites.net/api/updateBundleOption?",
        {
          method: "POST",
          data: {
            email: userProfile?.email,
            currentOrganisation: currentOrg,
            currentBuyerGuide: currentBuyerGuideId,
            currentScenario: currentScenarioId,
            currentPackage: currentPackage?.id,
            bundleOptionsTab: {
              offerEvaluation: {
                includeFeaturesAddOns: addOnBundlesChecked,
                includeFeaturesLimitedScope: limitedBundlesChecked,
              },
            },
          },
        }
      );
      setNotification({
        open: true,
        message:
          response?.message === "Bundle option updated successfully."
            ? response?.message
            : "Failed to Save Bundle option",
        severity:
          response?.message === "Bundle option updated successfully."
            ? "success"
            : "error",
      });
    } catch (error) {
      console.error("Error updating data:", error);
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    const button = event.target.querySelector('button[type="submit"]');
    button.textContent = "Saved";

    await saveAndContinue();
    navigate(`${prefix}/threshold-option`, { replace: true });

    setTimeout(() => {
      button.textContent = "Save";
    }, 2000);
  };

  return isLoading ? (
    <Box
      height="80vh"
      width="100%"
      justifyContent="center"
      alignItems="center"
      display="flex"
    >
      <CircularProgress />
    </Box>
  ) : (
    <>
      <HeaderNaming data={data} />
      <form onSubmit={handleSubmit}>
        <Typography
          data-test-id="bundle-option"
          variant="h3"
          gutterBottom
          marginTop={-1.7}
        >
          Bundle Options for Offer Evaluation
        </Typography>
        <Grid container direction="column" spacing={2}>
          <Grid item>
            <FormControlLabel
              data-test-id="name-Include Features that are Add-On’s in Offers"
              data-testid="label1"
              control={
                <Checkbox
                  data-testid="checkbox1"
                  data-test-id="checkbox-Include Features that are Add-On’s in Offers"
                  checked={addOnBundlesChecked}
                  onChange={handleAddOnBundlesChange}
                  color="secondary"
                />
              }
              label="Include Features that are Add-On’s in Offers"
            />
          </Grid>
          <Grid item>
            <FormControlLabel
              data-testid="label2"
              data-test-id="name-Include Features that have a limited scope or constraints in Offers"
              control={
                <Checkbox
                  data-testid="checkbox2"
                  data-test-id="checkbox-Include Features that have a limited scope or constraints in Offers"
                  checked={limitedBundlesChecked}
                  onChange={handleLimitedBundlesChange}
                  color="secondary"
                />
              }
              label="Include Features that have a limited scope or constraints in Offers"
            />
          </Grid>
        </Grid>
      </form>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: 2,
          mt: 2,
          ml: 2,
        }}
      >
        <Button
          style={{ width: "250px" }}
          color="primary"
          variant="contained"
          disableRipple
          onClick={async () => {
            await saveAndContinue();
            navigate(
              `${prefix}/edit-scenario/scenario-settings/threshold-option`
            );
          }}
        >
          <Typography data-test-id="save&continue" color="white">
            Save & Continue
          </Typography>
        </Button>

        <SaveAndExit
          saveAndContinue={saveAndContinue}
          data={{
            limitedBundlesChecked,
            addOnBundlesChecked,
          }}
          setIsLoading={setIsLoading}
          prefix={prefix}
        />
      </Box>
    </>
  );
};

export default BundleSelection;
