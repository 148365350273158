import { useNodesState, useEdgesState, getIncomers, getOutgoers, getConnectedEdges } from "@xyflow/react";
import { addEdge } from "@xyflow/react";
// import saveNewTreeData from "./saveNewTreeData";
import { useCallback } from "react";

export const useTreeGraph = (initialNodes, initialEdges, getLayoutedElements) => {

  const [nodes, setNodes, onNodesChange] = useNodesState(initialNodes);
  // eslint-disable-next-line no-unused-vars
  const [edges, setEdges, onEdgesChange] = useEdgesState(initialEdges);

  const onConnect = (params) => {
    setEdges((eds) => {
      const updatedEdges = addEdge(params, eds);
      getLayoutedElements(nodes, updatedEdges, setNodes);

      // // Call saveNewTreeData after edges are updated
      // const updatedData = saveNewTreeData({ nodes, edges: updatedEdges });
      // console.log("Updated Tree Data:", updatedData);

      return updatedEdges;
    });
  };

  const onNodesDelete = useCallback(
    (deleted) => {
      setEdges(
        deleted.reduce((acc, node) => {
          const incomers = getIncomers(node, nodes, edges);
          const outgoers = getOutgoers(node, nodes, edges);
          const connectedEdges = getConnectedEdges([node], edges);

          const remainingEdges = acc.filter((edge) => !connectedEdges.includes(edge));

          const createdEdges = incomers.flatMap(({ id: source }) =>
            outgoers.map(({ id: target }) => ({
              id: `${source}->${target}`,
              source,
              target,
            }))
          );

          return [...remainingEdges, ...createdEdges];
        }, edges)
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [nodes, edges]
  );

  return {
    nodes,
    edges,
    onNodesChange,
    onEdgesChange,
    onConnect,
    setNodes,
    onNodesDelete,
    setEdges,
  };
};