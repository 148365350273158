import { Close } from "@mui/icons-material";
import { Box, IconButton, Modal, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import CustomButton from "../../components/CustomButton";
import theme from "../../theme/theme";
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from "@mui/material";

export default function PreviewAllocation({ open, handleClose, data, allocateUnits }) {
  const [rows, setRows] = useState([]);

  // Transform data into rows with grouping
  useEffect(() => {
    if (data) {
      const transformedRows = transformDataWithGrouping(data);
      setRows(transformedRows);
    }
    return () => {
      setRows([]);
    };
  }, [data]);

  // Grouping and row transformation with rowSpan
  const transformDataWithGrouping = (data) => {
    if (!data) return [];

    const transformedRows = [];
    data.forEach((item, groupIndex) => {
      const { bgList, purchasedPackages, allocationType, totalUnits, userList, euoList } = item;

      if (bgList && bgList.length > 0) {
        // When bgList is available, process each buyer guide
        bgList.forEach((buyerGuide, index) => {
          const isFirstInGroup = index === 0;
          transformedRows.push({
            id: `${buyerGuide.id}-${groupIndex}-${index}`,
            purchasedPackages: isFirstInGroup ? purchasedPackages : "",
            allocationType: isFirstInGroup ? allocationType : "",
            totalUnits: isFirstInGroup ? totalUnits : "",
            userList: isFirstInGroup ? userList : [],
            euoList: isFirstInGroup ? euoList : [],
            buyerGuideTitle: buyerGuide.title,
            vendors: buyerGuide.vendors,
            isFirstInGroup,
            rowSpan: isFirstInGroup ? bgList.length : 0,
          });
        });
      } else {
        // When bgList is not available, create a single row for the item
        transformedRows.push({
          id: `${groupIndex}-no-bgList`,
          purchasedPackages,
          allocationType,
          totalUnits,
          userList,
          euoList,
          buyerGuideTitle: "User Defined", // Placeholder for missing buyer guide
          vendors: [], // Empty array as no vendors are present
          isFirstInGroup: true,
          rowSpan: 1,
        });
      }
    });

    return transformedRows;
  };

  return (
    <Modal
      open={open}
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        mt: "40px",
        width: "100%",
        height: "100%",
      }}
    >
      <Box
        sx={{
          position: "relative",
          width: "90%",
          height: "80%",
          bgcolor: "white",
          borderRadius: "20px",
          p: "20px",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Box
          sx={{
            mb: 4,
            width: "100%",
            bgcolor: theme.palette.primary.main,
            position: "relative",
            alignItems: "center",
            display: "flex",
            justifyContent: "center",
            borderTopRightRadius: "10px",
            borderTopLeftRadius: "10px",
            paddingY: "5px",
          }}
        >
          <Typography variant="h2" sx={{ ...theme.typography.h2, textAlign: "center", color: "#fff" }}>
            Preview Allocation
          </Typography>
          <IconButton aria-label="close" onClick={handleClose} sx={{ position: "absolute", right: 5, color: "#fff" }}>
            <Close />
          </IconButton>
        </Box>

        {rows.length > 0 ? (
          <Box sx={{ width: "100%", maxHeight: "90%", display: "flex", alignItems: "start", flexDirection: "column", position: "relative" }}>
            <TableContainer component={Paper} sx={{ mb: 2, borderRadius: "20px" }}>
              <Table stickyHeader>
                <TableHead>
                  <TableRow>
                    {["Purchased Packages", "Allocation Type", "Users", "End-User Organizations", "Buyer Guide List", "Prescribed Vendors", "Total Units"].map((header) => (
                      <TableCell
                        key={header}
                        align="center"
                        sx={{
                          backgroundColor: theme.palette.primary.main,
                          ...theme.typography.h5,
                          color: "#fff",
                          whiteSpace: "break-spaces",
                          border: "1px solid rgba(224, 224, 224, 1)",
                        }}
                      >
                        {header}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>

                <TableBody sx={{ ...theme.typography.body1 }}>
                  {rows.map((row, rowIndex) => (
                    <TableRow
                      key={row.id}
                      sx={{
                        // Add a bolder border between groups and include vertical lines
                        borderTop: row.isFirstInGroup && rowIndex !== 0 ? "2px solid red" : "none",
                        "& .MuiTableCell-root": {
                          borderRight: "1px solid rgba(224, 224, 224, 1)",
                          "&:last-child": {
                            borderRight: "1px solid rgba(224, 224, 224, 1)",
                          },
                        },
                      }}
                    >
                      {/* Purchased Packages Below */}
                      {row.isFirstInGroup && (
                        <TableCell align="center" rowSpan={row.rowSpan} sx={{ ...theme.typography.body1 }}>
                          {row.purchasedPackages}
                        </TableCell>
                      )}

                      {/* Allocatio Type Below */}
                      {row.isFirstInGroup && (
                        <TableCell align="center" rowSpan={row.rowSpan} sx={{ ...theme.typography.body1 }}>
                          {row.allocationType}
                        </TableCell>
                      )}

                      {/* User Unit Below */}
                      {row.isFirstInGroup && (
                        <TableCell align="left" rowSpan={row.rowSpan}>
                          {row.userList?.map((user, index) => (
                            <Typography key={index} variant="body2" sx={{ ...theme.typography.body1 }}>
                              <Typography key={index} variant="p" sx={{ whiteSpace: "break-spaces", mr: 1 }}>
                                {user.emailId}
                              </Typography>
                              ({user.combinationUnits})
                            </Typography>
                          ))}
                        </TableCell>
                      )}

                      {/* EUO Unit Below */}
                      {row.isFirstInGroup && (
                        <TableCell align="center" rowSpan={row.rowSpan} sx={{ ...theme.typography.body1 }}>
                          {row?.euoList?.map((euo, index) => (
                            <Typography key={index} sx={{ ...theme.typography.body1 }}>
                              {console.log(typeof euo)}
                              {euo.title}
                            </Typography>
                          )) || "User Defined"}
                        </TableCell>
                      )}

                      {/* Buyer Guide Below */}
                      <TableCell align="center" sx={{ ...theme.typography.body1, whiteSpace: "break-spaces" }}>
                        {row.buyerGuideTitle}
                      </TableCell>

                      {/* Vendor Below */}
                      <TableCell align="center" sx={{ ...theme.typography.body1, whiteSpace: "break-spaces" }}>
                        {row?.vendors && row.vendors.length > 0
                          ? row.vendors.map((vendor, index) => (
                              <Typography key={index} sx={{ ...theme.typography.body1, whiteSpace: "break-spaces" }}>
                                {vendor.title},
                              </Typography>
                            ))
                          : "User Defined"}
                      </TableCell>

                      {/* Total Unit Below */}
                      {row.isFirstInGroup && (
                        <TableCell align="center" sx={{ ...theme.typography.body1 }} rowSpan={row.rowSpan}>
                          {row.totalUnits}
                        </TableCell>
                      )}
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>

            <CustomButton text={"Allocate"} onClick={allocateUnits} />
          </Box>
        ) : (
          <Typography sx={{ ...theme.typography.h1, color: "gray", mt: "auto", mb: "auto" }}>No Prescribed Data Found</Typography>
        )}
      </Box>
    </Modal>
  );
}
