import { Typography } from "@mui/material";
import DropDown from "./DropDown";
// import CustomButton from "../../components/CustomButton";
// import theme from "../../theme/theme";
import { allocationType, restrictUser } from "./helper";

export const columnData = (setSectionData, userProfile, setPopover, setPopoverContent, setAllocatedData) => {
  const columns = [
    // {
    //   field: "Split Row",
    //   headerName: "Split Row",
    //   width: 90,
    //   sortable: false,
    //   headerClassName: "super-app-theme--header",
    //   headerAlign: "center",
    //   disableColumnMenu: false,
    //   display: "flex",
    //   minWidth: 120,
    //   flex: 0.5,
    //   renderCell: () => (
    //     <CustomButton
    //       variant="contained"
    //       sx={{
    //         backgroundColor: theme.palette.secondary.main,
    //       }}
    //       text={"Split"}
    //       onClick={() => {}}
    //     />
    //   ),
    // },

    //do not remove this above commented code also not the imports this is for version 2.0

    {
      field: "purchasedPackageName",
      headerName: "Purchased Packages",
      headerClassName: "super-app-theme--header",
      display: "flex",
      minWidth: 200,
      flex: 1,
    },

    {
      field: "allocationType",
      headerName: "Allocation Type",
      headerClassName: "super-app-theme--header",
      display: "flex",
      minWidth: 300,
      flex: 1,
      renderCell: (params) => {
        return <Typography>{allocationType[params.row?.allocationType]}</Typography>;
      },
    },

    {
      field: "userUnitsTotal",
      headerName: "User Units",
      headerClassName: "super-app-theme--header",
      display: "flex",
      minWidth: 250,
      flex: 1,
      renderCell: (params) => (
        <DropDown
          params={params}
          maxLimit={params?.row?.endUserOrganizationUnitsTotal}
          isDisabled={restrictUser(params)}
          setSectionData={setSectionData}
          inputsLabel={"userUnitsInput"}
          setPopover={setPopover}
          setPopoverContent={setPopoverContent}
          setAllocatedData={setAllocatedData}
        />
      ),
    },

    {
      field: "endUserOrganizationUnitsTotal",
      headerName: "End-User Organization Units",
      headerClassName: "super-app-theme--header",
      display: "flex",
      minWidth: 250,
      flex: 1,
      renderCell: (params) => (
        <DropDown
          params={params}
          maxLimit={params?.row?.endUserOrganizationUnitsTotal}
          isDisabled={restrictUser(params)}
          setSectionData={setSectionData}
          inputsLabel={"endUserOrganizationUnitsInput"}
          setPopover={setPopover}
          setPopoverContent={setPopoverContent}
          setAllocatedData={setAllocatedData}
        />
      ),
    },

    {
      field: "buyerGuideUnitsTotal",
      headerName: "Buyer Guide Units",
      headerClassName: "super-app-theme--header",
      display: "flex",
      minWidth: 220,
      flex: 1,
      renderCell: (params) => (
        <DropDown
          params={params}
          maxLimit={params?.row?.buyerGuideUnitsTotal}
          isDisabled={restrictUser(params)}
          setSectionData={setSectionData}
          inputsLabel={"buyerGuideUnitsInput"}
          setPopover={setPopover}
          setPopoverContent={setPopoverContent}
          setAllocatedData={setAllocatedData}
        />
      ),
    },

    {
      field: "totalUnits",
      headerName: "Total Units",
      headerClassName: "super-app-theme--header",
      display: "flex",
      minWidth: 250,
      flex: 1,
      renderCell: (params) => (
        <DropDown
          params={params}
          maxLimit={params?.row?.totalUnits}
          isDisabled={restrictUser(params)}
          setSectionData={setSectionData}
          inputsLabel={"totalUnitsInput"}
          setAllocatedData={setAllocatedData}
        />
      ),
    },
  ];
  const updatedColumn = columns?.filter((el) => {
    if (el.field === "endUserOrganizationUnitsTotal" && userProfile.profileType !== "consultant") return false;
    return true;
  });
  return updatedColumn;
};
