import React, { useState } from "react";
import { AllocationProvider } from "./AllocationContext";
import Allocation from "./Allocation";

export default function AllocationWrapper() {
  const [popover, setPopover] = useState(false);
  return (
    <AllocationProvider>
      <Allocation popover={popover} setPopover={setPopover} />
    </AllocationProvider>
  );
}
