import React, { useState, useEffect, useContext, useRef } from "react";
import { Link, Box, Alert, Divider } from "@mui/material";
import DataGridTable from "../../../components/DataGridTable";
import { apiService } from "../../../utils/utils"; // Adjust the path as necessary
import { UserProfileContext } from "../../../context/UserContext";
import "./ComponentStyle.css";
import NoDataAvilable from "../../../components/NoDataAvilable";
import { ComparativeTableMap } from "../../Utils/helper";
import Loader from "../../../components/Loader";

const columns = [
  {
    field: "useCase",
    headerName: "Use Case",
    flex: 1,
    headerClassName: "super-app-theme--header",
    minWidth: 200,
    renderCell: (params) => (
      <Box
        sx={{
          overflowY: "hidden", // Apply overflowY: hidden to each column
          display: "flex",
          justifyContent: "flex-start", //to target each column of table
          alignItems: "center",
          height: "100%", // Adjust height as needed
        }}
      >
        {params.value}
      </Box>
    ),
  },
  {
    field: "importanceScore",
    headerName: "Relative Importance",
    type: "number",
    flex: 1,
    headerClassName: "super-app-theme--header",
    minWidth: 200,
    renderCell: (params) => (
      <Box
        sx={{
          overflowY: "hidden", // Apply overflowY: hidden to each column
          display: "flex",
          justifyContent: "center", //to target each column of table
          alignItems: "center",
          width: "100%",
          height: "100%", // Adjust height as needed
        }}
      >
        {params.value}
      </Box>
    ),
  },
  {
    field: "mandatory",
    headerName: "Mandatory Status",
    flex: 1,
    headerClassName: "super-app-theme--header",
    minWidth: 200,
    renderCell: (params) => (
      <Box
        sx={{
          overflowY: "hidden", // Apply overflowY: hidden to each column
          display: "flex",
          justifyContent: "flex-start", //to target each column of table
          alignItems: "center",
          width: "100%",
          height: "100%", // Adjust height as needed
        }}
      >
        {params.value}
      </Box>
    ),
  },
  {
    field: "offers",
    headerName: "Number of Offers that meet the Use Case",
    flex: 1,
    headerClassName: "super-app-theme--header",
    minWidth: 200,
    renderCell: (params) => (
      <Box
        sx={{
          overflowY: "hidden", // Apply overflowY: hidden to each column
          display: "flex",
          justifyContent: "center", //to target each column of table
          alignItems: "center",
          width: "100%",
          height: "100%", // Adjust height as needed
        }}
      >
        {params.value}
      </Box>
    ),
  },
  {
    field: "vendors",
    headerName: "Number of Vendors that meet the Use Case",
    flex: 1,
    headerClassName: "super-app-theme--header",
    minWidth: 200,
    renderCell: (params) => (
      <Box
        sx={{
          overflowY: "hidden", // Apply overflowY: hidden to each column
          display: "flex",
          justifyContent: "center", //to target each column of table
          alignItems: "center",
          width: "100%",
          height: "100%", // Adjust height as needed
        }}
      >
        {params.value}
      </Box>
    ),
  },
];

const CoverageHeatmap = ({ showDetails, widgetKey, setLayout, isViewReport ,generateReport,setCommentary,table }) => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [expandedRows, setExpandedRows] = useState({});
  const [, setCommentryLoading] = useState(true);
  const [, setCommentryData] = useState("");
  const staticData =
      "### Summary of Top 5 Unused Features ('Not Required' Use Cases)\r\n\r\nThe widget titled \"Top 5 Unused Features ('Not Required' Use Cases)\" identifies the five least utilized features within a given application, specifically highlighting areas where these features are deemed unnecessary by users. The data represents an analysis of feature usage within the Zscaler Private Access platform. Each feature listed contributes equally to the total share of unused features, with a contribution score of 11.11%.\r\n\r\n#### Detailed Breakdown:\r\n\r\n1. **Secure Workforce**\r\n   - **Feature Contribution Score:** 11.11%\r\n   - **Lineage:** Zscaler Private Access > Secure Workforce\r\n\r\n2. **Secure Hybrid Workforce**\r\n   - **Feature Contribution Score:** 11.11%\r\n   - **Lineage:** Zscaler Private Access > Secure Workforce > Secure Hybrid Workforce\r\n\r\n3. **Branch & Remote Office Connectivity**\r\n   - **Feature Contribution Score:** 11.11%\r\n   - **Lineage:** Zscaler Private Access > Branch & Remote Office Connectivity\r\n\r\n4. **App Segmentation**\r\n   - **Feature Contribution Score:** 11.11%\r\n   - **Lineage:** Zscaler Private Access > App Segmentation\r\n\r\n5. **Digital Experience Monitoring**\r\n   - **Feature Contribution Score:** 11.11%\r\n   - **Lineage:** Zscaler Private Access > Monitoring & Optimization > Digital Experience Monitoring\r\n\r\nEach feature’s lineage indicates its hierarchical placement within the broader Zscaler Private Access system, providing context on its primary function and relationship to other functionalities within the platform. By identifying these unused features, stakeholders can gain insights into possible areas for optimization, user education, or feature redesign, aligning product offerings more closely with user needs and preferences.";
  const {
    userProfile,
    // currentOrganizationId,
    currentBuyerGuideId,
    currentScenarioId,
    currentPackage,
    currentOrg,
    buyerGuideName,
    scenarioName,
    currentOrganizationId
  } = useContext(UserProfileContext);
  const mainData = useRef(null);

  useEffect(() => {
    let isMounted = true; // Track whether component is mounted
    const fetchData = async () => {
      setLoading(true);
      try {
        await apiService(
          "https://newtestfuncpython.azurewebsites.net/api/getCoverageHeatmap?", // Replace with your actual endpoint
          {
            method: "POST",
            data: {
              email: userProfile?.email,
              currentOrganisation: currentOrg,
              currentBuyerGuide: currentBuyerGuideId,
              currentScenario: currentScenarioId,
              currentPackage: currentPackage?.id,
            },
          },
          setLoading,
          setData,
          setError
        );
      } catch (error) {
        if (isMounted) {
          setError(error.message || "Error fetching data");
        }
      } finally {
        if (isMounted) {
          setLoading(false);
        }
      }
    };

    fetchData();

    // Cleanup function to set isMounted to false when the component unmounts
    return () => {
      isMounted = false;
    };
  }, [
    userProfile,
    currentOrg,
    currentBuyerGuideId,
    setLayout,
    currentScenarioId,
    widgetKey,
    currentPackage?.id,
  ]);
  useEffect(() => {
      if (isViewReport&&data.length>0) {
        const reportData = JSON.parse(
          localStorage.getItem(
            `${currentOrg}-${buyerGuideName}-${scenarioName}-ComparativeSummary-${table}`
          )
        );
        
        const fetchData = async (data) => {
          const payload = {
            email: userProfile?.email,
            currentOrganisation: currentOrganizationId,
            currentBuyerGuide: currentBuyerGuideId,
            currentPackage: currentPackage.id,
            currentScenario: currentScenarioId,
            reportComparativeTable: ComparativeTableMap[table],
            comparativeTableJson: data,
          };
          if (table && data) {
            try {
              const endPoint = generateReport?"generateComparativeSummary":"getComparativeSummary";
              const response = await apiService(
                `https://newtestfuncpython.azurewebsites.net/api/${endPoint}?`,
                {
                  method: "POST",
                  data: payload,
                },
                setCommentryLoading,
                setCommentryData,
                setError
              );

              setCommentary((prev) => {
                return {
                  ...prev,
                  ComparativeSummary:{
                    ...prev?.ComparativeSummary,
                    [table]:{
                        ...prev?.ComparativeSummary[table],
                        commentary:response?.comparativeSummary,
                        generateCount:response.maxGenerationLimit - response?.currentGenerationCount,
                        tableData:data,
                        isLoading:false
                    }
                  }

              }
              });

              localStorage.setItem(
                `${currentOrg}-${buyerGuideName}-${scenarioName}-ComparativeSummary-${table}`,
                JSON.stringify(response)
              );
            } catch (error) {
              console.error("Failed to fetch data:", error);
              setError(error);
              setCommentary((prev) => {
                return {
                  ...prev,
                  ComparativeSummary:{
                    ...prev?.ComparativeSummary,
                    [table]:{
                        ...prev?.ComparativeSummary[table],
                        commentary:staticData,
                        tableData:data,
                        isLoading:false
                    }
                  }

              }
              })
              localStorage.setItem(
                `${currentOrg}-${buyerGuideName}-${scenarioName}-ComparativeSummary-${table}`,
                JSON.stringify({
                  comparativeSummary: staticData,
                  maxGenerationLimit: 3,
                  currentGenerationCount: 1,
                })
              );
            }
          }
        };

        if (table && data.length>0 && !reportData?.comparativeSummary) {
            if(data.length>0){
            fetchData(data);
            }
            else{
              return
            }
        } else if (table && data) {
         setCommentary((prev) => {
                return {
                  ...prev,
                  ComparativeSummary:{
                    ...prev?.ComparativeSummary,
                    [table]:{
                        ...prev?.ComparativeSummary[table],
                        commentary:reportData?.comparativeSummary,
                        generateCount:reportData.maxGenerationLimit - reportData?.currentGenerationCount,
                        tableData:data,
                        isLoading:false
                    }
                  }
              }
              });
        } else {
          return;
        }
      }else{
        return
      }
    }, [
      table,
      setCommentary,
      data,
      currentOrg,
      scenarioName,
      isViewReport,
      buyerGuideName,
      currentBuyerGuideId,
      currentOrganizationId,
      currentPackage.id,
      currentScenarioId,
      userProfile,
      generateReport
    ]);

  useEffect(() => {
    if(!isViewReport){
    const updateLayout = () => {
      setLayout((prev) => {
        const newLayout = prev.map((item) => {
          if (item.i !== widgetKey) {
            return item;
          } else {
            const newItem = {
              ...item,
              h: 0.2 + mainData?.current?.clientHeight / 70,
            };
            return newItem;
          }
        });
        return newLayout;
      });
    };
    const rafId = requestAnimationFrame(updateLayout);
    return () => {
      // Clean up on unmount - cancel the requestAnimationFrame if it is still pending
      cancelAnimationFrame(rafId);
    };
    }else{
        return
     }
  }, [mainData?.current?.clientHeight, setLayout,widgetKey,isViewReport]);

  const handleToggleRow = (index) => {
    setExpandedRows((prevExpandedRows) => ({
      ...prevExpandedRows,
      [index]: !prevExpandedRows[index],
    }));
  };

  const renderDetails = (details, isExpanded, index) => {
    const displayedDetails = isExpanded ? details : details.slice(0, 3);
    const moreText = details.length > 3 ? (isExpanded ? "View Less" : "View More") : null;

    return (
      <>
        {displayedDetails.join(", ")}
        {moreText && (
          <Link
            component="button"
            variant="body2"
            onClick={(e) => {
              e.preventDefault();
              handleToggleRow(index);
            }}
            style={{ marginLeft: 8 }}
          >
            {moreText}
          </Link>
        )}
      </>
    );
  };

  const enhancedData = data.map((row, index) => ({
    id: index, // Add an id field for the DataGrid
    useCase: row.UseCase,
    importanceScore: row.Importance.toFixed(1),
    mandatory: row.MandatoryFlag,
    offers: showDetails ? renderDetails(row.PathNames, expandedRows[index], index) : row.OfferCount,
    vendors: showDetails
      ? renderDetails(row.PathNames, expandedRows[index], index)
      : row.VendorCount,
  }));

  if (error) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
        <Alert severity="error">Error fetching data: {error}</Alert>
      </Box>
    );
  }

  return loading ? (
    // <Box
    //   height="250px"
    //   width="100%"
    //   justifyContent="center"
    //   alignItems="center"
    //   overflow="hidden"
    //   display="flex"
    // >
    //   <CircularProgress />
    // </Box>
    <Loader open={loading} />
  ) : enhancedData.length > 0 ? (
    <Box
      ref={mainData}
      className="widget-header-container"
      sx={{
        justifyContent: "center",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        // minWidth: "100%",
        // height: "100%", // Fill the available space
        // overflow: "auto", // Prevent overflow
      }}
    >
      {/* Divider */}
      <Divider sx={{ marginY: 1, width: "95%" }} />

      {/* Main Content */}
      <Box
        data-test-id="coverage"
        className="thinner-scrollbar"
        sx={{ height: "100%", width: "100%" }}
      >
        <DataGridTable name="coverage" rows={enhancedData} columns={columns} />
      </Box>
    </Box>
  ) : (
    <NoDataAvilable />
  );
};

export default CoverageHeatmap;
