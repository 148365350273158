export default function useHidden(setNodes, setLayouted, nodes, edges) {
  const hideChildrens = (id) => {
    return;
  };

  const getMaxDepth =  (nodeId) => {
    // Find all children of the current node
    const childEdges = edges.filter((edge) => edge.source === nodeId);
    const childNodes = childEdges.map((edge) => nodes.find((node) => node.id === edge.target));

    if (childNodes.length === 0) {
      // If no children, this is a leaf node
      return 0;
    }

    // Recursively calculate the max depth for all children
    const childDepths = childNodes.map((childNode) => getMaxDepth(childNode.id, nodes, edges));
    return 1 + Math.max(...childDepths);
  };

  const expandNodesTillDepth = (nodeId, maxDepth) => {
    // Helper function to traverse and update the hierarchy
    const traverseAndUpdate = (currentNodeId, currentDepth) => {
      if (currentDepth > maxDepth) {
        return;
      }

      // Find the node to update
      const node = nodes.find((n) => n.id === currentNodeId);
      if (node) {
        node.hidden = currentDepth > maxDepth ? true : false; // Update hidden property
      }

      // Get all children of the current node
      const childEdges = edges.filter((edge) => edge.source === currentNodeId);
      const childNodes = childEdges.map((edge) => nodes.find((n) => n.id === edge.target));

      // Recursively update children
      childNodes.forEach((childNode) => {
        if (childNode) {
          traverseAndUpdate(childNode.id, currentDepth + 1);
        }
      });
    };

    // Start traversal from the given node
    traverseAndUpdate(nodeId, 1);

    return nodes;
  };

  return { hideChildrens, expandNodesTillDepth, getMaxDepth };
}
