import React, { useContext, useEffect, useMemo, useRef, useState } from "react";

import DataGridTable from "../../../components/DataGridTable";
import { apiService } from "../../../utils/utils"; // Adjust the path as necessary
import { UserProfileContext } from "../../../context/UserContext";
import { Box, Divider } from "@mui/material";
import "./ComponentStyle.css";
import NoDataAvilable from "../../../components/NoDataAvilable";
import Loader from "../../../components/Loader";
import { ComparativeTableMap } from "../../Utils/helper";
const BestOfBest = ({ widgetKey, setLayout,isViewReport ,generateReport , setCommentary,table}) => {
  const { userProfile, currentBuyerGuideId, currentScenarioId, currentPackage, currentOrg,buyerGuideName,
    scenarioName,currentOrganizationId } =
    useContext(UserProfileContext);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [data, setData] = useState(null);
  const mainData = useRef(null);
  const [, setCommentryLoading] = useState(true);
  const [, setCommentryData] = useState("");
  const staticData =
      "### Summary of Top 5 Unused Features ('Not Required' Use Cases)\r\n\r\nThe widget titled \"Top 5 Unused Features ('Not Required' Use Cases)\" identifies the five least utilized features within a given application, specifically highlighting areas where these features are deemed unnecessary by users. The data represents an analysis of feature usage within the Zscaler Private Access platform. Each feature listed contributes equally to the total share of unused features, with a contribution score of 11.11%.\r\n\r\n#### Detailed Breakdown:\r\n\r\n1. **Secure Workforce**\r\n   - **Feature Contribution Score:** 11.11%\r\n   - **Lineage:** Zscaler Private Access > Secure Workforce\r\n\r\n2. **Secure Hybrid Workforce**\r\n   - **Feature Contribution Score:** 11.11%\r\n   - **Lineage:** Zscaler Private Access > Secure Workforce > Secure Hybrid Workforce\r\n\r\n3. **Branch & Remote Office Connectivity**\r\n   - **Feature Contribution Score:** 11.11%\r\n   - **Lineage:** Zscaler Private Access > Branch & Remote Office Connectivity\r\n\r\n4. **App Segmentation**\r\n   - **Feature Contribution Score:** 11.11%\r\n   - **Lineage:** Zscaler Private Access > App Segmentation\r\n\r\n5. **Digital Experience Monitoring**\r\n   - **Feature Contribution Score:** 11.11%\r\n   - **Lineage:** Zscaler Private Access > Monitoring & Optimization > Digital Experience Monitoring\r\n\r\nEach feature’s lineage indicates its hierarchical placement within the broader Zscaler Private Access system, providing context on its primary function and relationship to other functionalities within the platform. By identifying these unused features, stakeholders can gain insights into possible areas for optimization, user education, or feature redesign, aligning product offerings more closely with user needs and preferences.";
  useEffect(() => {
    const fetchBestOfBestData = async () => {
      try {
        setLoading(true);
        const data = await apiService(
          "https://newtestfuncpython.azurewebsites.net/api/getBestofBest?",
          {
            method: "POST",
            data: {
              email: userProfile?.email,
              currentOrganisation: currentOrg,
              currentBuyerGuide: currentBuyerGuideId,
              currentScenario: currentScenarioId,
              currentPackage: currentPackage?.id,
              displayUseCasesOptions: "Both",
            },
          },
          setLoading,
          null,
          setError
        );

        // Only update state if mounted
        if (data) {
          setData(data);
          setLoading(false);
        }
      } catch (err) {
        console.error(err);
        setError(err); // Only update error if mounted
        setLoading(false);
      }
    };
    fetchBestOfBestData();
  }, [
    setLayout,
    widgetKey,
    userProfile,
    currentOrg,
    currentBuyerGuideId,
    currentScenarioId,
    currentPackage?.id,
    
  ]);
  useEffect(() => {
      if (isViewReport&&data) {
        const reportData = JSON.parse(
          localStorage.getItem(
            `${currentOrg}-${buyerGuideName}-${scenarioName}-ComparativeSummary-${table}`
          )
        );
        
        const fetchData = async (data) => {
          const payload = {
            email: userProfile?.email,
            currentOrganisation: currentOrganizationId,
            currentBuyerGuide: currentBuyerGuideId,
            currentPackage: currentPackage.id,
            currentScenario: currentScenarioId,
            reportComparativeTable: ComparativeTableMap[table],
            comparativeTableJson: data,
          };
          if (table && data) {
            try {
              const endPoint = generateReport?"generateComparativeSummary":"getComparativeSummary"
              const response = await apiService(
                `https://newtestfuncpython.azurewebsites.net/api/${endPoint}?`,
                {
                  method: "POST",
                  data: payload,
                },
                setCommentryLoading,
                setCommentryData,
                setError
              );

              setCommentary((prev) => {
                return {
                  ...prev,
                  ComparativeSummary:{
                    ...prev?.ComparativeSummary,
                    [table]:{
                        ...prev?.ComparativeSummary[table],
                        commentary:response?.comparativeSummary,
                        generateCount:response.maxGenerationLimit - response?.currentGenerationCount,
                        tableData:data,
                        isLoading:false
                    }
                  }

              }
              });

              localStorage.setItem(
                `${currentOrg}-${buyerGuideName}-${scenarioName}-ComparativeSummary-${table}`,
                JSON.stringify(response)
              );
            } catch (error) {
              console.error("Failed to fetch data:", error);
              setError(error);
              setCommentary((prev) => {
                return {
                  ...prev,
                  ComparativeSummary:{
                    ...prev?.ComparativeSummary,
                    [table]:{
                        ...prev?.ComparativeSummary[table],
                        commentary:staticData,
                        tableData:data,
                        isLoading:false
                    }
                  }

              }
              })
              localStorage.setItem(
                `${currentOrg}-${buyerGuideName}-${scenarioName}-ComparativeSummary-${table}`,
                JSON.stringify({
                  comparativeSummary: staticData,
                  maxGenerationLimit: 3,
                  currentGenerationCount: 1,
                })
              );
            }
          }
        };

        if (table && data && !reportData?.comparativeSummary) {
            if(data){
            fetchData(data);
            }
            else{
              return
            }
        } else if (table && data) {
         setCommentary((prev) => {
                return {
                  ...prev,
                  ComparativeSummary:{
                    ...prev?.ComparativeSummary,
                    [table]:{
                        ...prev?.ComparativeSummary[table],
                        commentary:reportData?.comparativeSummary,
                        generateCount:reportData.maxGenerationLimit - reportData?.currentGenerationCount,
                        tableData:data,
                        isLoading:false
                    }
                  }
              }
              });
        } else {
          return;
        }
      }else{
        return
      }
    }, [
      table,
      setCommentary,
      data,
      currentOrg,
      scenarioName,
      isViewReport,
      buyerGuideName,
      currentBuyerGuideId,
      currentOrganizationId,
      currentPackage.id,
      currentScenarioId,
      userProfile,
      generateReport
    ]);

  // Transform the response data into the format expected by DataGridTable
  const bestOfBestData = useMemo(() => {
    if (!data || !data.MandatoryMetrics || !data.OverallMetrics) {
      return [];
    }
    return [
      ["Metrics", "Mandatory Use Case", "Overall Use Case"],
      [
        "Maximum Offer Score",
        `${data?.MandatoryMetrics?.MaxMandatoryOfferScore?.toFixed(1)}`,
        `${data?.OverallMetrics?.MaxOverallOfferScore?.toFixed(1)}`,
      ],
      [
        "Maximum Offer Relevance",
        `${data?.MandatoryMetrics?.MaxMandatoryOfferRelevance?.toFixed(1)}`,
        `${data?.OverallMetrics?.MaxOverallOfferRelevance?.toFixed(1)}`,
      ],
      [
        "Maximum Offer Utilization",
        `${data?.MandatoryMetrics?.MaxMandatoryOfferUtilisation?.toFixed(1)}`,
        `${data?.OverallMetrics?.MaxOverallOfferUtilisation?.toFixed(1)}`,
      ],
    ];
  }, [data]);

  // Prepare columns from the first row of data
  const columns = useMemo(() => {
    return bestOfBestData[0]
      ? bestOfBestData[0].map((heading, index) => ({
          field: `col${index}`,
          headerName: heading,
          flex: 1,
          minWidth: 220,
          headerClassName: "super-app-theme--header",
          renderCell: (params) => (
            <Box
              sx={{
                overflowY: "hidden", // Apply overflowY: hidden to each column
                display: "flex",
                justifyContent: index !== 0 ? "center" : "flex-start", //to target each column of table
                alignItems: "center",
                width: "100%",
                height: "100%", // Adjust height as needed
              }}
            >
              {params.value}
            </Box>
          ),
        }))
      : [];
  }, [bestOfBestData]);

  // Prepare rows from the rest of the data
  const rows = useMemo(() => {
    return bestOfBestData?.slice(1).map((row, index) => {
      const rowData = {};
      row.forEach((cell, cellIndex) => {
        rowData[`col${cellIndex}`] = cell;
      });

      return { id: index, ...rowData };
    });
  }, [bestOfBestData]);

  useEffect(() => {
    if(!isViewReport){
    const updateLayout = () => {
      setLayout((prev) => {
        const newLayout = prev.map((item) => {
          if (item.i !== widgetKey) {
            return item;
          } else {
            const newItem = {
              ...item,
              h: 0.3 + (mainData.current?.clientHeight ?? 0) / 70, // Update based on the data length
            };
            return newItem;
          }
        });
        return newLayout;
      });
    };

    const rafId = requestAnimationFrame(updateLayout);
    return () => {
      // Clean up on unmount - cancel the requestAnimationFrame if it is still pending
      cancelAnimationFrame(rafId);
    };
    }else{
        return 
    }
    // eslint-disable-next-line
  }, [mainData?.current?.clientHeight]);

  if (error) {
    return <div>Error loading data</div>;
  }

  return loading ? (
    // <Box
    //   height="250px"
    //   width="100%"
    //   justifyContent="center"
    //   alignItems="center"
    //   overflow="hidden"
    //   display="flex"
    // >
    //   <CircularProgress />
    // </Box>
    <Loader open={loading} />
  ) : rows ? (
    <Box
      ref={mainData}
      sx={{
        justifyContent: "center",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      {/* Divider */}
      <Divider sx={{ marginY: 1, width: "95%" }} />

      <Box
        data-test-id="chartTable"
        className="thinner-scrollbar"
        sx={{ height: "100%", width: "100%" }}
      >
        <DataGridTable name="bestofbest" rows={rows} columns={columns} />
      </Box>
    </Box>
  ) : (
    <NoDataAvilable />
  );
};

export default BestOfBest;
