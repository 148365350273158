import React, { useEffect, useState } from "react";
import "./LaunchingSoonLogOut.css";
import { Box, Button, Typography } from "@mui/material";
import { gsap } from "gsap";
import PopupAction from "../../components/PopupAction";
const LaunchingSoonForLogOut = () => {
  const [open, setOpen] = useState(false);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  useEffect(() => {
    gsap.set(".demo", { autoAlpha: 1 });
    const animation = gsap.timeline({ repeat: -1});
    // Increase the duration for both from and to animations
    animation
      .from(".demo div", { y: 30, opacity: -1, stagger: 2, duration: 2 })
      .to(".demo div", { y: -30, opacity: -1, stagger: 2, duration: 2 }, 2); // Added +=1 to delay the start of the repeat
  }, []);
  return (
    <Box
      sx={{
        height: { xs: "100vh", sm: "100vh" },
        width: { xs: "100vw", sm: "100vw" },
        display: "flex",
        alignContent: "center",
        justifyContent: "center",
      }}
    >
      <Box
        className="background-container"
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
        }}
      >
        <Box>
          <Box sx={{ position: "absolute", top: "6rem", left: "5rem" }}>
            <Typography
              sx={{
                fontSize: { xs: "32px", sm: "48px", md: "64px" },
                fontWeight: "600",
              }}
            >
              <Box sx={{ display: "inline-block", color: "#4F40BA" }}>
                Smart
              </Box>{" "}
              <Box sx={{ display: "inline-block", color: "#40BAB4" }}>
                Decision
              </Box>
            </Typography>
          </Box>

          <Box
            sx={{
              position: "absolute",
              top: { xs: "9rem", sm: "10rem", md: "11rem" },
              left: "5rem",
              fontFamily: "Roboto",
              fontSize: { xs: "1rem", sm: "1.66rem", md: "2.5rem" },
              fontWeight: "400",
            }}
          >
            Assistant
          </Box>
          <Box
            className="demo"
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "flex-start",
            }}
          >
            <Box>Designed for end-users and consultants.</Box>
            <Box>Matches your needs with best solutions.</Box>
            <Box>Delivers expert insights at your fingertips.</Box>
            <Box>Frees you from months of research work.</Box>
          </Box>
          <Box
            sx={{
              position: "absolute",
              top: "15rem",
              left: "5rem",
              fontFamily: "Roboto",
              fontSize: "1rem",
              width: "50%",
            }}
          >
            <Typography>
              Discover the smarter way to find your perfect solution.
            </Typography>
            <Typography sx={{ display: "inline", marginTop: "1rem" }}>
              Smart Decision Assistant makes choosing the right option simple,
              fast, and stress-free.
            </Typography>
          </Box>
          <Typography
            sx={{
              position: "absolute",
              top: { xs: "26rem", sm: "22rem", md: "21rem" },
              left: "5rem",
              fontFamily: "Roboto",
              fontSize: "1rem",
              display: "inline",
              width: "50%",
            }}
          >
            Starting with <strong>CNAPP</strong> solutions, we’re making
            decisions effortless.
          </Typography>
        </Box>

        <Box
          sx={{
            padding: "1rem",
            textAlign: "center",
            position: "relative",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            top: { xs: "0rem", sm: "0rem", md: "-1rem" },
          }}
        >
          {" "}
          {/* Adjusted to move closer to the button */}
          <Box
            sx={{
              position: "absolute",
              top: { xs: "0rem", sm: "-0.4rem", md: "-1rem" }, // Adjust this value to place the text right above the button
              width: "90%",
              fontFamily: "Roboto",
              fontSize: { xs: "0.6rem", sm: "1rem" },
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              color: "white",
            }}
          >
            To explore a self-service{" "}
            <Box
              sx={{
                display: "inline-block",
                fontFamily: "Roboto",
                fontSize: { xs: "0.5rem", sm: "1rem" },
                fontWeight: "600",
              }}
            >
              {"\u00A0"}Demo{"\u00A0"}
            </Box>{" "}
            and{" "}
            <Box
              sx={{
                display: "inline-block",
                fontFamily: "Roboto",
                fontSize: { xs: "0.6rem", sm: "1rem" },
                fontWeight: "600",
              }}
            >
              {"\u00A0"}Experience{"\u00A0"}
            </Box>{" "}
            it for yourself!
          </Box>
          <Button
            sx={{
              height: { xs: "20px", sm: "30px" },
              background: "#3D3197",
              width: "200px",
              top: { xs: "0rem", sm: "0.5rem", color: "white" },
            }}
            onClick={handleOpen}
          >
            Let's Connect
          </Button>
        </Box>
      </Box>
      <PopupAction
        open={open}
        title="Contact Us Form"
        handleClose={handleClose}
        showCross={true}
      >
        {/* Microsoft Form iframe */}
        <iframe
          title="contactForm"
          width="920px"
          height="640px"
          src="https://forms.office.com/Pages/ResponsePage.aspx?id=OTMTgvzEgki51f0e4WQKQn6TdxpOVrNJnoi9tf95d-JURTMzRkgxRFFGSFc0UEczOUI3SE82VjdHRi4u&embed=true"
          frameborder="0"
          marginwidth="0"
          marginheight="0"
          style={{
            border: "none",
            maxWidth: "100%",
            maxHeight: "100vh",
          }}
          allowfullscreen
          webkitallowfullscreen
          mozallowfullscreen
          msallowfullscreen
        >
          {" "}
        </iframe>
      </PopupAction>
    </Box>
  );
};

export default LaunchingSoonForLogOut;
