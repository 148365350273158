import { Box, Typography, useTheme } from "@mui/material";
import { Handle, NodeToolbar, Position } from "@xyflow/react";
import React from "react";

// RootNode Component
const RootNode = React.memo(({ data, selected }) => {
  const theme = useTheme();
  console.log("rooooot", data);
  const depth  = data.getMaxDepth()
  console.log(depth)
  const style = selected ? { border: `2px solid ${theme.palette.primary.main}`, boxShadow: `0px 0px 10px ${theme.palette.primary.main}` } : null;
  return (
    <Box
      p={2}
      sx={{
        background: "#fff",
        maxWidth: "270px",
        boxShadow: 3,
        borderRadius: 3,
        pointerEvents: "all",
        ...style,
      }}
    >
      <NodeToolbar isVisible={true}>
        <button>expand</button>
      </NodeToolbar>
      <Box>
        <Typography variant="h6">Name:</Typography>
        <Typography variant="body1" sx={{ whiteSpace: "break-spaces" }}>
          {data.label}
        </Typography>

        <Typography variant="h6">Type:</Typography>
        <Typography variant="body1" sx={{ whiteSpace: "break-spaces" }}>
          {data.type}
        </Typography>

        <Typography variant="h6">Description:</Typography>
        <Typography variant="body1" sx={{ whiteSpace: "break-spaces" }}>
          {data.description}
        </Typography>

        <Typography variant="caption">{data.source}</Typography>
      </Box>
      <Handle type="target" position={Position.Top} />
      <Handle type="source" position={Position.Bottom} />
    </Box>
  );
});

// ChildrenNode Component
const ChildrenNode = React.memo(({ data, selected }) => {
  const theme = useTheme();
  // console.log(data);
  const style = selected ? { border: `2px solid ${theme.palette.primary.main}`, boxShadow: `0px 0px 10px ${theme.palette.primary.main}` } : null;
  return (
    <Box
      p={2}
      sx={{
        boxShadow: 3,
        borderRadius: 3,
        maxWidth: "270px",
        background: "#fff",
        pointerEvents: "all",
        ...style,
      }}
    >
      <NodeToolbar isVisible={true}>
        <button>expand</button>
      </NodeToolbar>
      <Box>
        <Typography variant="h6">Name:</Typography>
        <Typography variant="body1" sx={{ whiteSpace: "break-spaces" }}>
          {data.label}
        </Typography>

        <Typography variant="h6">Description:</Typography>
        <Typography variant="body1" sx={{ whiteSpace: "break-spaces" }}>
          {data.description}
        </Typography>
      </Box>
      <Handle type="target" position={Position.Top} />
      <Handle type="source" position={Position.Bottom} />
    </Box>
  );
});

export { RootNode, ChildrenNode };
