/* eslint-disable jsx-a11y/iframe-has-title */
import React, { useContext, useState } from "react";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import ResponsiveAppBar from "../pages/TopMostBar";
import { Alert, Snackbar } from "@mui/material";
import { useLocation } from "react-router-dom";
import "../pages/TopNavBar.css";
// import BreadcrumbsComponent from "../components/Breadcrumbs";
import { UserProfileContext } from "../context/UserContext";
import CustomBreadcrumbs from "../components/Breadcrumbs/CustomBreadcrumbs";
import PopupAction from "../components/PopupAction";

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  ...theme.mixins.toolbar,
}));

const Main = styled("main", { shouldForwardProp: (prop) => prop !== "open" })(
  ({ theme, open }) => ({
    flexGrow: 1,
  })
);

export default function Layout({ children }) {
  const { notification, handleCloseNotification } =
    useContext(UserProfileContext);
  const [open, setOpen] = useState(false);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const location = useLocation();
  const { pathname } = location;
  const pathParts = pathname.split("/");
  const prefix = `/${pathParts[1]}`;

  return (
    <>
      <Box sx={{ display: "flex" }}>
        <CssBaseline />
        <ResponsiveAppBar prefix={prefix} handleOpen={handleOpen} />
        <Main>
          <DrawerHeader />
          <CustomBreadcrumbs />
          {/* <BreadcrumbsComponent /> */}

          {children}
        </Main>
        <Snackbar
          open={notification.open}
          autoHideDuration={6000}
          onClose={handleCloseNotification}
        >
          <Alert
            onClose={handleCloseNotification}
            severity={notification.severity}
          >
            {notification.message}
          </Alert>
        </Snackbar>
        <PopupAction
          open={open}
          title="Contact Us Form"
          handleClose={handleClose}
          showCross={true}
        >
          {/* Microsoft Form iframe */}
          <iframe
            width="920px"
            height="640px"
            src="https://forms.office.com/Pages/ResponsePage.aspx?id=OTMTgvzEgki51f0e4WQKQn6TdxpOVrNJnoi9tf95d-JURTMzRkgxRFFGSFc0UEczOUI3SE82VjdHRi4u&embed=true"
            frameborder="0"
            marginwidth="0"
            marginheight="0"
            style={{
              border: "none",
              maxWidth: "100%",
              maxHeight: "100vh",
            }}
            allowfullscreen
            webkitallowfullscreen
            mozallowfullscreen
            msallowfullscreen
          >
            {" "}
          </iframe>
        </PopupAction>
      </Box>
    </>
  );
}
