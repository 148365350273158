export default function useDragAndDropFeature(nodes, setSelectedNodes, setMenuPosition, setSelectedNode) {
  const detectOverlap = (draggedNode) => {
    let overlappedNode = null;
    nodes.forEach((node) => {
      if (
        draggedNode.id !== node.id && // Ensure it's not the same node
        draggedNode.position.x < node.position.x + 150 &&
        draggedNode.position.x + 150 > node.position.x &&
        draggedNode.position.y < node.position.y + 150 &&
        draggedNode.position.y + 150 > node.position.y
      ) {
        overlappedNode = node; // Capture the overlapped node
      }
    });

    return overlappedNode;
  };

  const onNodeDragStop = (event, draggedNode) => {
    // Detect if the dragged node intersects with another node
    const targetNode = detectOverlap(draggedNode);
    if (targetNode) {
      // Open menu and show details of the node below
      setSelectedNodes((prev) => {
        return [...prev, targetNode, draggedNode];
      });
      setMenuPosition({ x: event.clientX, y: event.clientY });
      setSelectedNode(draggedNode);
    } else {
      setSelectedNodes([]);
    }
  };
  return { onNodeDragStop };
}
