import React, { useContext, useEffect } from "react";

import { useNavigate, useLocation } from "react-router-dom";

import {
  Container,
  Box,
  Typography,
  Button,
  Checkbox,
  FormControlLabel,
  // Grid,
  CircularProgress,
} from "@mui/material";
import { styled } from "@mui/system";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { UserProfileContext } from "../context/UserContext";

import ExploreIcon from "@mui/icons-material/Explore";
import LibraryBooksIcon from "@mui/icons-material/LibraryBooks";
import SubscriptionsIcon from "@mui/icons-material/Subscriptions";
import AssignmentIndIcon from "@mui/icons-material/AssignmentInd";
import RateReviewIcon from "@mui/icons-material/RateReview";
import StorageRoundedIcon from "@mui/icons-material/StorageRounded";

import { apiService } from "../utils/utils";

const StyledButton = styled(Button)(({ theme }) => ({
  position: "relative",
  overflow: "hidden",
  padding: "2.5rem",
  height: "100%",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  boxShadow: theme.shadows[3],
  background: "transparent",
  color: theme.palette.text.primary,
  transition: "color 0.3s ease-in-out, box-shadow 0.3s ease-in-out",
  "&:before": {
    content: '""',
    position: "absolute",
    left: 0,
    bottom: 0,
    width: "100%",
    height: "0%",
    backgroundColor: theme.palette.primary.main,
    zIndex: 0,
    transition: "height 0.3s ease-in-out",
  },
  "&:hover:before": {
    height: "100%",
    color: "#fff",
  },
  "&:hover": {
    boxShadow: theme.shadows[6],
    color: "#fff",
  },
  "& .MuiTypography-root": {
    position: "relative",
    zIndex: 1,
    transition: "transform 0.3s ease-in-out, opacity 0.3s ease-in-out",
  },
  "& .title": {
    transform: "translateY(0)",
    textAlign: "center", // Centering the title text
    height: "auto", // Ensure it takes up space when visible
    display: "block", // Make sure it is displayed by default
  },
  "& .subtitle": {
    visibility: "hidden",
    // display: "none",
    transform: "translateY(100%)",
    color: "#fff", // Setting the subtitle text color to white
    textAlign: "center", // Centering the subtitle text
  },
  "&:hover .title": {
    transform: "translateY(-100%)",
    height: 0, // Set height to 0 to ensure it does not take up space
    visibility: "hidden", // Hide the title
    overflow: "hidden", // Prevent it from taking up space
    margin: 0, // Remove margin
    padding: 0, // Remove padding
    display: "none", // Ensure it is not displayed
  },
  "&:hover .subtitle": {
    // display: "block",
    visibility: "visible",
    transform: "translateY(0)",
  },
  "& .icon": {
    fontSize: "3rem",
    marginBottom: "1rem",
  },
  "&:hover .icon": {
    opacity: 0, // Fade out the icon
    transform: "scale(0)", // Shrink the icon
    display: "none", // Hide the icon
  },
}));
const theme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 700,
      lg: 1200,
      xl: 1536,
      customBp: 1400, // Add custom breakpoint at 1400px
    },
  },
});
const WelcomePage = ({ prefix }) => {
  const {
    userProfile,
    isProfileLoading,
    interestedBuyerGuides,
    // interestedBuyerGuidesLoading,
    availableUnitsForAllocation,
    fetchAvailableUnitsForAllocation,
    // loadingBuyerGuide,
  } = useContext(UserProfileContext);
  const location = useLocation();
  const navigate = useNavigate();

  const createPurchasePackageNode = async (inputData) => {
    const response = await apiService(
      `https://newtestfuncpython.azurewebsites.net/api/orchestrators/DurableFunctionsOrchestrator1`,
      {
        method: "POST",
        data: {
          inputData,
        },
      }
    );

    if (response) {
      console.log("first response: " + JSON.stringify(response));
    }
  };

  function generatePackages(input, customerId) {
    const packageMap = {
      0: "Solo Starter",
      1: "Duel Dynamics",
      2: "Focus Five",
      3: "All Available",
    };

    const packages = [];

    // Iterate over the duration object keys (0 for Monthly, 1 for Yearly)
    Object.keys(input?.duration).forEach((durationKey) => {
      let duration = "";
      if (input?.duration?.[durationKey]?.length > 0) {
        duration = +durationKey === 0 ? "Monthly" : "Yearly";
      }

      const selectedPackages = input?.duration?.[durationKey];

      selectedPackages?.forEach((packageNumber) => {
        const packageType = packageMap[packageNumber];

        // Determine the number of subscriptionIDs needed
        const packageQuantity = input?.volumeUnitsByPackage?.[packageNumber];

        const packageDetail = {
          packageType: packageType,
          customerId: customerId,
          packageQuantity: packageQuantity,
          subscriptionDuration: duration,
        };

        packages.push(packageDetail);
      });
    });

    return packages;
  }

  useEffect(() => {
    if (prefix !== "/demo") fetchAvailableUnitsForAllocation();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    // const sessionId = queryParams.get("session_id");

    const customerId = queryParams.get("customer_id");
    const packageDetailsStr = queryParams.get("packageDetails");
    const productsForSubStr = queryParams.get("productsForSub");
    let packageDetails;
    let products;
    if (packageDetailsStr && productsForSubStr) {
      packageDetails = JSON.parse(decodeURIComponent(packageDetailsStr));
      products = JSON.parse(decodeURIComponent(productsForSubStr));
    }

    if (customerId && packageDetails && products) {
      // console.log("customerId", customerId);
      // console.log("packageDetails", packageDetails);
      const packages = generatePackages(packageDetails, customerId);

      const inputData = {
        packages,
        customerId,
        products,
        email: userProfile?.email,
      };

      createPurchasePackageNode(inputData);
    }

    return () => {
      sessionStorage.removeItem("radioData");
      sessionStorage.removeItem("availableUnitsForAllocation");
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  return isProfileLoading ? (
    <Box
      height="80vh"
      width="100%"
      justifyContent="center"
      alignItems="center"
      display="flex"
    >
      <CircularProgress />
    </Box>
  ) : (
    <Container
      // maxWidth="md"
      sx={{
        textAlign: "center",
        marginTop: "2rem",
        height: "70%",
        width: "100%",
      }}
    >
      <Typography variant="h2" gutterBottom>
        Welcome {userProfile?.displayName}!
      </Typography>
      <Typography variant="h4" gutterBottom sx={{ color: "#666666" }}>
        What do you want to do today?
      </Typography>
      {/* <Grid container spacing={3} mt={4}> */}
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          flexWrap: "wrap",
          height: "100%",
          width: "100%",
          gap: 3,
          justifyContent: "center",
          alignItems: "center",
          marginTop: { sm: 8, lg: 2 },
        }}
      >
        {/* <Grid item xs={12} sm={6} md={4}> */}
        <StyledButton
          data-test-id="Smart Decision Advisor"
          data-testid="Smart Decision Advisor"
          variant="outlined"
          // fullWidth
          sx={{ height: "280px", width: "290px" }}
          onClick={() => {
            if (userProfile?.profileType === "consultant")
              navigate(`${prefix}/select-end-user-org`);
            else if (
              Array.isArray(interestedBuyerGuides) &&
              interestedBuyerGuides?.length > 0
            )
              navigate(`${prefix}/select-buyer-guide/buyer-guide-of-interest`);
            else
              navigate(`${prefix}/select-buyer-guide/available-buyer-guides`);
          }}
        >
          <Typography
            data-testid="smart-decision-title"
            variant="h2"
            gutterBottom
            className="title"
          >
            Smart Decision Assistant
          </Typography>
          <ExploreIcon data-testid="smart-decision-icon" className="icon" />
          <Typography
            data-testid="smart-decision-subtitle"
            variant="body1"
            className="subtitle"
          >
            Explore & Use the Smart Decision Assistant Tool
          </Typography>
        </StyledButton>
        {/* </Grid> */}

        {/* <Grid item xs={12} sm={6} md={4}> */}
        {prefix !== "/demo" && (
          <>
            <StyledButton
              data-testid="Browse Buyer Guides"
              variant="outlined"
              // fullWidth
              sx={{ height: "280px", width: "290px" }}
            >
              <Typography variant="h2" gutterBottom className="title">
                Browse Buyer Guides
              </Typography>
              <StorageRoundedIcon
                data-testid="Browse-Buyer-Guides-icon"
                className="icon"
              />
              <Typography
                data-testid="Browse-Buyer-Guides-subtitle"
                variant="body1"
                className="subtitle"
              >
                Browse the Available Buyer Guides for various solutions
              </Typography>
            </StyledButton>
            {/* </Grid> */}

            {/* <Grid item xs={12} sm={6} md={4}> */}
            <StyledButton
              data-testid="Subscribe"
              variant="outlined"
              // fullWidth
              sx={{ height: "280px", width: "290px" }}
              onClick={() => {
                navigate(`${prefix}/packages`);
              }}
            >
              <Typography variant="h2" gutterBottom className="title">
                Subscribe
              </Typography>
              <SubscriptionsIcon
                data-testid="Subscribe-icon"
                className="icon"
              />
              <Typography
                data-testid="Subscribe-subtitle"
                variant="body1"
                className="subtitle"
              >
                Purchase subscription packages
              </Typography>
            </StyledButton>
            {/* </Grid> */}

            {/* <Grid item xs={12} sm={6} md={4}> */}
            {availableUnitsForAllocation?.some((el) => el.totalUnits > 0) && (
              <StyledButton
                data-testid="Allocate"
                variant="outlined"
                // fullWidth
                sx={{ height: "280px", width: "290px" }}
                onClick={() => {
                  navigate(`${prefix}/available-units-for-allocation`);
                }}
              >
                <Typography
                  data-test-id="Allocate"
                  variant="h2"
                  gutterBottom
                  className="title"
                >
                  Allocate
                </Typography>
                <AssignmentIndIcon
                  data-testid="Allocate-icon"
                  className="icon"
                />
                <Typography
                  data-testid="Allocate-subtitle"
                  variant="body1"
                  className="subtitle"
                >
                  Allocate subscription to Users, End-user Organisations or
                  BuyerGuides
                </Typography>
              </StyledButton>
            )}
            {/* </Grid> */}

            {/* <Grid item xs={12} sm={6} md={4}> */}
            <StyledButton
              data-testid="My Subscription"
              variant="outlined"
              // fullWidth
              sx={{ height: "280px", width: "290px" }}
              onClick={() => navigate(`${prefix}/my-subscriptions`)}
            >
              <Typography variant="h2" gutterBottom className="title">
                My Subscription
              </Typography>
              <RateReviewIcon
                data-testid="My-Subscription-icon"
                className="icon"
              />
              <Typography
                variant="body1"
                data-testid="My-Subscription-subtitle"
                className="subtitle"
              >
                Review your subscriptions & allocations
              </Typography>
            </StyledButton>
            {/* </Grid> */}

            {/* <Grid item xs={12} sm={6} md={4}> */}
            <StyledButton
              data-testid="My Report"
              variant="outlined"
              // fullWidth
              sx={{ height: "280px", width: "290px" }}
              onClick={() => {
                navigate(`${prefix}/saved-reports`);
              }}
            >
              <Typography
                variant="h2"
                data-testid="My-Report-title"
                gutterBottom
                className="title"
              >
                My Reports
              </Typography>
              <LibraryBooksIcon data-testid="My-Report-icon" className="icon" />
              <Typography
                data-testid="My-Report-subtitle"
                variant="body1"
                className="subtitle"
              >
                View saved reports
              </Typography>
            </StyledButton>
          </>
        )}
        {/* </Grid> */}
      </Box>
      {/* </Grid> */}
      <ThemeProvider theme={theme}>
        {prefix !== "/demo" && (
          <Box sx={{ marginTop: { xs: 25, sm: 18, md: 8, lg: 4 } }}>
            <FormControlLabel
              control={
                <Checkbox
                  data-testid="rememberPreference-checkbox"
                  name="rememberPreference"
                />
              }
              label="Remember my preference. Don’t show me this page again!"
            />
          </Box>
        )}
      </ThemeProvider>
    </Container>
  );
};

export default WelcomePage;
