import React, { useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { Box, Button, Typography, styled, Checkbox } from "@mui/material";

import ExploreIcon from "@mui/icons-material/Explore";
import LibraryBooksIcon from "@mui/icons-material/LibraryBooks";
import NearMeIcon from "@mui/icons-material/NearMe";
import HeaderNaming from "../components/HeaderNaming/HeaderNaming";
import { UserProfileContext } from "../context/UserContext";
import { apiService } from "../utils/utils";
const StyledButton = styled(Button)(({ theme }) => ({
  position: "relative",
  overflow: "hidden",
  padding: "2.5rem",
  height: "100%",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  boxShadow: theme.shadows[3],
  background: "transparent",
  color: theme.palette.text.primary,
  transition: "color 0.3s ease-in-out, box-shadow 0.3s ease-in-out",
  "&:before": {
    content: '""',
    position: "absolute",
    left: 0,
    bottom: 0,
    width: "100%",
    height: "0%",
    backgroundColor: theme.palette.primary.main,
    zIndex: 0,
    transition: "height 0.3s ease-in-out",
  },
  "&:hover:before": {
    height: "100%",
    color: "#fff",
  },
  "&:hover": {
    boxShadow: theme.shadows[6],
    color: "#fff",
  },
  "& .MuiTypography-root": {
    position: "relative",
    zIndex: 1,
    transition: "transform 0.3s ease-in-out, opacity 0.3s ease-in-out",
  },
  "& .title": {
    transform: "translateY(0)",
    textAlign: "center",
    height: "auto",
    display: "block",
  },
  "& .subtitle": {
    display: "none",
    transform: "translateY(100%)",
    color: "#fff",
    textAlign: "center",
  },
  "&:hover .title": {
    transform: "translateY(-100%)",
    height: 0,
    visibility: "hidden",
    overflow: "hidden",
    margin: 0,
    padding: 0,
    display: "none",
  },
  "&:hover .subtitle": {
    display: "block",
    transform: "translateY(0)",
  },
  "& .icon": {
    fontSize: "3rem",
    marginBottom: "1rem",
  },
  "&:hover .icon": {
    opacity: 0,
    transform: "scale(0)",
    display: "none",
  },
}));

function UsecaseToFeature() {
  const { userProfile, prefix } = useContext(UserProfileContext);

  const navigate = useNavigate();
  //const [isLoading, setLoading] = useState(true);
  const localStorageData = JSON.parse(localStorage.getItem("mappingModelData"));
  const mappingData = localStorageData?.mappingModelData;
  const localStorageBuyerGuideData = JSON.parse(
    localStorage.getItem("currentbuyerGuideData")
  );
  const buyerGuideId = localStorageBuyerGuideData?.buyerGuideId;
  const localStorageVendorData = JSON.parse(
    localStorage.getItem("currentVendorData")
  );
  const vendorId = localStorageVendorData?.vendorId;
  console.log(vendorId);
  const localStorageCurrentOfferData = JSON.parse(
    localStorage.getItem("currentOfferData")
  );
  const offerId = localStorageCurrentOfferData?.offerId;

  const data = {
    "Buyer Guide": localStorageBuyerGuideData?.buyerGuideName,
    "Vendor Name": localStorageVendorData?.vendorName,
    "Offer Name": localStorageCurrentOfferData?.offerName,
    "Selecte Embedding Model": mappingData?.["Select Embedding Model"],
    "Mapping Parameters": `{ Discription: ${mappingData["Mapping Parameters"]?.Description}  ,  Name: ${mappingData["Mapping Parameters"]?.Name}  ,  Path: ${mappingData["Mapping Parameters"]?.Path} ,  Question Based Mapping: ${mappingData?.["Question Based Mapping"]}}`,
  };
  const [querydata, setQueryDataMaping] = useState({
    filtersOnRecommendations: {
      doNotShowAlreadyMapped: {
        useCases: false,
        features: false,
      },
      showOnlyDirectlyConnectedNodes: false,
    },
  });
  const fetchSpecificUsecase = async () => {
    // const
    const response = await apiService(
      `https://newtestfuncpython.azurewebsites.net/api/getExistingBuyerGuideForMapping`,
      {
        method: "POST",
        data: {
          email: userProfile?.email,
          currentBuyerGuideId: buyerGuideId,
          currentOfferId: offerId,
        },
      }
    );
    localStorage.setItem("filterData", JSON.stringify(querydata));
    navigate(`${prefix}/analysis`, { state: { flag2: true, data: response } });
  };
  const fetchSpecificOffer = async () => {
    const response = await apiService(
      `https://newtestfuncpython.azurewebsites.net/api/getExistingOfferForMapping`,
      {
        method: "POST",
        data: {
          email: userProfile?.email,
          currentOfferId: offerId,
          currentBuyerGuideId: buyerGuideId,
        },
      }
    );
    localStorage.setItem("filterData", JSON.stringify(querydata));
    navigate(`${prefix}/analysis`, { state: { flag1: true, data: response } });
  };
  const fetchUseCaseOverAll = async () => {
    try {
      const payload = {
        email: userProfile?.email,
        currentBuyerGuideId: buyerGuideId,
        currentVendorId: vendorId,
        currentOfferId: offerId,
        modelAndParameterSelection: mappingData,
        currentPageNumber: 1,
        recommendationsPerPage: 10,
        filtersOnRecommendations: {
          doNotShowAlreadyMapped: {
            useCases:
              querydata?.filtersOnRecommendations?.doNotShowAlreadyMapped
                ?.useCases,
            features:
              querydata?.filtersOnRecommendations?.doNotShowAlreadyMapped
                ?.features,
          },
          showOnlyDirectlyConnectedNodes: false,
        },
      };
      const response = await apiService(
        "https://newtestfuncpython.azurewebsites.net/api/mappingRecommendationOverall",
        {
          method: "POST",
          data: payload,
        }
      );
      localStorage.setItem("filterData", JSON.stringify(querydata));
      navigate(`${prefix}/overall-recommendations`, {
        state: {
          pageType: "overAll",
          data: response,
          payload: payload,
          endPoint: "mappingRecommendationOverall",
        },
      });
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <Box>
      <HeaderNaming data={data} />
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          height: "100%",
          width: "100%",
          gap: 3,
          marginTop: { sm: 8, lg: 2 },
        }}
      >
        <Box sx={{ flexDirection: "column", display: "flex" }}>
          <Typography variant="h6">Do not show already mapped :</Typography>
          <Box
            sx={{
              flexDirection: "row",
              display: "flex",
              alignItems: "center",
              marginLeft: "3em",
            }}
          >
            <Checkbox
              onClick={() => {
                setQueryDataMaping((prev) => {
                  return {
                    ...prev,
                    filtersOnRecommendations: {
                      ...prev?.filtersOnRecommendations,
                      doNotShowAlreadyMapped: {
                        ...prev?.filtersOnRecommendations
                          ?.doNotShowAlreadyMapped,
                        useCases:
                          !prev?.filtersOnRecommendations
                            ?.doNotShowAlreadyMapped?.useCases,
                      },
                    },
                  };
                });
              }}
            />
            <Typography variant="h7" sx={{ marginLeft: "10px" }}>
              Use Case
            </Typography>
          </Box>
          <Box
            sx={{
              flexDirection: "row",
              display: "flex",
              alignItems: "center",
              marginLeft: "3em",
            }}
          >
            <Checkbox
              onClick={() => {
                setQueryDataMaping((prev) => {
                  return {
                    ...prev,
                    filtersOnRecommendations: {
                      ...prev?.filtersOnRecommendations,
                      doNotShowAlreadyMapped: {
                        ...prev?.filtersOnRecommendations
                          ?.doNotShowAlreadyMapped,
                        features:
                          !prev?.filtersOnRecommendations
                            ?.doNotShowAlreadyMapped?.features,
                      },
                    },
                  };
                });
              }}
            />
            <Typography variant="h7" sx={{ marginLeft: "10px" }}>
              Features
            </Typography>
          </Box>
        </Box>
        <Box
          sx={{
            alignItems: "center",
            marginTop: "-1em",
            marginLeft: "-0.8em",
            display: "flex",
          }}
        >
          <Checkbox
            onClick={() => {
              setQueryDataMaping((prev) => {
                return {
                  ...prev,
                  filtersOnRecommendations: {
                    ...prev?.filtersOnRecommendations,
                    showOnlyDirectlyConnectedNodes:
                      !prev?.filtersOnRecommendations
                        ?.showOnlyDirectlyConnectedNodes,
                  },
                };
              });
            }}
          />
          <Typography variant="h7" sx={{ marginLeft: "10px" }}>
            show Only directly connected nodes (Features/Use Cases)
          </Typography>
        </Box>
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          flexWrap: "wrap",
          height: "100%",
          width: "100%",
          gap: 3,
          justifyContent: "center",
          alignItems: "center",
          marginTop: { sm: 8, lg: 2 },
        }}
      >
        <StyledButton
          variant="outlined"
          sx={{ height: "280px", width: "290px" }}
          onClick={async () => {
            await fetchUseCaseOverAll();
          }}
        >
          <Typography
            data-test-id="Buyer Guide"
            variant="h2"
            gutterBottom
            className="title"
          >
            Mapping Recommendations (Overall)
          </Typography>
          <LibraryBooksIcon className="icon" />
          <Typography
            data-test-id="Explore Buyer Guide"
            className="subtitle"
            variant="body1"
          >
            Explore Mapping Recommendations (Overall)
          </Typography>
        </StyledButton>

        <StyledButton
          variant="outlined"
          sx={{ height: "280px", width: "290px" }}
          onClick={async () => {
            await fetchSpecificUsecase();
          }}
        >
          <Typography variant="h2" gutterBottom className="title">
            Recommendation For Specific Use Cases
          </Typography>
          <NearMeIcon className="icon" />
          <Typography className="subtitle" variant="body1">
            Explore Recommendation For Specific Use Cases
          </Typography>
        </StyledButton>

        <StyledButton
          variant="outlined"
          sx={{ height: "280px", width: "290px" }}
          onClick={async () => {
            await fetchSpecificOffer();
          }}
        >
          <Typography
            data-test-id="Vendor/Offer"
            variant="h2"
            gutterBottom
            className="title"
          >
            Recommendation For Specific Features
          </Typography>
          <ExploreIcon className="icon" />
          <Typography
            data-test-id="Manage Vendors and Offers"
            className="subtitle"
            variant="body1"
          >
            Explore Recommendation For Specific Features
          </Typography>
        </StyledButton>
      </Box>

      <Box
        sx={{
          alignItems: "center",
          marginTop: "1em",
          marginLeft: "-0.8em",
          display: "flex",
        }}
      >
        <Button
          sx={{ width: "250px" }}
          color="primary"
          variant="contained"
          disableRipple
        >
          Exit to Mapping Start Page
        </Button>
      </Box>
    </Box>
  );
}

export default UsecaseToFeature;
