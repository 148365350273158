import { useState } from "react";

export default function useCaseTree() {
  const [menuPosition, setMenuPosition] = useState(null);
  const [selectedNode, setSelectedNode] = useState(null);
  const [selectedNodes, setSelectedNodes] = useState([]);
  const [layouted, setLayouted] = useState(false);
  const [nodePosition, setNodePosition] = useState(null);
  const [underProcess, setUnderProcess] = useState(true);

  const handleNodeClick = (event, node) => {
    // Store the node's position
    setNodePosition(node.position);
  };

  return {
    menuPosition,
    setMenuPosition,
    selectedNode,
    setSelectedNode,
    selectedNodes,
    setSelectedNodes,
    layouted,
    setLayouted,
    handleNodeClick,
    nodePosition,
    underProcess,
    setUnderProcess,
  };
}