import { useState, useEffect, useContext } from "react";
import { UserProfileContext } from "../../../../context/UserContext";
import { apiService } from "../../../../utils/utils";
import { AllocationContext } from "../../AllocationContext";

const useUserUnit = ({ maxFields, totalQuantity, allocatedData, id, handleClose, setAllocatedData }) => {
  const [formData, setFormData] = useState([]);
  const [popup, setPopup] = useState(false);
  const [response, setResponse] = useState(null);
  const [buttonLoading, setButtonLoading] = useState(false);
  const { setNotification } = useContext(UserProfileContext);
  const { setNewUser } = useContext(AllocationContext);

  // Initialize formData based on allocatedData
  useEffect(() => {
    const dataItem = allocatedData.find((data) => data.id === id);
    if (dataItem && dataItem.userList) {
      setFormData(dataItem.userList);
    } else {
      setFormData([{ emailId: "", combinationUnits: 0 }]);
    }
  }, [allocatedData, id]);

  const validateEmail = (email) => /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(String(email).toLowerCase());

  const handleAdd = async (checkEmail) => {
    if (!validateEmail(formData[formData.length - 1].emailId)) {
      setNotification({
        open: true,
        message: "Please enter a valid email",
        severity: "warning",
      });
      return;
    }

    setButtonLoading(true);

    const response = await apiService("https://newtestfuncpython.azurewebsites.net/api/checkUserAvailabilty", {
      method: "POST",
      data: { checkEmail },
    });
    setResponse(response);

    if (!response?.userExists) {
      setPopup(true); // Open the popup if the user doesn't exist
    } else {
      // Directly add row if the user exists
      if (totalQuantity() < maxFields && formData.length < maxFields) {
        setFormData([...formData, { emailId: "", combinationUnits: 0 }]);
      }
    }

    setButtonLoading(false);
  };

  const handleButtonClick = (option) => {
    setPopup(false); // Close popup

    if (option) {
      // "Yes" clicked: Add a new row
      if (totalQuantity() < maxFields && formData.length < maxFields) {
        setFormData([...formData, { emailId: "", combinationUnits: 0 }]);
      } else {
        setNotification({
          open: true,
          message: `The total quantity must be less than ${maxFields} to add more fields.`,
          severity: "error",
        });
      }
      setNewUser((prevUsers) => {
        const newEmail = formData[formData.length - 1].emailId;
        if (prevUsers.includes(newEmail)) {
          return prevUsers;
        }
        return [...prevUsers, newEmail];
      });
    } else {
      // "No" clicked: Remove the last row or reset
      if (formData.length === 1) {
        setFormData((prevFormData) => {
          const updatedFormData = [...prevFormData];
          updatedFormData[updatedFormData.length - 1] = {
            ...updatedFormData[updatedFormData.length - 1],
            emailId: "",
            combinationUnits: 0,
          };
          return updatedFormData;
        });
      } else {
        setFormData((prevFormData) => prevFormData.slice(0, -1));
      }
    }
  };

  const handleFieldChange = (index, field, value) => {
    setFormData((prev) => prev.map((item, i) => (i === index ? { ...item, [field]: field === "combinationUnits" ? Number(value) : value } : item)));
  };

  const handleDeleteRow = (index) => {
    setFormData((prev) => prev.filter((_, i) => i !== index));
    setNewUser((prevUsers) => (prevUsers.includes(formData[formData.length - 1].emailId) ? prevUsers.filter((email) => email !== formData[formData.length - 1].emailId) : prevUsers));
  };

  // Submit handler with validation
  const handleSubmit = () => {
    let isValid = true;

    formData.forEach((item) => {
      if (!validateEmail(item.emailId) || !item.combinationUnits || item.combinationUnits < 1) {
        isValid = false;
      }
    });

    if (isValid) {
      setAllocatedData((prevData) => {
        const updatedData = [...prevData];
        const index = updatedData.findIndex((item) => item.id === id);

        if (index !== -1) {
          updatedData[index] = { ...updatedData[index], userList: formData };
        } else {
          updatedData.push({ id, userList: formData });
        }
        return updatedData;
      });
      handleClose();
    } else {
      setNotification({
        open: true,
        message: "Please ensure all email addresses are valid and each quantity is at least 1.",
        severity: "error",
      });
    }
  };

  return {
    formData,
    popup,
    response,
    buttonLoading,
    handleAdd,
    handleButtonClick,
    handleFieldChange,
    handleDeleteRow,
    validateEmail,
    handleSubmit,
  };
};

export default useUserUnit;
