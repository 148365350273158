import { useState, useCallback, useContext, useEffect } from "react";
import { AllocationContext } from "../../AllocationContext";

export const useBuyerAndEUO = (id, label, setAllocatedData, handleClose, handelUserResponse) => {
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [inputValue, setInputValue] = useState("");
  const { setselectedBG, selectedVendors, setSelectedVendors, allocatedData } = useContext(AllocationContext);

  useEffect(() => {
    const index = allocatedData.findIndex((item) => item.id === id);
    setSelectedOptions(allocatedData[index]?.[fieldMapForAllocatedData[label]] || []);
  }, [allocatedData, id, label]);

  const handleAddClick = () => {
    if (inputValue.trim() !== "") {
      setSelectedOptions((prevOptions) => [
        ...prevOptions,
        { title: inputValue.trim(), id: null }, // Add inputValue as an object with the title key
      ]);
      setInputValue(""); // Clear the text field after adding
    }
  };

  const handleDeleteClick = (title) => {
    setSelectedOptions((prevOptions) => prevOptions.filter((option) => option.title !== title));
  };

  const handelVendors = (bgListArray) => {
    // Update each bgObj in bgListArray
    const updatedBgListArray = bgListArray.map((bgObj) => {
      if (selectedVendors[bgObj.id]) {
        console.log(selectedVendors);
        // If the bgObj.id matches a key in selectedVendors, update its vendors
        const existingVendors = bgObj.vendors || [];
        const newVendors = selectedVendors[bgObj.id].filter((vendor) => !existingVendors.some((existing) => existing.id === vendor.id));
        // Return updated bgObj with merged vendors
        return {
          ...bgObj,
          vendors: [...existingVendors, ...newVendors],
        };
      } else {
        // If no match, return bgObj as is
        return bgObj;
      }
    });

    // Return the updated bgListArray
    return updatedBgListArray;
  };

  const handleSubmit = () => {
    setAllocatedData((prevData) => {
      const existingItemIndex = prevData.findIndex((item) => item.id === id);

      if (existingItemIndex !== -1) {
        // Update the existing item
        const updatedItem = {
          ...prevData[existingItemIndex],
          [fieldMapForAllocatedData[label]]: selectedOptions,
        };

        if (label === "Buyer Guide list") {
          updatedItem.bgList = handelVendors(updatedItem.bgList || []);
        }

        // Replace the item in the array
        return [...prevData.slice(0, existingItemIndex), updatedItem, ...prevData.slice(existingItemIndex + 1)];
      } else {
        // Add a new item if it doesn't exist
        const newItem = {
          id,
          [fieldMapForAllocatedData[label]]: selectedOptions,
        };

        if (label === "Buyer Guide list") {
          newItem.bgList = handelVendors([]);
        }

        return [...prevData, newItem];
      }
    });

    // Clear selected options and close the popover
    setSelectedOptions([]);
    handleClose();
  };

  const handleChipClick = useCallback(
    (option) => {
      if (label === "Buyer Guide list") {
        setselectedBG(option);
        handelUserResponse(true, option?.id);
      }
    },
    [label, setselectedBG, handelUserResponse]
  );

  const handelChipOnDelete = (option, onDelete) => {
    if (option?.id && selectedVendors[option.id] && label === "Buyer Guide list") {
      const updatedVendors = Object.fromEntries(Object.entries(selectedVendors).filter(([key]) => key !== option.id));
      setSelectedVendors(updatedVendors);
    }
    if (onDelete) onDelete();
  };

  return {
    selectedOptions,
    setSelectedOptions,
    inputValue,
    setInputValue,
    handleAddClick,
    handleDeleteClick,
    handleSubmit,
    handleChipClick,
    handelChipOnDelete,
  };
};

const fieldMapForAllocatedData = {
  "Buyer Guide list": "bgList",
  "End-User List": "euoList",
};
