import React, { useState, useContext } from "react";
import {
  Box,
  Button,
  TextField,
  Typography,
  RadioGroup,
  FormControlLabel,
  Radio,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { UserProfileContext } from "../context/UserContext";

export const CreateNewBuyerGuide = () => {
  const [buyerGuideName, setBuyerGuideName] = useState("");
  const [version, setVersion] = useState("");
  const [description, setDescription] = useState("");
  const [generateWithAI, setGenerateWithAI] = useState("yes");
  const [documentLink, setDocumentLink] = useState("");
  const [analystPrompt, setAnalystPrompt] = useState("");
  const [isError, setIsError] = useState(false);
  const [isFocused, setIsfocused] = useState(false);
  const { userProfile, prefix } = useContext(UserProfileContext);

  const navigate = useNavigate();

  const handleBuyerGuideNameChange = (e) => {
    setBuyerGuideName(e.target.value);
    if (e.target.value === "") setIsError(true);
    else setIsError(false);
  };

  const handleVersionChange = (e) => {
    setVersion(e.target.value);
  };

  const handleDescriptionChange = (e) => {
    setDescription(e.target.value);
  };

  const handleRadioChange = (event) => {
    setGenerateWithAI(event.target.value);
  };

  const handleDocumentLinkChange = (e) => {
    setDocumentLink(e.target.value);
  };

  const handleAnalystPromptChange = (e) => {
    setAnalystPrompt(e.target.value);
  };

  const handleCreateNewBuyerGuide = async () => {
    if (buyerGuideName === "") {
      setIsError(true);
      return;
    }
    try {
      // Step 1: Create the buyer guide
      const createResponse = await axios.post(
        "https://newtestfuncpython.azurewebsites.net/api/createBuyerGuideForAnalyst",
        {
          email: userProfile.email,
          //  userEmail  :"nitin.acharekar@gmail.com",
          //  userEmail  :"rajat.gupta@smartdecision.ai",
          buyerGuideName,
          buyerGuideDescription: description,
          buyerGuideVersion: version,
        }
      );
      const { buyerGuideId } = createResponse?.data;
      // Step 2: Fetch the created buyer guide details using element_id
      const fetchResponse = await axios.post(
        "https://newtestfuncpython.azurewebsites.net/api/getExistingBuyerGuideForAnalyst",
        {
          email: userProfile?.email,
          //  userEmail  :"nitin.acharekar@gmail.com",
          currentBuyerGuideId: buyerGuideId, // Use the element_id from the previous response
        }
      );
      const buyerGuideData = fetchResponse.data;
      // Step 3: Navigate to the analysis page with fetched data
      navigate(`${prefix}/analysis`, { state: { buyerGuideData } });
    } catch (error) {
      console.error("Error creating or fetching buyer guide:", error);
    }
  };

  return (
    <Box
      sx={{ width: "100%", marginLeft: 0, textAlign: "left", padding: "16px" }}
    >
      <Box sx={{ display: "flex", alignItems: "center", marginBottom: "16px" }}>
        <Typography
          data-test-id="Name of The Buyer Guide"
          sx={{ minWidth: "200px" }}
        >
          Name of The Buyer Guide:
        </Typography>
        <TextField
          data-test-id="text-field-Name of The Buyer Guide"
          spellCheck={false}
          autoComplete="off"
          sx={{ flex: 1 }}
          required
          error={isError}
          type="text"
          value={buyerGuideName}
          onChange={handleBuyerGuideNameChange}
          onFocus={(e) => {
            setIsfocused(true);
            e.target.select();
          }}
          InputLabelProps={{
            shrink: isFocused || buyerGuideName?.length > 0,
          }}
          size="small"
        />
      </Box>

      <Box sx={{ display: "flex", alignItems: "center", marginBottom: "16px" }}>
        <Typography data-test-id="Version" sx={{ minWidth: "200px" }}>
          Version:
        </Typography>
        <TextField
          data-test-id="text-field-Version"
          spellCheck={false}
          autoComplete="off"
          sx={{ flex: 1 }}
          required
          type="text"
          value={version}
          onChange={handleVersionChange}
          size="small"
        />
      </Box>

      <Box sx={{ display: "flex", alignItems: "center", marginBottom: "16px" }}>
        <Typography data-test-id="Description" sx={{ minWidth: "200px" }}>
          Description:
        </Typography>
        <TextField
          data-test-id="text-field-Description"
          variant="outlined"
          fullWidth
          multiline
          rows={2}
          sx={{ flex: 1 }}
          value={description}
          onChange={handleDescriptionChange}
        />
      </Box>

      <Box sx={{ marginBottom: "16px" }}>
        <Typography data-test-id="Generate with AI?">
          Generate with AI?
        </Typography>
        <RadioGroup
          name="generateWithAI"
          value={generateWithAI}
          onChange={handleRadioChange}
          sx={{ marginTop: "8px" }}
        >
          <FormControlLabel
            data-test-id="Yes"
            value="yes"
            control={<Radio data-test-id="radio-Yes" />}
            label="Yes"
          />
          <FormControlLabel
            data-test-id="No"
            value="no"
            control={<Radio data-test-id="radio-No" />}
            label="No"
          />
        </RadioGroup>
      </Box>

      {generateWithAI === "no" && (
        <>
          <Box
            sx={{ display: "flex", alignItems: "center", marginBottom: "16px" }}
          >
            <Typography data-test-id="Document Link" sx={{ minWidth: "200px" }}>
              Document Link:
            </Typography>
            <TextField
              data-test-id="text-field-Document Link"
              variant="outlined"
              fullWidth
              sx={{ flex: 1 }}
              value={documentLink}
              onChange={handleDocumentLinkChange}
            />
          </Box>

          <Box
            sx={{ display: "flex", alignItems: "center", marginBottom: "16px" }}
          >
            <Typography
              data-test-id="Analyst Prompt"
              sx={{ minWidth: "200px" }}
            >
              Analyst Prompt:
            </Typography>
            <TextField
              data-test-id="text-field-Analyst Prompt"
              variant="outlined"
              fullWidth
              multiline
              rows={2}
              sx={{ flex: 1 }}
              value={analystPrompt}
              onChange={handleAnalystPromptChange}
            />
          </Box>
        </>
      )}

      <Button
        data-test-id={
          generateWithAI === "yes"
            ? "button-Generate Use Cases"
            : "button-Extract Use Cases"
        }
        variant="contained"
        color="primary"
        sx={{ marginTop: "16px" }}
        onClick={handleCreateNewBuyerGuide}
      >
        {generateWithAI === "yes" ? "Generate Use Cases" : "Extract Use Cases"}
      </Button>
    </Box>
  );
};
