import React, { useState, useEffect, useContext, useMemo } from "react";
import {
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
  TextField,
  Box,
  Divider,
  Grid,
  Typography,
  useMediaQuery,
  Snackbar,
  Alert,
  // CircularProgress,
  Checkbox,
  Select,
  MenuItem,
} from "@mui/material";
import { styled } from "@mui/system";
import { UserProfileContext } from "../../context/UserContext";
import { apiService } from "../../utils/utils.js";
import HeaderNaming from "../../components/HeaderNaming/HeaderNaming.jsx";
import Loader from "../../components/Loader.jsx";
import SaveAndExit from "../../components/reusableButton/SaveAndExit.jsx";
const CustomRadio = styled(Radio)({
  "&.Mui-checked": {
    color: "#4f40ba",
  },
});

const CustomCheckbox = styled(Checkbox)({
  "&.Mui-checked": {
    color: "#4f40ba",
  },
});

const ReportOption = ({ prefix }) => {
  const [thresholdType, setThresholdType] = useState("maximumNumberOfVendors");
  const [isLoading, setIsLoading] = useState(false);
  const [numVendors, setNumVendors] = useState(1);
  const [numOffersPerVendor, setNumOffersPerVendor] = useState(1);
  const [numOffers, setNumOffers] = useState(1);
  const [primaryMetric, setPrimaryMetric] = useState("offerScore");
  const [bias, setBias] = useState({
    vendorBias: false,
    offerBias: false,
  });
  const [biasData, setBiasData] = useState([]);

  const [offers, setOffers] = useState([]);
  const [selectedVendor, setSelectedVendor] = useState({ id: "", value: "" });
  const [selectedOffer, setSelectedOffer] = useState({ id: "", value: "" });

  const handleVendorChange = (e) => {
    const selectedVendorId = e.target.value;
    const vendor = biasData.find((v) => v.vendorId === selectedVendorId);
    if (vendor) {
      setSelectedVendor({ id: vendor.vendorId, value: vendor.vendorName });
      setOffers(vendor.offers); // Update offers based on selected vendor
    } else {
      setSelectedVendor({ id: "", value: "" });
      setOffers([]); // Clear offers if no vendor is selected
    }
    setSelectedOffer({ id: "", value: "" }); // Reset selected offer
  };

  const handleOfferChange = (e) => {
    const selectedOfferId = e.target.value;
    const offer = offers.find((o) => o.offerId === selectedOfferId);
    if (offer) {
      setSelectedOffer({ id: offer.offerId, value: offer.offerName });
    }
  };

  const handleBiasChange = (e) => {
    const { value, checked } = e.target;
    setBias((prevBias) => ({
      ...prevBias,
      [value]: checked, // Update the correct bias value based on checkbox
    }));
  };

  const isMobile = useMediaQuery("(max-width:600px)");
  const {
    currentOrg,
    currentOrganization,
    buyerGuideName,
    scenarioName,
    userProfile,
    notification,
    currentBuyerGuideId,
    currentScenarioId,
    currentPackage,
    handleCloseNotification,
  } = useContext(UserProfileContext);

  const data = {
    "End-user Organisation":
      userProfile?.profileType === "endUser"
        ? userProfile?.companyName
        : currentOrganization,
    "Buyer Guide Name": buyerGuideName,
    "Scenario Name": scenarioName,
  };

  const payload = useMemo(() => {
    return {
      email: userProfile?.email,
      currentOrganisation: currentOrg,
      currentBuyerGuide: currentBuyerGuideId,
      currentScenario: currentScenarioId,
      currentPackage: currentPackage?.id,
    };
  }, [
    currentOrg,
    currentBuyerGuideId,
    currentScenarioId,
    currentPackage?.id,
    userProfile?.email,
  ]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setIsLoading(true);
        const response = await apiService(
          "https://newtestfuncpython.azurewebsites.net/api/getReportSettings?",
          {
            method: "POST",
            data: payload,
          }
        );
        if (response) {
          setThresholdType(
            response.selectedOption === "Maximum Number of Vendors"
              ? "maximumNumberOfVendors"
              : "maximumNumberOfOffers"
          );
          setNumVendors(
            response?.shortlistThresholdType.vendorsToBeShortlisted || 1
          );
          setNumOffers(
            response?.shortlistThresholdType.offersToBeShortlisted || 1
          );
          setNumOffersPerVendor(
            response?.shortlistThresholdType.offersPerVendorsToBeShortlisted ||
              1
          );
          setPrimaryMetric(
            response.primaryMetricForShortlisting.offerScore
              ? "offerScore"
              : "offerRelevance"
          );
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();

    const fetchBias = async () => {
      try {
        setIsLoading(true);
        const response = await apiService(
          "https://newtestfuncpython.azurewebsites.net/api/listOfUnlockedVendorAndOfferForBias?",
          {
            method: "POST",
            data: { ...payload },
          }
        );
        if (response) {
          setBiasData(response);
        }
      } catch (error) {
        console.error("Error fetching bias data:", error);
      } finally {
        setIsLoading(false);
      }
    };
    fetchBias();
  }, [
    currentOrg,
    currentPackage?.id,
    currentBuyerGuideId,
    userProfile,
    currentScenarioId,
    payload,
  ]);

  const handleThresholdTypeChange = (e) => {
    setThresholdType(e.target.value);
  };

  const handlePrimaryMetricChange = (e) => {
    setPrimaryMetric(e.target.value);
  };

  const getUpdateRepostSetting = async () => {
    const response = await apiService(
      "https://newtestfuncpython.azurewebsites.net/api/updateReportSettings?",
      {
        method: "POST",
        data: {
          ...payload,
          reportSettingsTab: {
            shortlistThresholdType: {
              maxNumberOfVendors: thresholdType === "maximumNumberOfVendors",
              vendorsToBeShortlisted:
                thresholdType === "maximumNumberOfVendors" ? numVendors : 0,
              offersPerVendorsToBeShortlisted:
                thresholdType === "maximumNumberOfVendors"
                  ? numOffersPerVendor
                  : 0,
              maxNumberOfOffers: thresholdType !== "maximumNumberOfVendors",
              offersToBeShortlisted:
                thresholdType !== "maximumNumberOfVendors" ? numOffers : 0,
            },
            primaryMetricForShortlisting: {
              offerScore: primaryMetric === "offerScore",
              offerRelevance: primaryMetric !== "offerScore",
            },
            biasSettings: {
              vendorBias: bias.vendorBias,
              selectedVendor: selectedVendor.value,
              offerBias: bias.offerBias,
              selectedOffer: selectedOffer.value,
            },
          },
        },
      }
    );
    if (response) {
      setThresholdType(
        thresholdType === "maximumNumberOfVendors"
          ? "maximumNumberOfVendors"
          : "maximumNumberOfOffers"
      );
      setNumVendors(
        (thresholdType === "maximumNumberOfVendors" ? numVendors : 0) || 1
      );
      setNumOffers(
        (thresholdType !== "maximumNumberOfVendors" ? numOffers : 0) || 1
      );
      setNumOffersPerVendor(
        (thresholdType === "maximumNumberOfVendors" ? numOffersPerVendor : 0) ||
          1
      );
    }
    return response;
  };

  return isLoading ? (
    // <Box
    //   height="80vh"
    //   width="100%"
    //   justifyContent="center"
    //   alignItems="center"
    //   display="flex"
    // >
    //   <CircularProgress />
    // </Box>
    <Loader open={isLoading} />
  ) : (
    <Box sx={{ height: "100%" }}>
      <HeaderNaming data={data} />
      <Box p={0} sx={{ height: "70%" }}>
        <Grid container spacing={3} direction={isMobile ? "column" : "row"}>
          <Grid item xs={12} sm={5}>
            <FormControl component="fieldset">
              <Typography
                data-test-id="name-Shortlist Threshold Type"
                variant="h3"
              >
                Shortlist Threshold Type
              </Typography>
              <RadioGroup
                value={thresholdType}
                onChange={handleThresholdTypeChange}
              >
                <FormControlLabel
                  data-test-id="name-Maximum Number of Vendors"
                  value="maximumNumberOfVendors"
                  control={
                    <CustomRadio data-test-id="radio-Maximum Number of Vendors" />
                  }
                  label="Maximum Number of Vendors"
                />
                {thresholdType === "maximumNumberOfVendors" && (
                  <Box mt={2} ml={3}>
                    <TextField
                      data-test-id="text-Number of vendors to be shortlisted"
                      label="Number of vendors to be shortlisted"
                      type="number"
                      value={numVendors}
                      onChange={(e) => setNumVendors(e.target.value)}
                      fullWidth
                      margin="normal"
                    />
                    <TextField
                      data-test-id="text-Maximum Number of offers per vendor to be shortlisted"
                      label="Maximum Number of offers per vendor to be shortlisted"
                      type="number"
                      value={numOffersPerVendor}
                      onChange={(e) => setNumOffersPerVendor(e.target.value)}
                      fullWidth
                      margin="normal"
                    />
                  </Box>
                )}
                <FormControlLabel
                  data-test-id="name-Maximum Number of Offers"
                  value="maximumNumberOfOffers"
                  control={
                    <CustomRadio data-test-id="radio-Maximum Number of Offers" />
                  }
                  label="Maximum Number of Offers"
                />
                {thresholdType === "maximumNumberOfOffers" && (
                  <Box mt={2} ml={3}>
                    <TextField
                      data-test-id="text-Number of offers to be shortlisted"
                      label="Number of offers to be shortlisted"
                      type="number"
                      value={numOffers}
                      onChange={(e) => setNumOffers(e.target.value)}
                      fullWidth
                      margin="normal"
                    />
                  </Box>
                )}
              </RadioGroup>
            </FormControl>
          </Grid>
          <Divider
            orientation={isMobile ? "horizontal" : "vertical"}
            flexItem
            variant="middle"
            sx={{ my: 2, mx: isMobile ? 0 : 2 }}
          />
          <Grid
            item
            xs={12}
            sm={6}
            sx={{ display: "flex", flexDirection: "column", gap: "50px 0px" }}
          >
            <FormControl component="fieldset">
              <Typography
                data-test-id="name-Primary Metric for Shortlisting"
                variant="h3"
              >
                Primary Metric for Shortlisting
              </Typography>
              <RadioGroup
                value={primaryMetric}
                onChange={handlePrimaryMetricChange}
              >
                <FormControlLabel
                  data-test-id="name-Offer Score"
                  value="offerScore"
                  sx={{ display: "flex", width: "fit-content" }}
                  control={<CustomRadio data-test-id="radio-Offer Score" />}
                  label="Offer Score"
                />
                <FormControlLabel
                  data-test-id="name-Offer Relevance"
                  value="offerRelevance"
                  sx={{ display: "flex", width: "fit-content" }}
                  control={<CustomRadio data-test-id="radio-Offer Relevance" />}
                  label="Offer Relevance"
                />
              </RadioGroup>
            </FormControl>

            {/* --------------------------------------------- */}
            { prefix!=="/demo"&&
            <FormControl component="fieldset">
              <Typography data-test-id="name-Bias Settings" variant="h3">
                Bias Settings
              </Typography>

              <FormControlLabel
                data-test-id="name-Vendor Bias"
                value="vendorBias"
                sx={{ display: "flex", width: "fit-content" }}
                control={
                  <CustomCheckbox
                    onChange={handleBiasChange}
                    data-test-id="checkbox-Vendor Bias"
                    checked={bias.vendorBias}
                  />
                }
                label="Vendor Bias"
              />

              {bias.vendorBias && (
                <FormControl sx={{ maxWidth: "350px", width: "100%" }}>
                  <Select
                    labelId="vendorBias-label"
                    id="bias-vendorBias-dropDown"
                    value={selectedVendor.id}
                    name="vendorBias"
                    sx={{ fontFamily: "inherit" }}
                    onChange={handleVendorChange}
                    displayEmpty
                    inputProps={{ "aria-label": "Without label" }}
                  >
                    <MenuItem value="" disabled>
                      Select Vendor
                    </MenuItem>
                    {biasData.map((vendor) => (
                      <MenuItem key={vendor.vendorId} value={vendor.vendorId}>
                        {vendor.vendorName}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              )}

              {bias.vendorBias && selectedVendor.id && (
                <>
                  <FormControlLabel
                    data-test-id="name-Offer Bias"
                    value="offerBias"
                    sx={{ display: "flex", width: "fit-content" }}
                    control={
                      <CustomCheckbox
                        onChange={handleBiasChange}
                        data-test-id="radio-Offer Bias"
                        checked={bias.offerBias}
                      />
                    }
                    label="Offer Bias"
                  />

                  {bias.offerBias && (
                    <FormControl sx={{ maxWidth: "350px", width: "100%" }}>
                      <Select
                        labelId="offerBias-label"
                        id="bias-offerBias-dropDown"
                        value={selectedOffer.id}
                        sx={{ fontFamily: "inherit" }}
                        name="offerBias"
                        onChange={handleOfferChange}
                        displayEmpty
                        inputProps={{ "aria-label": "Without label" }}
                      >
                        <MenuItem value="" disabled>
                          Select Offer
                        </MenuItem>
                        {offers.map((offer) => (
                          <MenuItem key={offer.offerId} value={offer.offerId}>
                            {offer.offerName}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  )}
                </>
              )}
            </FormControl>
            }
          </Grid>
        </Grid>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: 2,
          mt: 4,
          ml: 2,
        }}
      >
        <SaveAndExit
          saveAndContinue={getUpdateRepostSetting}
          data={{
            thresholdType,
            numVendors,
            numOffersPerVendor,
            numOffers,
            primaryMetric,
          }}
          setIsLoading={setIsLoading}
          prefix={prefix}
        />
      </Box>
      </Box>
      <Snackbar
        open={notification.open}
        autoHideDuration={6000}
        onClose={handleCloseNotification}
      >
        <Alert
          onClose={handleCloseNotification}
          severity={notification.severity}
        >
          {notification.message}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default ReportOption;
