import React, { createContext, useEffect, useState } from "react";

export const AllocationContext = createContext();

export const AllocationProvider = ({ children }) => {
  const [loading, setLoading] = useState(false);
  const [sectionData, setSectionData] = useState([]);

  const [selectedRowData, setSelectedRowData] = useState(""); //to check which buyergide packagek package
  const [popoverContent, setPopoverContent] = useState({});
  const [allocatedData, setAllocatedData] = useState([]); // data that is going to be send as data in Allocation API
  const [vendorPopup, setVendorPopup] = useState(false); //state for popup for asking to allocate vendor
  const [vendorList, setVendorList] = useState([]); //list of vendor got from API call
  const [vendorTab, setVendorTab] = useState(false); // state for showing vendor tab if user response in positive
  const [selectedBG, setselectedBG] = useState({}); //seelcted buyer guide ID
  const [selectedVendors, setSelectedVendors] = useState([]);
  const [newUser, setNewUser] = useState([]);

  useEffect(() => {
    console.log("Allocated Data Updated:", newUser);
  }, [newUser]);

  const value = {
    loading,
    setLoading,
    sectionData,
    setSectionData,
    popoverContent,
    setPopoverContent,
    allocatedData,
    setAllocatedData,
    vendorPopup,
    setVendorPopup,
    vendorList,
    setVendorList,
    vendorTab,
    selectedRowData,
    setSelectedRowData,
    setVendorTab,
    selectedBG,
    setselectedBG,
    selectedVendors,
    setSelectedVendors,
    newUser,
    setNewUser,
  };

  return <AllocationContext.Provider value={value}>{children}</AllocationContext.Provider>;
};
