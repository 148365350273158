export function getPlaceholder(params) {
  const { row, field } = params;
  const { allocationType } = row;

  if (allocationType === "UnPrescribed") {
    if (field === "totalUnits" || field === "userUnitsTotal") {
      return "#";
    } else if (field === "endUserOrganizationUnitsTotal" || field === "buyerGuideUnitsTotal") {
      return "(Optional)";
    }
  } else if (allocationType === "Fully Prescribed") {
    return "#";
  } else if (allocationType === "Partially Prescribed (UCBG)") {
    if (field === "endUserOrganizationUnitsTotal") {
      return "(Optional)";
    } else {
      return "#";
    }
  } else if (allocationType === "Partially Prescribed (EUO)") {
    if (field === "buyerGuideUnitsTotal") {
      return "(Optional)";
    } else {
      return "#";
    }
  }

  return "#";
}

export function calculateOptionList(params) {
  const { row, field } = params;
  const { allocationType, totalUnits, totalUnitsInput, userUnitsInput, endUserOrganizationUnitsInput, buyerGuideUnitsInput } = row;

  let options = [];
  let maxValue = null;

  if (allocationType === "UnPrescribed") {
    if (field === "totalUnits") {
      options = Array.from({ length: totalUnits }, (_, index) => index + 1);
    } else if (field === "userUnitsTotal" && totalUnitsInput) {
      options = Array.from({ length: totalUnitsInput }, (_, index) => index + 1).filter((number) => totalUnitsInput % number === 0);
    } else if (field === "endUserOrganizationUnitsTotal" && userUnitsInput) {
      options = Array.from({ length: totalUnitsInput }, (_, index) => index + 1)?.filter((number) => (totalUnitsInput / userUnitsInput) % number === 0);
    } else if (field === "buyerGuideUnitsTotal" && userUnitsInput) {
      if (totalUnitsInput && userUnitsInput) {
        const isEndUserOrgUnitsValid = !!endUserOrganizationUnitsInput;
        const denominator = isEndUserOrgUnitsValid ? userUnitsInput * endUserOrganizationUnitsInput : userUnitsInput;
        options = Array.from({ length: totalUnitsInput }, (_, index) => index + 1)?.filter((number) => (totalUnitsInput / denominator) % number === 0);
      }
    }
  } else if (allocationType === "Fully Prescribed") {
    if (field === "endUserOrganizationUnitsTotal") {
      options = Array.from({ length: row.endUserOrganizationUnitsTotal }, (_, index) => index + 1);
    } else if (field === "buyerGuideUnitsTotal") {
      options = Array.from({ length: row.buyerGuideUnitsTotal }, (_, index) => index + 1);
    } else if (field === "totalUnits" && endUserOrganizationUnitsInput && buyerGuideUnitsInput) {
      const product = endUserOrganizationUnitsInput * buyerGuideUnitsInput;
      const count = Math.floor(totalUnits / product);
      options = Array.from({ length: count }, (_, i) => product * (i + 1));
    } else if (field === "userUnitsTotal" && totalUnitsInput && buyerGuideUnitsInput && endUserOrganizationUnitsInput) {
      const product = endUserOrganizationUnitsInput * buyerGuideUnitsInput;
      const quotient = totalUnitsInput / product;
      options = Array.from({ length: quotient }, (_, i) => i + 1).filter((i) => quotient % i === 0);
    }
  } else if (allocationType === "Partially Prescribed (UCBG)") {
    if (field === "buyerGuideUnitsTotal") {
      options = Array.from({ length: row.buyerGuideUnitsTotal }, (_, index) => index + 1);
    } else if (field === "totalUnits" && buyerGuideUnitsInput) {
      options = Array.from({ length: Math.floor(totalUnits / buyerGuideUnitsInput) }, (_, index) => (index + 1) * buyerGuideUnitsInput);
    } else if (field === "userUnitsTotal" && totalUnitsInput && buyerGuideUnitsInput) {
      const quotient = totalUnitsInput / buyerGuideUnitsInput;
      options = Array.from({ length: Math.floor(Math.sqrt(quotient)) }, (_, i) => i + 1)
        .filter((i) => quotient % i === 0)
        .flatMap((i) => (i !== quotient / i ? [i, quotient / i] : [i]))
        .sort((a, b) => a - b);
    } else if (field === "endUserOrganizationUnitsTotal" && userUnitsInput && buyerGuideUnitsInput) {
      const quotient = totalUnitsInput / (buyerGuideUnitsInput * userUnitsInput);
      options = Array.from({ length: Math.floor(Math.sqrt(quotient)) }, (_, i) => i + 1)
        .filter((i) => quotient % i === 0)
        .flatMap((i) => (i !== quotient / i ? [i, quotient / i] : [i]));
    }
  } else if (allocationType === "Partially Prescribed (EUO)") {
    if (field === "endUserOrganizationUnitsTotal") {
      options = Array.from({ length: row.endUserOrganizationUnitsTotal }, (_, index) => index + 1);
    } else if (field === "totalUnits" && endUserOrganizationUnitsInput) {
      options = Array.from({ length: Math.floor(totalUnits / endUserOrganizationUnitsInput) }, (_, index) => (index + 1) * endUserOrganizationUnitsInput);
    } else if (field === "userUnitsTotal" && totalUnitsInput && endUserOrganizationUnitsInput) {
      const quotient = totalUnitsInput / endUserOrganizationUnitsInput;
      options = Array.from({ length: Math.floor(Math.sqrt(quotient)) }, (_, i) => i + 1)
        .filter((i) => quotient % i === 0)
        .flatMap((i) => (i !== quotient / i ? [i, quotient / i] : [i]))
        .sort((a, b) => a - b);
    } else if (field === "buyerGuideUnitsTotal" && userUnitsInput && endUserOrganizationUnitsInput) {
      const quotient = totalUnitsInput / (endUserOrganizationUnitsInput * userUnitsInput);
      options = Array.from({ length: Math.floor(Math.sqrt(quotient)) }, (_, i) => i + 1)
        .filter((i) => quotient % i === 0)
        .flatMap((i) => (i !== quotient / i ? [i, quotient / i] : [i]));
    }
  }

  maxValue = options.length > 0 ? Math.max(...options) : null;

  return { options, maxValue };
}
