import React, { useState, useEffect, useContext, useRef, useMemo } from "react";
import ListToggleWidget from "../VendorRelevantOffer";
import { UserProfileContext } from "../../../context/UserContext";

import NoDataAvilable from "../../../components/NoDataAvilable";
import { apiService } from "../../../utils/utils";
import { ComparativeTableMap } from "../../Utils/helper";
import { Box, Divider, Alert, Typography } from "@mui/material";
import { ComparitiveContext } from "../../../context/ComparitiveContext";
import Loader from "../../../components/Loader";

// Main component for displaying different types of offers
export default function Card({
  widgetLabels, // Label for the widget (e.g., Premium, Smart, etc.)
  widgetKey, // Unique key for the widget
  setLayout, // Function to set the layout (potentially for parent component)
  isViewReport,
  generateReport,
  setCommentary,
  table,
}) {
  const [loading, setLoading] = useState(false); // Loading state for fetching data
  const [error, setError] = useState(null); // Error state for handling API errors
  const [, setCommentryLoading] = useState(true);
  const [, setCommentryData] = useState("");
  const [contentKey, setContentKey] = useState(0);
  // Context for user profile and data
  const staticData =
    "### Summary of Top 5 Unused Features ('Not Required' Use Cases)\r\n\r\nThe widget titled \"Top 5 Unused Features ('Not Required' Use Cases)\" identifies the five least utilized features within a given application, specifically highlighting areas where these features are deemed unnecessary by users. The data represents an analysis of feature usage within the Zscaler Private Access platform. Each feature listed contributes equally to the total share of unused features, with a contribution score of 11.11%.\r\n\r\n#### Detailed Breakdown:\r\n\r\n1. **Secure Workforce**\r\n   - **Feature Contribution Score:** 11.11%\r\n   - **Lineage:** Zscaler Private Access > Secure Workforce\r\n\r\n2. **Secure Hybrid Workforce**\r\n   - **Feature Contribution Score:** 11.11%\r\n   - **Lineage:** Zscaler Private Access > Secure Workforce > Secure Hybrid Workforce\r\n\r\n3. **Branch & Remote Office Connectivity**\r\n   - **Feature Contribution Score:** 11.11%\r\n   - **Lineage:** Zscaler Private Access > Branch & Remote Office Connectivity\r\n\r\n4. **App Segmentation**\r\n   - **Feature Contribution Score:** 11.11%\r\n   - **Lineage:** Zscaler Private Access > App Segmentation\r\n\r\n5. **Digital Experience Monitoring**\r\n   - **Feature Contribution Score:** 11.11%\r\n   - **Lineage:** Zscaler Private Access > Monitoring & Optimization > Digital Experience Monitoring\r\n\r\nEach feature’s lineage indicates its hierarchical placement within the broader Zscaler Private Access system, providing context on its primary function and relationship to other functionalities within the platform. By identifying these unused features, stakeholders can gain insights into possible areas for optimization, user education, or feature redesign, aligning product offerings more closely with user needs and preferences.";
  const { currentOrg, buyerGuideName, scenarioName, currentOrganizationId, currentPackage, userProfile, currentBuyerGuideId, currentScenarioId } = useContext(UserProfileContext);
  const {
    fetchRelevantVendors,
    fetchRelevantOffers,
    relevantVendorsData,
    relevantOffersData,
    relevantVendorDataForSummary,
    relevantOfferDataForSummary,
    smartData,
    lowData,
    premiumData,
    budgetData,
    fetchPremiumData,
    fetchBudgetData,
    fetchLowData,
    fetchSmartData,
  } = useContext(ComparitiveContext);
  // URLs for different offer types
  const urls = {
    Premimum: fetchPremiumData,
    Budget: fetchBudgetData,
    Low: fetchLowData,
    Smart: fetchSmartData,
    "Relevant Vendors": fetchRelevantVendors,
    "Relevant Offers": fetchRelevantOffers,
  };

  const dataMap = {
    Smart: smartData,
    Premimum: premiumData,
    Budget: budgetData,
    Low: lowData,
    "Relevant Vendors": relevantVendorsData,
    "Relevant Offers": relevantOffersData,
  };
  const summaryDataMap = useMemo(() => {
    return {
      "Relevant Vendors": relevantVendorDataForSummary,
      "Relevant Offers": relevantOfferDataForSummary,
    };
  }, [relevantOfferDataForSummary, relevantVendorDataForSummary]);
  // Determine the appropriate URL and data handler based on widgetLabels
  const selectedUrl = urls[widgetLabels] || null;
  const selectedData = dataMap[widgetLabels];
  const mainCard = useRef(null);

  useEffect(() => {
    let isMounted = true;

    const fetchData = async () => {
      if (selectedUrl) {
        setLoading(true);
        setError(null);
        try {
          await selectedUrl();
          if (isMounted) {
            setContentKey((prev) => prev + 1);
          }
        } catch (err) {
          if (isMounted) {
            setError(err);
          }
        } finally {
          if (isMounted) {
            setLoading(false);
          }
        }
      }
    };

    fetchData();

    return () => {
      isMounted = false;
    };
  }, [widgetKey, setLayout, widgetLabels, selectedUrl]);

  useEffect(() => {
    if (!isViewReport) {
      // Update layout only if the component is still mounted
      const updateLayout = () => {
        setLayout((prev) => {
          const newLayout = prev.map((item) => {
            if (item.i !== widgetKey) {
              return item;
            } else {
              const newItem = {
                ...item,
                h: Math.max(1, Math.ceil((mainCard?.current?.scrollHeight ?? 0) / 70)),
              };

              return newItem;
            }
          });
          return newLayout;
        });
      };

      const rafId = requestAnimationFrame(updateLayout);
      return () => {
        // Clean up on unmount - cancel the requestAnimationFrame if it is still pending
        cancelAnimationFrame(rafId);
      };
    } else {
      return;
    }
  }, [mainCard?.current?.clientHeight, mainCard, setLayout, widgetKey, isViewReport]);

  useEffect(() => {
    if (isViewReport) {
      const reportData = JSON.parse(localStorage.getItem(`${currentOrg}-${buyerGuideName}-${scenarioName}-ComparativeSummary-${table}`));

      const fetchData = async (data) => {
        const payload = {
          email: userProfile?.email,
          currentOrganisation: currentOrganizationId,
          currentBuyerGuide: currentBuyerGuideId,
          currentPackage: currentPackage.id,
          currentScenario: currentScenarioId,
          reportComparativeTable: ComparativeTableMap[table],
          comparativeTableJson: data,
        };
        if (table && data) {
          try {
            const endPoint = generateReport ? "generateComparativeSummary" : "getComparativeSummary";
            const response = await apiService(
              `https://newtestfuncpython.azurewebsites.net/api/${endPoint}?`,
              {
                method: "POST",
                data: payload,
              },
              setCommentryLoading,
              setCommentryData,
              setError
            );

            setCommentary((prev) => {
              return {
                ...prev,
                ComparativeSummary: {
                  ...prev?.ComparativeSummary,
                  [table]: {
                    ...prev?.ComparativeSummary[table],
                    commentary: response?.comparativeSummary,
                    generateCount: response.maxGenerationLimit - response?.currentGenerationCount,
                    tableData: data,
                    isLoading: false,
                  },
                },
              };
            });

            localStorage.setItem(`${currentOrg}-${buyerGuideName}-${scenarioName}-ComparativeSummary-${table}`, JSON.stringify(response));
          } catch (error) {
            console.error("Failed to fetch data:", error);
            setError(error);
            setCommentary((prev) => {
              return {
                ...prev,
                ComparativeSummary: {
                  ...prev?.ComparativeSummary,
                  [table]: {
                    ...prev?.ComparativeSummary[table],
                    commentary: staticData,
                    tableData: data,
                    isLoading: false,
                  },
                },
              };
            });
            localStorage.setItem(
              `${currentOrg}-${buyerGuideName}-${scenarioName}-ComparativeSummary-${table}`,
              JSON.stringify({
                comparativeSummary: staticData,
                maxGenerationLimit: 3,
                currentGenerationCount: 1,
              })
            );
          }
        }
      };

      if (table && !reportData?.comparativeSummary) {
        fetchData(summaryDataMap[widgetLabels]);
      } else if (table) {
        setCommentary((prev) => {
          return {
            ...prev,
            ComparativeSummary: {
              ...prev?.ComparativeSummary,
              [table]: {
                ...prev?.ComparativeSummary[table],
                commentary: reportData?.comparativeSummary,
                generateCount: reportData.maxGenerationLimit - reportData?.currentGenerationCount,
                tableData: summaryDataMap[widgetLabels],
                isLoading: false,
              },
            },
          };
        });
      }
    }
  }, [
    table,
    setCommentary,
    currentOrg,
    scenarioName,
    isViewReport,
    buyerGuideName,
    currentBuyerGuideId,
    currentOrganizationId,
    currentPackage,
    currentScenarioId,
    userProfile,
    generateReport,
    summaryDataMap,
    widgetLabels,
  ]);
  // Error state UI
  if (error) {
    return (
      <Box height="250px" width="100%" justifyContent="center" alignItems="center" overflow="hidden" display="flex">
        <Alert severity="error">
          Error fetching data of {widgetLabels}: {error.message}
        </Alert>
      </Box>
    );
  }

  // Main render
  return loading ? (
    <Loader open={loading} />
  ) : selectedData?.items?.length > 0 ? (
    <Box data-test-id={widgetKey} key={contentKey}>
      {widgetLabels ? (
        <Box
          ref={mainCard}
          className="widget-header-container"
          sx={{
            display: "flex",
            flexDirection: "column", // Stack header and content vertically
            height: "100%", // Fill the available space
            overflow: "hidden", // Prevent overflow
          }}
        >
          {/* Divider */}
          <Divider sx={{ margin: 1, width: "94%" }} />

          {/* Widget Content */}
          <Box
            sx={{
              width: "100%",
              height: "100%",
              paddingX: "10px",
            }}
          >
            <ListToggleWidget name={widgetLabels} items={selectedData?.items} count={selectedData?.count} />
          </Box>
        </Box>
      ) : (
        <NoDataAvilable ref={mainCard} />
      )}
    </Box>
  ) : (
    <NoDataAvilable
      ref={mainCard}
      message={
        <Typography variant="h6" sx={{ position: "absolute", top: 2, left: 5 }}>
          Count: {selectedData?.count}
        </Typography>
      }
    />
  );
}
