import React, { createContext ,useContext, useState, useCallback, useEffect} from "react";
import { UserProfileContext } from "./UserContext";
import { apiService } from "../utils/utils";
const ComparitiveContext = createContext();
const ComparitiveContextProvider=({ children })=> {
   const {
    currentOrg,
    userProfile,
    currentBuyerGuideId,
    currentScenarioId,
    currentPackage,
    scenarioName
  } = useContext(UserProfileContext);
  const [smartData, setSmartData] = useState({
    count: 0,
    items: [],
    otherData: {},
  });
  const [lowData, setLowData] = useState({
    count: 0,
    items: [],
    otherData: {},
  });
  const [budgetData, setBudgetData] = useState({
    count: 0,
    items: [],
    otherData: {},
  });
  const [premiumData, setPremiumData] = useState({
    count: 0,
    items: [],
    otherData: {},
  });
  const [relevantVendorsData, setRelevantVendorsData] = useState({
      count: 0,
      items: [],
      otherData: {},
    });
  const [relevantOffersData, setRelevantOffersData] = useState({
      count: 0,
      items: [],
      otherData: {},
    });
  const [relevantVendorDataForSummary,setrelevantVendorDataForSummary] = useState(null);
  const [relevantOfferDataForSummary,setrelevantOfferDataForSummary] = useState(null)
  const [initialData, setInitialData] = useState([]);
  
  const [, setError] = useState(null);

  
  useEffect(() => {
      if (localStorage.getItem("initialData")) {
        setInitialData(JSON.parse(localStorage.getItem("initialData")));
      }
    }, [currentOrg, scenarioName]);
  const fetchSmartData = useCallback(async () => {
    if (userProfile && currentOrg && currentBuyerGuideId && currentScenarioId) {
      const payload = {
        email: userProfile?.email,
        currentOrganisation: currentOrg,
        currentBuyerGuide: currentBuyerGuideId,
        currentScenario: currentScenarioId,
        currentPackage: currentPackage?.id,
      };

      try {
        
        const response = await apiService("https://newtestfuncpython.azurewebsites.net/api/smartValyouOfferCountAndNames?", {
          method: "POST",
          data: payload,
        });

        if (response) {
          setSmartData({
            count: response.SmartOfferCount,
            items: response.SmartOfferDetails.map((offer) => offer.SmartOfferNames),
            otherData: response?.SmartOfferDetails?.reduce((obj, item) => {
              obj[item?.SmartOfferNames] = item;
              return obj;
            }, {}),
          });
        }
      } catch (err) {
        console.error("Error fetching data:", err);
        setError(err);
      }
    }
  }, [userProfile, currentOrg, currentBuyerGuideId, currentScenarioId, currentPackage?.id]);

  // Use effect for low data
  const fetchLowData = useCallback(async () => {
    if (userProfile && currentOrg && currentBuyerGuideId && currentScenarioId) {
      const payload = {
        email: userProfile?.email,
        currentOrganisation: currentOrg,
        currentBuyerGuide: currentBuyerGuideId,
        currentScenario: currentScenarioId,
        currentPackage: currentPackage?.id,
      };

      try {
        
        const response = await apiService("https://newtestfuncpython.azurewebsites.net/api/lowValyouOfferCountAndNames?", {
          method: "POST",
          data: payload,
        });

        if (response) {
          setLowData({
            count: response.LowOfferCount,
            items: response?.LowOfferDetails?.map((offer) => offer.LowOfferNames),
            otherData: response?.LowOfferDetails?.reduce((obj, item) => {
              obj[item?.LowOfferNames] = item;
              return obj;
            }, {}),
          });
          
        }
      } catch (err) {
        console.error("Error fetching data:", err);
        setError(err);
      } 
    }
  }, [userProfile, currentOrg, currentBuyerGuideId, currentScenarioId, currentPackage?.id]);


  const fetchBudgetData = useCallback(async () => {
    if (userProfile && currentOrg && currentBuyerGuideId && currentScenarioId) {
      const payload = {
        email: userProfile?.email,
        currentOrganisation: currentOrg,
        currentBuyerGuide: currentBuyerGuideId,
        currentScenario: currentScenarioId,
        currentPackage: currentPackage?.id,
      };

      try {
        const response = await apiService("https://newtestfuncpython.azurewebsites.net/api/budgetValyouOfferCountAndNames?", {
          method: "POST",
          data: payload,
        });

        if (response) {
          setBudgetData({
            count: response.BudgetOfferCount,
            items: response.BudgetOfferDetails.map((offer) => offer.BudgetOfferNames),
            otherData: response?.BudgetOfferDetails?.reduce((obj, item) => {
              obj[item?.BudgetOfferNames] = item;
              return obj;
            }, {}),
          });
        }
      } catch (err) {
        console.error("Error fetching data:", err);
        setError(err);
      } 
    }
  }, [userProfile, currentOrg, currentBuyerGuideId, currentScenarioId, currentPackage?.id]);
  // Use effect for premium data
  const fetchPremiumData = useCallback(async () => {
    if (userProfile && currentOrg && currentBuyerGuideId && currentScenarioId) {
      const payload = {
        email: userProfile?.email,
        currentOrganisation: currentOrg,
        currentBuyerGuide: currentBuyerGuideId,
        currentScenario: currentScenarioId,
        currentPackage: currentPackage?.id,
      };

      try {
        const response = await apiService("https://newtestfuncpython.azurewebsites.net/api/premiumValyouOfferCountAndNames?", {
          method: "POST",
          data: payload,
        });

        if (response) {
          setPremiumData({
            count: response?.PremiumOfferCount,
            items: response.PremiumOfferDetails?.map((offer) => offer?.PremiumOfferNames),
            otherData: response?.PremiumOfferDetails?.reduce((obj, item) => {
              obj[item?.PremiumOfferNames] = item;
              return obj;
            }, {}),
          });

        }
      } catch (err) {
        console.error("Error fetching data:", err);
        setError(err);
      } 
    }
  }, [userProfile, currentOrg, currentBuyerGuideId, currentScenarioId, currentPackage?.id]);
  const fetchRelevantVendors = useCallback(async () => {
      if (userProfile && currentOrg && currentBuyerGuideId && currentScenarioId) {
        const payload = {
          email: userProfile?.email,
          currentOrganisation: currentOrg,
          currentBuyerGuide: currentBuyerGuideId,
          currentScenario: currentScenarioId,
          currentPackage: currentPackage?.id,
          // displayUseCasesOptions: "Both",
        };
  
        try {
          const response = await apiService("https://newtestfuncpython.azurewebsites.net/api/relevantVendorsCountAndNames?", {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            data: payload,
          });
  
          if (response) {
            setrelevantVendorDataForSummary(response)
            setRelevantVendorsData({
              count: response?.VendorCount,
              items: response?.VendorDetails?.map((offer) => offer?.VendorName) || [],
            });
          }
        } catch (err) {
          console.error("Failed to fetch vendors:", err);
          setError(err);
        }
      }
    }, [userProfile, currentOrg, currentBuyerGuideId, currentScenarioId, currentPackage?.id]);
  const fetchRelevantOffers = useCallback(async () => {
      if (userProfile && currentOrg && currentBuyerGuideId && currentScenarioId) {
        const payload = {
          email: userProfile?.email,
          currentOrganisation: currentOrg,
          currentBuyerGuide: currentBuyerGuideId,
          currentScenario: currentScenarioId,
          currentPackage: currentPackage?.id,
          // displayUseCasesOptions: "Both",
        };
        try {
          const response = await apiService("https://newtestfuncpython.azurewebsites.net/api/relevantOfferCountAndNames?", {
            method: "POST",
  
            data: payload,
          });
  
          if (response) {
            setrelevantOfferDataForSummary(response)
            setRelevantOffersData({
              count: response?.OfferCount,
              items: response?.OfferDetails.map((offer) => offer.OfferName) || [],
            });
          }
        } catch (err) {
          console.error("Failed to fetch vendors:", err);
          setError(err);
        }
      }
    }, [userProfile, currentOrg, currentBuyerGuideId, currentScenarioId, currentPackage?.id]);
  return (
   <ComparitiveContext.Provider value={{smartData,
        setSmartData,
        fetchSmartData,
        lowData,
        setLowData,
        fetchLowData,
        budgetData,
        setBudgetData,
        fetchBudgetData,
        premiumData,
        setPremiumData,
        fetchPremiumData,
        setInitialData,
        initialData,
        relevantVendorsData,
        fetchRelevantVendors,
        relevantOffersData,
        fetchRelevantOffers,
        relevantVendorDataForSummary,
        relevantOfferDataForSummary
        }} >
       {children}
   </ComparitiveContext.Provider>
  )
}

export { ComparitiveContext,ComparitiveContextProvider}