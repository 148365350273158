import React, { useContext, useEffect, useRef, useState } from "react";
import { Box, Divider, IconButton } from "@mui/material";
import DataGridTable from "../../../components/DataGridTable";
import { apiService } from "../../../utils/utils";
import { UserProfileContext } from "../../../context/UserContext";
import KeyboardDoubleArrowRightIcon from "@mui/icons-material/KeyboardDoubleArrowRight";
import KeyboardDoubleArrowLeftIcon from "@mui/icons-material/KeyboardDoubleArrowLeft";
import NoDataAvilable from "../../../components/NoDataAvilable";
import theme from "../../../theme/theme";
import { ComparativeTableMap } from "../../Utils/helper";
import Loader from "../../../components/Loader";

// Define columns for the data grid

const UnmetUseCases = ({ widgetKey, setLayout, isViewReport,
  generateReport,setCommentary,table }) => {
  const {
    userProfile,
    // currentOrganizationId,
    currentBuyerGuideId,
    currentScenarioId,
    currentPackage,
    currentOrg,
    buyerGuideName,
    scenarioName,
    currentOrganizationId
  } = useContext(UserProfileContext);
  const [rows, setRows] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [data,setData] =useState([])
  const mainData = useRef(null);
  const [, setCommentryLoading] = useState(true);
  const [, setCommentryData] = useState("");
  const staticData =
      "### Summary of Top 5 Unused Features ('Not Required' Use Cases)\r\n\r\nThe widget titled \"Top 5 Unused Features ('Not Required' Use Cases)\" identifies the five least utilized features within a given application, specifically highlighting areas where these features are deemed unnecessary by users. The data represents an analysis of feature usage within the Zscaler Private Access platform. Each feature listed contributes equally to the total share of unused features, with a contribution score of 11.11%.\r\n\r\n#### Detailed Breakdown:\r\n\r\n1. **Secure Workforce**\r\n   - **Feature Contribution Score:** 11.11%\r\n   - **Lineage:** Zscaler Private Access > Secure Workforce\r\n\r\n2. **Secure Hybrid Workforce**\r\n   - **Feature Contribution Score:** 11.11%\r\n   - **Lineage:** Zscaler Private Access > Secure Workforce > Secure Hybrid Workforce\r\n\r\n3. **Branch & Remote Office Connectivity**\r\n   - **Feature Contribution Score:** 11.11%\r\n   - **Lineage:** Zscaler Private Access > Branch & Remote Office Connectivity\r\n\r\n4. **App Segmentation**\r\n   - **Feature Contribution Score:** 11.11%\r\n   - **Lineage:** Zscaler Private Access > App Segmentation\r\n\r\n5. **Digital Experience Monitoring**\r\n   - **Feature Contribution Score:** 11.11%\r\n   - **Lineage:** Zscaler Private Access > Monitoring & Optimization > Digital Experience Monitoring\r\n\r\nEach feature’s lineage indicates its hierarchical placement within the broader Zscaler Private Access system, providing context on its primary function and relationship to other functionalities within the platform. By identifying these unused features, stakeholders can gain insights into possible areas for optimization, user education, or feature redesign, aligning product offerings more closely with user needs and preferences.";
  useEffect(() => {
    let isMounted = true;

    const fetchUnmetUseCases = async () => {
      setLoading(true);
      try {
        const data = await apiService(
          "https://newtestfuncpython.azurewebsites.net/api/top5UnmetUseCasesAcrossAllVendors?",
          {
            method: "POST",
            data: {
              email: userProfile?.email,
              currentOrganisation: currentOrg,
              currentBuyerGuide: currentBuyerGuideId,
              currentScenario: currentScenarioId,
              currentPackage: currentPackage?.id,
            },
          },
          null,
          null,
          setError
        );
        if (isMounted && data.length > 0) {
          // Ensure mainData.current is available
         setData(data)
          const transformedData = transformData(data);
          setRows(transformedData);
        }
      } catch (err) {
        console.error(err);
        if (isMounted) {
          setError(err.message || "Error loading data");
        }
      } finally {
        if (isMounted) {
          setLoading(false);
        }
      }
    };

    fetchUnmetUseCases();

    return () => {
      isMounted = false;
    };
  }, [
    widgetKey,
    setLayout,
    currentBuyerGuideId,
    currentOrg,
    currentScenarioId,
    userProfile?.email,
    currentPackage?.id,
  ]);
  useEffect(() => {
      if (isViewReport&&data.length>0) {
        const reportData = JSON.parse(
          localStorage.getItem(
            `${currentOrg}-${buyerGuideName}-${scenarioName}-ComparativeSummary-${table}`
          )
        );
        
        const fetchData = async (data) => {
          const payload = {
            email: userProfile?.email,
            currentOrganisation: currentOrganizationId,
            currentBuyerGuide: currentBuyerGuideId,
            currentPackage: currentPackage.id,
            currentScenario: currentScenarioId,
            reportComparativeTable: ComparativeTableMap[table],
            comparativeTableJson: data,
          };
          if (table && data) {
            try {
              const endPoint = generateReport?"generateComparativeSummary":"getComparativeSummary";
              const response = await apiService(
                `https://newtestfuncpython.azurewebsites.net/api/${endPoint}?`,
                {
                  method: "POST",
                  data: payload,
                },
                setCommentryLoading,
                setCommentryData,
                setError
              );

              setCommentary((prev) => {
                return {
                  ...prev,
                  ComparativeSummary:{
                    ...prev?.ComparativeSummary,
                    [table]:{
                        ...prev?.ComparativeSummary[table],
                        commentary:response?.comparativeSummary,
                        generateCount:response.maxGenerationLimit - response?.currentGenerationCount,
                        tableData:data,
                        isLoading:false
                    }
                  }

              }
              });

              localStorage.setItem(
                `${currentOrg}-${buyerGuideName}-${scenarioName}-ComparativeSummary-${table}`,
                JSON.stringify(response)
              );
            } catch (error) {
              console.error("Failed to fetch data:", error);
              setError(error);
              setCommentary((prev) => {
                return {
                  ...prev,
                  ComparativeSummary:{
                    ...prev?.ComparativeSummary,
                    [table]:{
                        ...prev?.ComparativeSummary[table],
                        commentary:staticData,
                        tableData:data,
                        isLoading:false
                    }
                  }

              }
              })
              localStorage.setItem(
                `${currentOrg}-${buyerGuideName}-${scenarioName}-ComparativeSummary-${table}`,
                JSON.stringify({
                  comparativeSummary: staticData,
                  maxGenerationLimit: 3,
                  currentGenerationCount: 1,
                })
              );
            }
          }
        };

        if (table && data.length>0 && !reportData?.comparativeSummary) {
            if(data.length>0){
            fetchData(data);
            }
            else{
              return
            }
        } else if (table && data) {
         setCommentary((prev) => {
                return {
                  ...prev,
                  ComparativeSummary:{
                    ...prev?.ComparativeSummary,
                    [table]:{
                        ...prev?.ComparativeSummary[table],
                        commentary:reportData?.comparativeSummary,
                        generateCount:reportData.maxGenerationLimit - reportData?.currentGenerationCount,
                        tableData:data,
                        isLoading:false
                    }
                  }
              }
              });
        } else {
          return;
        }
      }else{
        return
      }
    }, [
      table,
      setCommentary,
      data,
      currentOrg,
      scenarioName,
      isViewReport,
      buyerGuideName,
      currentBuyerGuideId,
      currentOrganizationId,
      currentPackage.id,
      currentScenarioId,
      userProfile,
      generateReport
    ]);
  useEffect(() => {
    if(!isViewReport){
    const updateLayout = () => {
      if (mainData.current) {
        setLayout((prev) => {
          const newLayout = prev.map((item) => {
            if (item.i !== widgetKey) {
              return item;
            } else {
              const newItem = {
                ...item,
                h: (mainData?.current?.clientHeight ?? 0) / 70, // Update based on the data length
              };
              return newItem;
            }
          });
          return newLayout;
        });
      }
    };

    const rafId = requestAnimationFrame(updateLayout);
    return () => {
      // Clean up on unmount - cancel the requestAnimationFrame if it is still pending
      cancelAnimationFrame(rafId);
    };
    }else{
        return 
    }
  }, [mainData?.current?.clientHeight, widgetKey, setLayout,isViewReport]);

  if (error) {
    return <Box>Error loading data: {error}</Box>;
  }

  return loading ? (
    <Box
      ref={mainData}
      height="250px"
      width="100%"
      justifyContent="center"
      alignItems="center"
      overflow="hidden"
      display="flex"
    >
      {/* <CircularProgress /> */}
      <Loader open={loading} />
    </Box>
  ) : rows.length > 0 ? (
    <Box
      ref={mainData}
      className="widget-header-container"
      sx={{
        justifyContent: "center",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        // width: "100%",
        // height: "100%", // Fill the available space
        // overflow: "auto", // Prevent overflow
      }}
    >
      <Divider sx={{ marginY: 1, width: "95%" }} />

      <Box
        data-test-id="chartTable"
        className="thinner-scrollbar"
        sx={{ height: "100%", width: "100%" }}
      >
        <DataGridTable
          name={widgetKey}
          rows={rows}
          columns={columns}
          disableSelectionOnClick
          hideFooter
        />
      </Box>
    </Box>
  ) : (
    <NoDataAvilable />
  );
};

export default UnmetUseCases;

const ShowPath = (params) => {
  const [showCell, setShowCell] = useState(false);
  const { value } = params;
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "flex-start",
        alignItems: "center",
        height: "100%",
        width: "100%",
        overflowY: "hidden",
        gap: "0px 10px",
      }}
    >
      <Box>
        <IconButton
          onClick={() => {
            setShowCell(!showCell);
          }}
        >
          {showCell ? <KeyboardDoubleArrowLeftIcon /> : <KeyboardDoubleArrowRightIcon />}
        </IconButton>
      </Box>
      <Box>
        {showCell
          ? value.map((item, index) => (
              <span key={index}>
                {item}
                {index < value.length - 1 && (
                  <span
                    style={{
                      color: theme.palette.primary.main,
                      fontWeight: "bold",
                      fontSize: "large",
                    }}
                  >
                    {" "}
                    &gt;{" "}
                  </span>
                )}
              </span>
            ))
          : value[value.length - 1]}
      </Box>
    </Box>
  );
};

const transformData = (data) => {
  return data.map((item, index) => {
    return {
      id: index + 1,
      useCase: item.PathNames,
      importanceScore: item.Importance.toFixed(1),
      mandatory: item.MandatoryFlag,
    };
  });
};

const columns = [
  {
    field: "useCase",
    headerName: "Use Case",
    headerClassName: "super-app-theme--header",
    renderCell: ShowPath,
    minWidth: 250,
    flex: 1,
  },
  {
    field: "importanceScore",
    headerName: "Relative Importance",
    headerClassName: "super-app-theme--header",
    renderCell: (params) => (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100%",
          width: "100%",
        }}
      >
        {params.value}
      </Box>
    ),
    minWidth: 250,
    type: "number",
  },
  {
    field: "mandatory",
    headerName: "Mandatory Status",
    headerClassName: "super-app-theme--header",
    renderCell: (params) => (
      <Box
        sx={{
          display: "flex",
          justifyContent: "flex-start",
          alignItems: "center",
          height: "100%",
          width: "100%",
        }}
      >
        {params.value}
      </Box>
    ),
    minWidth: 250,
  },
];
