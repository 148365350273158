import React, { useState, useEffect, useContext } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
  Typography,
  Grid,
  Button,
  Checkbox,
  useTheme,
  Box,
  ThemeProvider,
  Stack,
  Dialog,
  DialogContent,
  DialogActions,
  TextField,
  Snackbar,
} from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { UserProfileContext } from "../context/UserContext";

const ReviewVendorOfferPage = () => {
  const [tableData, setTableData] = useState([]);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [selectAllAccepted, setSelectAllAccepted] = useState(false);
  const [selectAllRejected, setSelectAllRejected] = useState(false);
  const [allCasesActedUpon, setAllCasesActedUpon] = useState(false);
  const navigate = useNavigate();

  const location = useLocation();
  const apiData = location.state?.data;
  const { userProfile, prefix } = useContext(UserProfileContext);

  // Remove "N/A" from acceptedBy and rejectedBy during the initial data setting
  useEffect(() => {
    if (apiData && apiData.TableData) {
      setTableData(
        apiData.TableData.map((data, index) => ({
          id: index + 1,
          ...data,
          subFeatureName: data?.subFeatureName || "N/A",
          subFeatureDescription: data?.subFeatureDescription || "N/A",
          subFeatureSource: data?.subFeatureSource || "N/A",
          hasSubFeature: data?.hasSubFeature ? "Yes" : "No",
          acceptedBy: Array.isArray(data?.acceptedBy)
            ? data.acceptedBy.filter((email) => email !== "N/A")
            : [],
          rejectedBy: Array.isArray(data?.rejectedBy)
            ? data.rejectedBy.filter((email) => email !== "N/A")
            : [],
        }))
      );
    }
  }, [apiData]);

  // Update the selectAll states and activeness states based on the tableData
  useEffect(() => {
    setSelectAllAccepted(
      tableData.every((row) => row.acceptedBy.includes(userProfile.email))
    );
    setSelectAllRejected(
      tableData.every((row) => row.rejectedBy.includes(userProfile.email))
    );
    const allActedUpon = tableData.every(
      (row) =>
        row.acceptedBy.includes(userProfile.email) ||
        row.rejectedBy.includes(userProfile.email)
    );
    setAllCasesActedUpon(allActedUpon);
  }, [tableData, userProfile.email]);

  const columns = [
    {
      field: "accept",
      headerName: "Accept",
      flex: 0.5,
      minWidth: 150,
      renderHeader: () => (
        <Box display="flex" alignItems="center">
          <Checkbox
            checked={selectAllAccepted}
            indeterminate={
              tableData.some((row) =>
                row.acceptedBy.includes(userProfile.email)
              ) && !selectAllAccepted
            }
            onChange={toggleAcceptAll}
            color="secondary"
          />
          <Typography variant="body2" style={{ marginLeft: 8, color: "white" }}>
            Accept
          </Typography>
        </Box>
      ),
      renderCell: (params) => (
        <Checkbox
          checked={params.row.acceptedBy.includes(userProfile.email)}
          onChange={() => handleAccept(params.id)}
          color="secondary"
        />
      ),
    },
    {
      field: "reject",
      headerName: "Reject",
      flex: 0.5,
      minWidth: 150,
      renderHeader: () => (
        <Box display="flex" alignItems="center">
          <Checkbox
            checked={selectAllRejected}
            indeterminate={
              tableData.some((row) =>
                row.rejectedBy.includes(userProfile.email)
              ) && !selectAllRejected
            }
            onChange={toggleRejectAll}
            color="secondary"
          />
          <Typography variant="body2" style={{ marginLeft: 8, color: "white" }}>
            Reject
          </Typography>
        </Box>
      ),
      renderCell: (params) => (
        <Checkbox
          checked={params.row.rejectedBy.includes(userProfile.email)}
          onChange={() => handleReject(params.id)}
          color="secondary"
        />
      ),
    },
    {
      field: "subFeatureName",
      headerName: "Feature Name",
      flex: 1,
      minWidth: 200,
    },
    {
      field: "subFeatureDescription",
      headerName: "Description (Optional)",
      flex: 2,
      minWidth: 300,
    },
    { field: "subFeatureSource", headerName: "Source", flex: 1, minWidth: 180 },
    {
      field: "hasSubFeature",
      headerName: "Has Sub Features?",
      flex: 1,
      minWidth: 180,
    },
    { field: "acceptedBy", headerName: "Accepted By", flex: 1, minWidth: 180 },
    { field: "rejectedBy", headerName: "Rejected By", flex: 1, minWidth: 180 },
  ];

  const handleDialogOpen = () => setDialogOpen(true);
  const handleDialogClose = () => setDialogOpen(false);
  const theme = useTheme();

  // Handle the acceptance of a feature
  const handleAccept = (id) => {
    const updatedData = tableData.map((row) =>
      row.id === id
        ? {
            ...row,
            acceptedBy: [...new Set([...row.acceptedBy, userProfile.email])], // Add email if not present
            rejectedBy: row.rejectedBy.filter(
              (email) => email !== userProfile.email
            ), // Remove email from rejectedBy
          }
        : row
    );
    setTableData(updatedData);
  };

  // Handle the rejection of a feature
  const handleReject = (id) => {
    const updatedData = tableData.map((row) =>
      row.id === id
        ? {
            ...row,
            rejectedBy: [...new Set([...row.rejectedBy, userProfile.email])], // Add email if not present
            acceptedBy: row.acceptedBy.filter(
              (email) => email !== userProfile.email
            ), // Remove email from acceptedBy
          }
        : row
    );
    setTableData(updatedData);
  };

  const toggleAcceptAll = () => {
    const newStatus = !selectAllAccepted;
    const updatedData = tableData.map((row) => ({
      ...row,
      acceptedBy: newStatus
        ? [...new Set([...row.acceptedBy, userProfile.email])]
        : row.acceptedBy.filter((email) => email !== userProfile.email),
      rejectedBy: row.rejectedBy.filter((email) => email !== userProfile.email),
    }));
    setTableData(updatedData);
    setSelectAllAccepted(newStatus);
  };

  const toggleRejectAll = () => {
    const newStatus = !selectAllRejected;
    const updatedData = tableData.map((row) => ({
      ...row,
      rejectedBy: newStatus
        ? [...new Set([...row.rejectedBy, userProfile.email])]
        : row.rejectedBy.filter((email) => email !== userProfile.email),
      acceptedBy: row.acceptedBy.filter((email) => email !== userProfile.email),
    }));
    setTableData(updatedData);
    setSelectAllRejected(newStatus);
  };

  const handleSaveNewSubFeature = () => {
    const newId = tableData.length + 1;
    const newSubFeature = {
      id: newId,
      subFeatureId: null,
      subFeatureName: name,
      subFeatureDescription: description || "N/A",
      hasSubFeature: "No",
      acceptedBy: [userProfile.email],
      rejectedBy: [],
      subFeatureSource: `Added by ${userProfile.email}`,
    };
    setTableData([...tableData, newSubFeature]);
    handleDialogClose();
  };

  const handleSaveAndContinue = async () => {
    const payload = {
      currentFeatureId: apiData?.Header?.currentFeatureId,
      features: tableData.map((row) => ({
        featureId: row.subFeatureId,
        featureName: row.subFeatureName,
        description: row.subFeatureDescription,
        // Ensure `acceptedBy` and `rejectedBy` are flat arrays
        acceptedBy: Array.isArray(row.acceptedBy) ? row.acceptedBy.flat() : [],
        rejectedBy: Array.isArray(row.rejectedBy) ? row.rejectedBy.flat() : [],
        hasSubFeature: row.hasSubFeature === "Yes",
        source: row.subFeatureSource,
      })),
    };

    try {
      const saveResponse = await fetch(
        "https://newtestfuncpython.azurewebsites.net/api/saveAndContinueForOfferForAnalyst",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(payload),
        }
      );

      if (!saveResponse.ok) throw new Error("Failed to save data");

      setSuccessMessage("Data saved successfully!");

      await new Promise((resolve) => setTimeout(resolve, 2000)); // Optional: 2-second delay

      const storedOfferId = localStorage.getItem("selectedOfferId");
      const analysisResponse = await fetch(
        "https://newtestfuncpython.azurewebsites.net/api/getExistingOfferFeatures",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            email: userProfile.email,
            currentOfferId: storedOfferId,
          }),
        }
      );

      if (!analysisResponse.ok)
        throw new Error("Failed to fetch existing offer details");

      const analysisData = await analysisResponse.json();

      navigate(`${prefix}/analysis`, {
        state: { buyerGuideData: analysisData, fromVendorOfferMenu: true },
      });
    } catch (error) {
      console.error("Error in saving or fetching data:", error);
    }
  };

  return (
    <ThemeProvider theme={theme}>
      <Snackbar
        open={!!successMessage}
        autoHideDuration={6000}
        onClose={() => setSuccessMessage("")}
        message={successMessage}
      />

      <Grid container spacing={2} style={{ marginBottom: "25px" }}>
        <Grid item xs={12}>
          <Typography
            variant="h3"
            component="div"
            style={{
              display: "grid",
              gridTemplateColumns: "350px 16px auto",
              alignItems: "flex-start",
            }}
          >
            <Box component="span">Selected Feature</Box>
            <Box component="span" style={{ textAlign: "center" }}>
              :
            </Box>
            <Box component="span" style={{ fontWeight: "normal" }}>
              {apiData?.Header?.currentFeatureName}
            </Box>
          </Typography>
          <Typography
            variant="h3"
            component="div"
            style={{
              display: "grid",
              gridTemplateColumns: "350px 16px auto",
              alignItems: "flex-start",
            }}
          >
            <Box component="span">Path to Selected Feature</Box>
            <Box component="span" style={{ textAlign: "center" }}>
              :
            </Box>
            <Box component="span" style={{ fontWeight: "normal" }}>
              {apiData?.Header.pathNodes
                ? apiData?.Header.pathNodes.join(" --> ")
                : apiData?.Header?.currentFeatureName}
            </Box>
          </Typography>
          <Typography
            variant="h3"
            component="div"
            style={{
              display: "grid",
              gridTemplateColumns: "350px 16px auto",
              alignItems: "flex-start",
            }}
          >
            <Box component="span">Sibling Features</Box>
            <Box component="span" style={{ textAlign: "center" }}>
              :
            </Box>
            <Box component="span" style={{ fontWeight: "normal" }}>
              {apiData?.Header.siblingFeatureNames?.join(", ")}
            </Box>
          </Typography>
        </Grid>
      </Grid>

      <Box sx={{ height: "100%", width: "100%" }}>
        <DataGrid
          // rows={tableData}
          rows={tableData}
          columns={columns}
          pageSize={5}
          hideFooter
          rowsPerPageOptions={[5]}
          checkboxSelection={false}
          disableRowSelectionOnClick
          autoHeight
          sx={{
            width: "100%",
            height: "100%",
            "& .super-app-theme--header": {
              backgroundColor: theme.palette.primary.main,
              color: "white",
              fontSize: "14px",
              ".MuiSvgIcon-root": {
                color: "white",
              },
            },
            "& .MuiDataGrid-columnHeader, .MuiDataGrid-columnHeaders": {
              backgroundColor: theme.palette.primary.main,
              color: "white",
            },
            "& .MuiDataGrid-columnHeaderTitle": {
              fontWeight: "normal",
            },
            "&.MuiDataGrid-root .MuiDataGrid-cell:focus-within": {
              outline: "none !important",
            },
          }}
        />
        <Stack sx={{ display: "flex", justifyContent: "flex-end", mt: 2 }}>
          {allCasesActedUpon && (
            <Button
              variant="contained"
              color="primary"
              sx={{ width: "250px", marginTop: "20px" }}
              onClick={handleSaveAndContinue}
            >
              Save & Continue
            </Button>
          )}
          <Button
            variant="contained"
            color="secondary"
            sx={{ width: "250px", marginTop: "20px" }}
            onClick={handleDialogOpen}
          >
            Add Sub-Feature
          </Button>
        </Stack>
      </Box>

      <Dialog open={dialogOpen} onClose={handleDialogClose}>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            id="name"
            label="Sub-Feature Name"
            type="text"
            fullWidth
            variant="outlined"
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
          <TextField
            margin="dense"
            id="description"
            label="Description"
            type="text"
            fullWidth
            variant="outlined"
            value={description}
            onChange={(e) => setDescription(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button variant="outlined" onClick={handleDialogClose} sx={{ mr: 2 }}>
            Cancel
          </Button>
          <Button
            variant="contained"
            onClick={handleSaveNewSubFeature}
            color="primary"
          >
            Create Sub-Feature
          </Button>
        </DialogActions>
      </Dialog>
    </ThemeProvider>
  );
};

export default ReviewVendorOfferPage;
