import { Position } from "@xyflow/react";

export default function useTreeMapData(data) {
  const initialnodes = [];
  const initialedges = [];
  // Helper function to recursively traverse the tree
  const traverseTree = (node, parentId = null, depth = 0, index = 0) => {
    if (!node || (typeof node.id === "undefined" && typeof node.parentId === "undefined")) {
      console.error("Invalid node detected:", node);
      return;
    }

    const nodeId = (node.id ?? node.parentId).toString(); // Use `id` if available; fallback to `parentId` for root nodes

    // Add node
    initialnodes.push({
      id: nodeId,
      type: parentId ? "childNode" : "rootNode", // Root or child node
      position: {
        x: depth * 300,
        y: index * 100,
      },
      data: {
        label: node.name,
        description: node.description,
        parentId: parentId ? parentId : nodeId,
      },
      hidden: false,
      targetPosition: Position.Top,
      sourcePosition: Position.Bottom,
    });

    // Add edge if there's a parent
    if (parentId) {
      initialedges.push({
        id: `edge-${parentId}-${nodeId}`,
        source: parentId,
        target: nodeId,
        animated: true,
        type: "default",
        label: "type can displayed here", // Edge label
      });
    }

    // Recursively process children
    if (Array.isArray(node.childrens) && node.childrens.length > 0) {
      node.childrens.forEach((child, childIndex) => {
        traverseTree(child, nodeId, depth + 1, childIndex);
      });
    } else if (node.childrens && !Array.isArray(node.childrens)) {
      console.error("Invalid children format for node:", node);
    }
  };

  // Process all root nodes
  if (Array.isArray(data)) {
    data.forEach((rootNode, rootIndex) => {
      traverseTree(rootNode, null, 0, rootIndex);
    });
  } else {
    console.error("Invalid data format. Expected an array.", data);
  }

  return { initialnodes, initialedges };
}
