import React, { createContext ,useContext, useState, useCallback, useEffect} from "react";
import { UserProfileContext } from "./UserContext";
import { apiService } from "../utils/utils";
// Create the context
const StaticReportContext = createContext();

// Create a provider component
const StaticReportProvider = ({ children }) => {

  const {
    currentOrg,
    userProfile,
    currentBuyerGuideId,
    currentScenarioId,
    currentPackage,
    scenarioName,
  } = useContext(UserProfileContext);
const [smartLoading, setSmartLoading] = useState(true);
const [budgetLoading, setBudgetLoading] = useState(true);
const [lowLoading, setLowLoading] = useState(true);
const [premiumLoading, setPremiumLoading] = useState(true);
const [valyouMatrixData, setValyouMatrixData] = useState(null);
const [initialDataForStatic, setInitialDataForStatic] = useState([]);
const [, setError] = useState(null);
const [smartDataForStaticData, setSmartDataForStaticPage] = useState({
    count: 0,
    items: [],
    otherData: {},
  });
  const [lowDataForStaticData, setLowDataForStaticPage] = useState({
    count: 0,
    items: [],
    otherData: {},
  });
  const [budgetDataForStaticData, setBudgetDataForStaticPage] = useState({
    count: 0,
    items: [],
    otherData: {},
  });
  const [premiumDataForStaticData, setPremiumDataForStaticPage] = useState({
    count: 0,
    items: [],
    otherData: {},
  });
  useEffect(() => {
   
    if (localStorage?.getItem("smartData")) {
      setSmartDataForStaticPage(JSON.parse(localStorage?.getItem("smartData")));
      setSmartLoading(false);
    }
    if (localStorage?.getItem("budgetData")) {
      setBudgetDataForStaticPage(JSON.parse(localStorage?.getItem("budgetData")));
      setBudgetLoading(false);
    }
    if (localStorage?.getItem("lowData")) {
      setLowDataForStaticPage(JSON.parse(localStorage?.getItem("lowData")));
      setLowLoading(false);
    }
    if (localStorage?.getItem("premiumData")) {
      setPremiumDataForStaticPage(JSON.parse(localStorage?.getItem("premiumData")));
      setPremiumLoading(false);
    }
    if (localStorage.getItem("valyouMatrixData")) {
      setValyouMatrixData(JSON.parse(localStorage.getItem("valyouMatrixData")));
    }
    if (localStorage.getItem("initialDataStatic")) {
      setInitialDataForStatic(JSON.parse(localStorage.getItem("initialDataStatic")));
    }
  }, [ scenarioName]);

  const fetchSmartDataForStaticReport = useCallback(async () => {
    if (userProfile && currentOrg && currentBuyerGuideId && currentScenarioId) {
      const payload = {
        email: userProfile?.email,
        currentOrganisation: currentOrg,
        currentBuyerGuide: currentBuyerGuideId,
        currentScenario: currentScenarioId,
        currentPackage: currentPackage?.id,
      };

      try {
        setSmartLoading(true);
        let response = await apiService(
          "https://newtestfuncpython.azurewebsites.net/api/smartValyouOfferCountAndNames?",
          {
            method: "POST",
            data: payload,
          }
        );

        if (response) {

        const count = response?.SmartOfferDetails.reduce((accumulator, item) => {
              
              if(item?.SmartOfferNames!=="Unlock Vendor"){
                   return accumulator++ ;
              }
              return accumulator;
            }, 0);

        response = {
             ...response,
             SmartOfferDetails:response?.SmartOfferDetails.filter((offer)=>{
                    return offer?.SmartOfferNames!=="Unlock Vendor"
             })
          }
           
        const data = {
            count:count,
            items: response.SmartOfferDetails.map((offer) => offer.SmartOfferNames),
            otherData: response?.SmartOfferDetails?.reduce((obj, item) => {
              obj[item?.SmartOfferNames] = item;
              return obj;
            }, {}),
          };
          localStorage.setItem("smartData", JSON.stringify(data));
          setSmartDataForStaticPage(data);
        }
      } catch (err) {
        console.error("Error fetching data:", err);
        setError(err);
      } finally {
        setSmartLoading(false);
      }
    }
  }, [userProfile, currentOrg, currentBuyerGuideId, currentScenarioId, currentPackage?.id]);
  const fetchLowDataForStaticReport = useCallback(async () => {
    if (userProfile && currentOrg && currentBuyerGuideId && currentScenarioId) {
      const payload = {
        email: userProfile?.email,
        currentOrganisation: currentOrg,
        currentBuyerGuide: currentBuyerGuideId,
        currentScenario: currentScenarioId,
        currentPackage: currentPackage?.id,
      };

      try {
        setLowLoading(true); // Start loading
        let response = await apiService(
          "https://newtestfuncpython.azurewebsites.net/api/lowValyouOfferCountAndNames?",
          {
            method: "POST",
            data: payload,
          }
        );

        if (response) {
        const count = response?.LowOfferDetails.reduce((accumulator, item) => {
              
              if(item?.LowOfferNames!=="Unlock Vendor"){
                   return accumulator++ ;
              }
              return accumulator;
            }, 0);

        response = {
             ...response,
             LowOfferDetails:response?.LowOfferDetails.filter((offer)=>{
                    return offer?.LowOfferNames!=="Unlock Vendor"
             })
          }
          const data = {
            count: count,
            items: response?.LowOfferDetails?.map((offer) => offer.LowOfferNames),
            otherData: response?.LowOfferDetails?.reduce((obj, item) => {
              obj[item?.LowOfferNames] = item;
              return obj;
            }, {}),
          };
          localStorage.setItem("lowData", JSON.stringify(data));
          setLowDataForStaticPage(data);
        }
      } catch (err) {
        console.error("Error fetching data:", err);
        setError(err);
      } finally {
        setLowLoading(false); // End loading
      }
    }
  }, [userProfile, currentOrg, currentBuyerGuideId, currentScenarioId, currentPackage?.id]);
  const fetchBudgetDataForStaticReport = useCallback(async () => {
    if (userProfile && currentOrg && currentBuyerGuideId && currentScenarioId) {
      const payload = {
        email: userProfile?.email,
        currentOrganisation: currentOrg,
        currentBuyerGuide: currentBuyerGuideId,
        currentScenario: currentScenarioId,
        currentPackage: currentPackage?.id,
      };

      try {
        setBudgetLoading(true); // Start loading
        let response = await apiService(
          "https://newtestfuncpython.azurewebsites.net/api/budgetValyouOfferCountAndNames?",
          {
            method: "POST",
            data: payload,
          }
        );

        if (response) {
        const count = response?.BudgetOfferDetails.reduce((accumulator, item) => {
              
              if(item?.BudgetOfferNames!=="Unlock Vendor"){
                   return accumulator++ ;
              }
              return accumulator;
            }, 0);

          response = {
             ...response,
             BudgetOfferDetails:response?.BudgetOfferDetails.filter((offer)=>{
                    return offer?.BudgetOfferNames!=="Unlock Vendor"
             })
          }
          const data = {
            count: count,
            items: response.BudgetOfferDetails.map((offer) => offer.BudgetOfferNames),
            otherData: response?.BudgetOfferDetails?.reduce((obj, item) => {
              obj[item?.BudgetOfferNames] = item;
              return obj;
            }, {}),
          };
          localStorage.setItem("budgetData", JSON.stringify(data));
          setBudgetDataForStaticPage(data);
        }
      } catch (err) {
        console.error("Error fetching data:", err);
        setError(err);
      } finally {
        setBudgetLoading(false); // End loading
      }
    }
  }, [userProfile, currentOrg, currentBuyerGuideId, currentScenarioId, currentPackage?.id]);
  const fetchPremiumDataForStaticReport = useCallback(async () => {
    if (userProfile && currentOrg && currentBuyerGuideId && currentScenarioId) {
      const payload = {
        email: userProfile?.email,
        currentOrganisation: currentOrg,
        currentBuyerGuide: currentBuyerGuideId,
        currentScenario: currentScenarioId,
        currentPackage: currentPackage?.id,
      };

      try {
        setPremiumLoading(true); // Start loading
        let response = await apiService(
          "https://newtestfuncpython.azurewebsites.net/api/premiumValyouOfferCountAndNames?",
          {
            method: "POST",
            data: payload,
          }
        );

        if (response) {
        const count = response?.PremiumOfferDetails.reduce((accumulator, item) => {
              
              if(item?.PremiumOfferNames!=="Unlock Vendor"){
                   return accumulator++ ;
              }
              return accumulator;
            }, 0);

        response = {
             ...response,
             PremiumOfferDetails:response?.PremiumOfferDetails.filter((offer)=>{
                    return offer?.PremiumOfferNames!=="Unlock Vendor"
             })
          }
          const data = {
            count: count,
            items: response.PremiumOfferDetails?.map((offer) => offer?.PremiumOfferNames),
            otherData: response?.PremiumOfferDetails?.reduce((obj, item) => {
              obj[item?.PremiumOfferNames] = item;
              return obj;
            }, {}),
          };
          localStorage.setItem("premiumData", JSON.stringify(data));
          setPremiumDataForStaticPage(data);
        }
      } catch (err) {
        console.error("Error fetching data:", err);
        setError(err);
      } finally {
        setPremiumLoading(false); // End loading
      }
    }
  }, [userProfile, currentOrg, currentBuyerGuideId, currentScenarioId, currentPackage?.id]);
  return (
    <StaticReportContext.Provider
      value={{
        fetchSmartDataForStaticReport,
        smartDataForStaticData,
        smartLoading,
        fetchBudgetDataForStaticReport,
        budgetDataForStaticData,
        budgetLoading,
        fetchPremiumDataForStaticReport,
        premiumDataForStaticData,
        premiumLoading,
        fetchLowDataForStaticReport,
        lowDataForStaticData,
        lowLoading,
        setValyouMatrixData,
        valyouMatrixData,
        setInitialDataForStatic,
        initialDataForStatic,
      }}
    >
      {children}
    </StaticReportContext.Provider>
  );
};

export { StaticReportContext, StaticReportProvider };
