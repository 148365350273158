import React, { useState, useRef, useMemo, useCallback, useEffect, Suspense } from "react";
import RGL, { WidthProvider } from "react-grid-layout";
import {
  TextField,
  Autocomplete,
  Box,
  Checkbox,
  useMediaQuery,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@mui/material"; // MUI components
import "../../GridLayout/GridComponent.css";

// Importing widget configurations
import { widgetComponents, widgetOptions } from "../../GridLayout/helper";
import LabelCopyViewClose from "../../../components/LabelCopyViewClose";
import { ExpandMore } from "@mui/icons-material";

const ReactGridLayout = WidthProvider(RGL); // Using WidthProvider for responsive grid layout

/**
 * @function ComparativeAnalysisWidgets
 * @description Component to render a grid layout of widgets with dynamic content. Allows users to add, remove, and rearrange widgets.
 * @returns {JSX.Element} The rendered ComparativeAnalysisWidgets component.
 */
function ComparativeAnalysisWidgets() {
  const issmallscreen = useMediaQuery((theme) => theme.breakpoints.down("md")); // Media query to adjust layout for small screens
  const chartRef = useRef(null);

  // State for managing the grid layout
  const [layout, setLayout] = useState(initialLayout);

  // State for table visibility, saved in local storage
  const [isTableVisible, setIsTableVisible] = useState(
    JSON.parse(localStorage.getItem("isTableVisible")) || true
  );

  // State for managing widget visibility, with initial values from localStorage
  const [widgets, setWidgets] = useState(() => {
    const savedWidgets = localStorage.getItem("widgets");
    if (savedWidgets) {
      try {
        return JSON.parse(savedWidgets);
      } catch (e) {
        console.error("Failed to parse widgets from localStorage", e);
      }
    }
    // Default: all widgets are visible
    return widgetOptions.reduce((acc, option) => {
      acc[option.key] = true;
      return acc;
    }, {});
  });

  // Default properties for the grid layout
  // eslint-disable-next-line
  const defaultProps = {
    isDraggable: true, // Widgets are draggable
    isResizable: true, // Widgets are resizable
    items: layout.length, // Number of items in the layout
    rowHeight: 70, // Height of each row in the grid
    onLayoutChange: function () {}, // Placeholder function for layout change handling
  };

  /**
   * @function toggleWidget
   * @description Toggles the visibility of a specific widget.
   * @param {string} widgetKey - The key of the widget to toggle.
   */
  const toggleWidget = (widgetKey) => {
    setWidgets((prevWidgets) => {
      const isActive = !prevWidgets[widgetKey]; // Toggle widget visibility
      const updatedWidgets = { ...prevWidgets, [widgetKey]: isActive };

      // Update layout based on widget visibility
      const newLayout = isActive
        ? [
            ...layout,
            {
              ...initialLayout.find((item) => item.i === widgetKey),
            },
          ]
        : layout.filter((item) => item.i !== widgetKey);

      setLayout(newLayout); // Set new layout
      return updatedWidgets;
    });
  };

  /**
   * @function toggleTableVisibility
   * @description Toggles the visibility of the table within widgets.
   */
  const toggleTableVisibility = () => {
    setIsTableVisible((prev) => !prev); // Toggle the state for table visibility
  };

  /**
   * @function handleRemoveWidget
   * @description Removes a widget from the layout and updates the state.
   * @param {string} widgetKey - The key of the widget to remove.
   */
  const handleRemoveWidget = (widgetKey) => {
    setWidgets((prevWidgets) => {
      const { [widgetKey]: _, ...remainingWidgets } = prevWidgets; // Remove widget key
      return remainingWidgets;
    });

    setLayout((prevLayout) => prevLayout.filter((item) => item.i !== widgetKey)); // Remove from layout
  };

  /**
   * @function handleCopyWidget
   * @description Placeholder function for copying a widget.
   */
  const handleCopyWidget = () => {
    // Placeholder for widget copy functionality
    return;
  };

  /**
   * @function generateWidgets
   * @description Generates the JSX for the widgets based on the current layout.
   * @returns {JSX.Element[]} Array of rendered widget components.
   */
  function generateWidgets() {
    return layout.map((layoutItem) => {
      const WidgetComponent = widgetComponents[layoutItem.i]; // Get the corresponding widget component
      if (!WidgetComponent) {
        console.error(`Component not found for key: ${layoutItem.i}`);
        return null;
      }

      const shortLabels = widgetOptions.find((option) => option.key === layoutItem.i)?.shortname;
      const widgetLabel = widgetOptions.find((option) => option.key === layoutItem.i)?.label; // Get the widget label
      return (
        <Box
          key={layoutItem.i}
          className="grid-item"
          sx={{ borderRadius: "10px", border: "1px solid", overflow: "hidden" }}
          id={layoutItem.i} // Widget ID eg.: best_of_best (see line initila)
        >
          <Box>
            <Box>
              <LabelCopyViewClose
                widgetLabel={widgetLabel}
                widgetKey={layoutItem.i}
                isTableVisible={isTableVisible}
                handleCopyWidget={handleCopyWidget}
                toggleTableVisibility={toggleTableVisibility}
                handleRemoveWidget={handleRemoveWidget}
                shortLabels={shortLabels}
                // heightChange={heightChange}
              />
              <Box
                className="widget-content no-drag"
                sx={{
                  position: "relative",
                  display: "flex",
                  flexDirection: "column",
                  minHeight: "100%",
                  height: "auto",
                  maxHeight: "100%",
                  overflow: "auto",
                }}
              >
                <Suspense
                  fallback={
                    <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
                      Somthing Went wrong
                    </Box>
                  }
                >
                  <WidgetComponent
                    widgetLabels={widgetLabel}
                    widgetKey={layoutItem.i}
                    setLayout={setLayout}
                    // chartRef={chartRef}
                    isTableVisible={isTableVisible}
                  />
                </Suspense>
              </Box>
            </Box>
          </Box>
        </Box>
      );
    });
  }

  /**
   * @function handleLayoutChange
   * @description Updates the layout state when the layout is changed (e.g., widgets are moved or resized).
   * @param {Object[]} updatedLayout - The updated layout after changes.
   */
  const handleLayoutChange = useCallback(
    (updatedLayout) => {
      setLayout(updatedLayout); // Update layout with new positions/sizes
      defaultProps.onLayoutChange(updatedLayout); // Call default layout change handler
    },
    [defaultProps]
  );

  const memoizedWidgets = useMemo(() => {
    return generateWidgets();
    // eslint-disable-next-line
  }, [layout, widgets, isTableVisible]);

  useEffect(() => {
    return () => {
      chartRef.current = null; // Clear chartRef when component unmounts
    };
  }, []);

  return (
    <Box sx={{ display: "flex", gap: "20px 0px", flexDirection: "column", overflow: "hidden" }}>
      {/* Widget selection dropdown */}
      <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
        {issmallscreen ? (
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMore />}
              aria-controls="panel2-content"
              id="panel2-header"
            >
              Add or Remove Widgets
            </AccordionSummary>
            <AccordionDetails>
              <Autocomplete
                multiple
                options={widgetOptions} // List of widgets to add/remove
                getOptionLabel={(option) => option.label}
                value={widgetOptions.filter((option) => widgets[option.key])}
                onChange={(event, newValue) => {
                  const updatedWidgets = widgetOptions.reduce((acc, option) => {
                    acc[option.key] = newValue.some((newOpt) => newOpt.key === option.key);

                    if (acc[option.key] !== widgets[option.key]) toggleWidget(option.key);

                    return acc;
                  }, {});

                  setWidgets(updatedWidgets);
                }}
                renderInput={(params) => (
                  <TextField
                    data-test-id="text-field"
                    {...params}
                    variant="outlined"
                    placeholder="Add/Remove Widgets"
                  />
                )}
                renderOption={(props, option, { selected }) => (
                  <li {...props}>
                    <Checkbox
                      data-test-id={`checkbox-${option.label}`}
                      checked={selected}
                      sx={{ marginRight: 2 }}
                    />
                    {option.label}
                  </li>
                )}
                ChipProps={{
                  sx: {
                    backgroundColor: "#40bab414",
                    color: "#000",
                  },
                }}
                sx={{ width: "100%", textAlign: "center", padding: "10px" }}
              />
            </AccordionDetails>
          </Accordion>
        ) : (
          <Autocomplete
            multiple
            options={widgetOptions} // List of widgets to add/remove
            getOptionLabel={(option) => option.label}
            value={widgetOptions.filter((option) => widgets[option.key])}
            onChange={(event, newValue) => {
              const updatedWidgets = widgetOptions.reduce((acc, option) => {
                acc[option.key] = newValue.some((newOpt) => newOpt.key === option.key);

                if (acc[option.key] !== widgets[option.key]) toggleWidget(option.key);

                return acc;
              }, {});

              setWidgets(updatedWidgets);
            }}
            renderInput={(params) => (
              <TextField
                data-test-id="text-field"
                {...params}
                variant="outlined"
                placeholder="Add/Remove Widgets"
              />
            )}
            renderOption={(props, option, { selected }) => (
              <li {...props}>
                <Checkbox
                  data-test-id={`checkbox-${option.label}`}
                  checked={selected}
                  sx={{ marginRight: 2 }}
                />
                {option.label}
              </li>
            )}
            ChipProps={{
              sx: {
                backgroundColor: "#40bab414",
                color: "#000",
              },
            }}
            sx={{ width: "100%", textAlign: "center", padding: "10px" }}
          />
        )}
      </Box>

      {/* React grid layout */}
      <ReactGridLayout
        layout={layout}
        cols={issmallscreen ? 1 : 12}
        onLayoutChange={handleLayoutChange}
        {...defaultProps}
        draggableCancel=".no-drag"
        draggableHandle=".widget"
        autoSize={true}
      >
        {memoizedWidgets}
      </ReactGridLayout>
    </Box>
  );
}

export default ComparativeAnalysisWidgets;

// Default initial layout for the grid
const initialLayout = [
  { i: "chart_box", x: 0, y: 0, w: 9, h: 0 },
  { i: "best_of_best", x: 0, y: 17, w: 9, h: 0 },
  { i: "coverage_heatmap", x: 0, y: 51, w: 9, h: 0 },
  { i: "unmet_use_cases", x: 0, y: 34, w: 9, h: 0 },
  { i: "smart_card", x: 9, y: 0, w: 3, h: 0 },
  { i: "premium_card", x: 9, y: 8, w: 3, h: 0 },
  { i: "budget_card", x: 9, y: 16, w: 3, h: 0 },
  { i: "low_card", x: 9, y: 24, w: 3, h: 0 },
  { i: "relevant_vendors", x: 9, y: 32, w: 3, h: 0 },
  { i: "relevant_offers", x: 9, y: 40, w: 3, h: 0 },
];
