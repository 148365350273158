import React, { useCallback, useContext, useEffect, useReducer } from "react";
import { Link, useLocation } from "react-router-dom";
import { Home, NavigateNext } from "@mui/icons-material";
import { Box, Breadcrumbs, Typography } from "@mui/material";
import { UserProfileContext } from "../../context/UserContext";
import HoverDropdown from "./HoverDropdown";

const UPDATE_BREADCRUMBS = "UPDATE_BREADCRUMBS";
const RESET_BREADCRUMBS = "RESET_BREADCRUMBS";

const breadcrumbsReducer = (state, action) => {
  switch (action.type) {
    case UPDATE_BREADCRUMBS:
      return action.payload;
    case RESET_BREADCRUMBS:
      return [];
    default:
      return state;
  }
};

const areArraysEqual = (arr1, arr2) => {
  if (arr1.length !== arr2.length) return false;
  return arr1.every((value, index) => value === arr2[index]);
};

export default function CustomBreadcrumbs() {
  const { pathname } = useLocation();
  const {
    finalcrumbs: contextFinalCrumbs,
    setFinalCrumbs: contextSetFinalCrumbs,
  } = useContext(UserProfileContext);
  const [finalcrumbs, dispatch] = useReducer(
    breadcrumbsReducer,
    contextFinalCrumbs
  );

  const baseUrl = pathname.split("/")[1];

  const updateBreadcrumbs = useCallback(
    (path) => {
      let updatedCrumbs = [...finalcrumbs];
      if (
        updatedCrumbs[updatedCrumbs.length - 1] === "select-scenario" &&
        path[0] === "results"
      ) {
        updatedCrumbs = [...updatedCrumbs, "edit-scenario"];
      }
      if (
        updatedCrumbs[updatedCrumbs.length - 1] === "report-settings" &&
        path[path.length - 1] === "comparative-analysis"
      ) {
        updatedCrumbs = updatedCrumbs.filter(
          (crumb) => crumb !== "report-settings"
        );
      }
      path.forEach((newString) => {
        const existingIndex = updatedCrumbs.indexOf(newString);
        if (existingIndex !== -1) {
          updatedCrumbs = updatedCrumbs.slice(0, existingIndex + 1);
        } else {
          updatedCrumbs.push(newString);
        }
      });
      if (!areArraysEqual(updatedCrumbs, finalcrumbs)) {
        dispatch({ type: UPDATE_BREADCRUMBS, payload: updatedCrumbs });
        localStorage.setItem("breadcrumbs", JSON.stringify(updatedCrumbs));
      }
    },
    [finalcrumbs]
  );

  useEffect(() => {
    const path = pathname.split("/").slice(2).filter(Boolean);
    if (path.length === 0) {
      if (finalcrumbs.length > 0) {
        dispatch({ type: RESET_BREADCRUMBS });
        localStorage.setItem("breadcrumbs", JSON.stringify([]));
      }
    } else {
      updateBreadcrumbs(path);
    }
  }, [pathname, updateBreadcrumbs, finalcrumbs]);

  useEffect(() => {
    if (!areArraysEqual(contextFinalCrumbs, finalcrumbs)) {
      contextSetFinalCrumbs(finalcrumbs);
    }
  }, [finalcrumbs, contextFinalCrumbs, contextSetFinalCrumbs]);

  return pathname === `/${baseUrl}` ? null : (
    <Box
      sx={{
        display: "flex",
        justifyContent: "start",
        alignItems: "center",
        padding: "10px",
        overflowY: "auto",
        whiteSpace: "nowrap",
      }}
    >
      <Breadcrumbs
        aria-label="breadcrumb"
        separator={<NavigateNext />}
        maxItems={2}
      >
        <Link
          to={"/"}
          style={{
            display: "flex",
            color: "gray",
            ":hover": { color: "gray" },
            ":visited": { color: "gray" },
            ":active": { color: "gray" },
          }}
        >
          <Home fontSize="small" sx={{ ":hover": { color: "#000" } }} />
        </Link>

        {finalcrumbs?.map((page, index) => {
          return index !== finalcrumbs.length - 1 ? (
            typeof breadcrumbsMap[finalcrumbs[index]] === "object" ? (
              <HoverDropdown
                key={index}
                filterOptions={breadcrumbsMap[finalcrumbs[index]]}
                parentKey={findParentKey(breadcrumbsMap[finalcrumbs[index]])}
              />
            ) : breadcrumbsMap[page] ? (
              <Link className="link" to={`/${baseUrl}/${page}`} key={index}>
                {breadcrumbsMap[page]}
              </Link>
            ) : null
          ) : (
            <Typography key={index} sx={{ fontWeight: "bold" }}>
              {typeof breadcrumbsMap[finalcrumbs[index - 1]] === "object"
                ? breadcrumbsMap[finalcrumbs[index - 1]]?.[page]?.label
                  ? breadcrumbsMap[finalcrumbs[index - 1]]?.[page].label
                  : breadcrumbsMap[finalcrumbs[index - 1]]?.[page]
                : typeof breadcrumbsMap[page] === "object"
                ? breadcrumbsMap[page]?.label
                : typeof breadcrumbsMap[finalcrumbs[index - 2]]?.[
                    finalcrumbs[index - 1]
                  ] === "object"
                ? breadcrumbsMap[finalcrumbs[index - 2]]?.[
                    finalcrumbs[index - 1]
                  ]?.[page]
                : breadcrumbsMap[page]}
            </Typography>
          );
        })}
      </Breadcrumbs>
    </Box>
  );
}

const findParentKey = (searchValue) => {
  for (const [key, value] of Object.entries(breadcrumbsMap)) {
    if (typeof value === "object" && value !== null) {
      if (value === searchValue) {
        return key;
      }
    }
  }
  return null;
};

const breadcrumbsMap = {
  "select-end-user-org": "Select End User",
  "select-buyer-guide": {
    label: "Select Buyer Guide",
    "buyer-guide-of-interest": "Buyer Guide of Interest",
    "available-buyer-guides": "Available Buyer Guide",
  },
  "select-scenario": "Select Scenario",
  results: {
    label: "Results",
    "comparative-analysis": "Comparative Analysis",
    "vendor-details": "Vendor Details",
    "offer-details": "Offer Details",
  },
  "edit-scenario": {
    label: "Edit Scenario",
    "vendor-options": "Vendor Options",
    "update-use-cases": "Update Use cases",
    "scenario-settings": {
      label: "Scenario Settings",
      "bundle-option": "Bundle Options",
      "threshold-option": "Threshold Options",
      "display-option": "Display Options",
      "report-settings": "Report Settings",
    },
  },
  "report-settings": "Report Settings",
  "view-reports": "View Reports",
  "saved-reports": "Saved Reports",
  "available-units-for-allocation": "Available Units for Allocation",
  packages: "Packages",
  "my-subscriptions": "My Subscriptions",
  users: "Users",
};
