import { useContext, useState } from "react";
import { UserProfileContext } from "../../../context/UserContext";

export const useMergeNodes = (nodes, edges, setNodes, setEdges, selectedNodes, setLayouted, setUnderProcess) => {
  const [mergeDialogOpen, setMergeDialogOpen] = useState(false);
  const [mergeDialogTypes, setMergeDialogTypes] = useState([]);
  const [parentSelectionOpen, setParentSelectionOpen] = useState(false);
  const [availableParents, setAvailableParents] = useState([]);
  const [parentSourceId, setParentSourceId] = useState(null);
  const { setNotification } = useContext(UserProfileContext);

  const notifyWarning = (message) => {
    setNotification({
      message,
      severity: "warning",
      open: true,
    });
  };

  const resetNodePosition = (nodeId, originalPosition) => {
    setNodes((prevNodes) =>
      prevNodes.map((node) =>
        node.id === nodeId
          ? {
              ...node,
              position: { ...originalPosition }, // Ensure position is correctly updated
            }
          : node
      )
    );
    setUnderProcess(false);
    setLayouted(false);
  };

  const handleMergeNodes = () => {
    if (selectedNodes.length < 2) {
      notifyWarning("You must select at least two nodes to merge.");
      return;
    }

    const parentNodes = selectedNodes
      .map((n) => {
        const parentEdge = edges.find((edge) => edge.target === n.id);
        return parentEdge
          ? {
              id: parentEdge.source,
              label: nodes.find((node) => node.id === parentEdge.source)?.data.label,
            }
          : null;
      })
      .filter(Boolean);

    const uniqueParents = [...new Set(parentNodes.map((p) => p?.id))];
    if (uniqueParents.length > 1 && !parentSourceId) {
      setAvailableParents(parentNodes);
      setParentSelectionOpen(true);
      notifyWarning("Cannot merge nodes with different parent nodes.");
      selectedNodes.forEach((node) => {
        resetNodePosition(node.id, node.position); // Reset overlapping nodes
      });
      return;
    }

    const selectedParentId = parentSourceId || uniqueParents[0];
    setParentSourceId(selectedParentId);

    const uniqueTypes = [...new Set(selectedNodes.map((node) => node.type))];
    if (uniqueTypes.length > 1) {
      setMergeDialogTypes(uniqueTypes);
    }
    setMergeDialogOpen(true);
  };

  const handleParentSelect = (parentId) => {
    setParentSourceId(parentId);
    setParentSelectionOpen(false);
    const uniqueTypes = [...new Set(selectedNodes.map((node) => node.type))];
    if (uniqueTypes.length > 1) {
      setMergeDialogTypes(uniqueTypes);
    }
    setMergeDialogOpen(true);
  };

  const handleMergeDialogSubmit = ({ label, type }) => {
    const newNodeId = `merged-${Date.now()}`;
    const mergedNode = {
      id: newNodeId,
      type: type !== "" ? type : selectedNodes?.[0]?.type,
      position: {
        x: selectedNodes[0].position.x,
        y: selectedNodes[0].position.y,
      },
      data: {
        label,
        description: selectedNodes.map((n) => n.data.description).join(", "),
      },
    };

    const childEdges = edges.filter((edge) => selectedNodes.some((n) => edge.source === n.id));
    const newChildEdges = childEdges.map((edge) => ({
      id: `edge-${newNodeId}-${edge.target}`,
      source: newNodeId,
      target: edge.target,
      label: "Inherited",
      animated: true,
      type: "default",
    }));

    const newEdges = edges
      .filter((edge) => !selectedNodes.some((n) => edge.source === n.id || edge.target === n.id))
      .concat({
        id: `edge-${parentSourceId}-${newNodeId}`,
        label: "New type can be displayed here",
        source: parentSourceId,
        target: newNodeId,
        animated: true,
        type: "default",
      })
      .concat(newChildEdges);

    setNodes((prevNodes) => prevNodes.filter((n) => !selectedNodes.some((sel) => sel.id === n.id)).concat(mergedNode));
    setEdges(newEdges);
    setParentSourceId(null);
    setMergeDialogOpen(false);
    setLayouted(false);
    setUnderProcess(false);
  };

  const mergeAndReplaceNode = () => {
    const nodeToDelete = selectedNodes?.[1];
    const nodeToKeep = selectedNodes?.[0];
    console.log(nodeToDelete);
    // Update the label and description of nodeToKeep
    setNodes((prevNodes) => {
      return prevNodes
        .map((node) => {
          if (node.id === nodeToKeep.id) {
            return {
              ...node,
              data: {
                label: nodeToKeep.data.label, // Keep the label of nodeToKeep
                description: `${node.data.description},${nodeToDelete.data.description}`, // Merge descriptions
              },
            };
          }
          return node;
        })
        .filter((node) => node.id !== nodeToDelete.id); // Remove nodeToDelete
    });

    // Reassign children of nodeToDelete to nodeToKeep
    setEdges((prevEdges) =>
      prevEdges
        .map((edge) => {
          if (edge.source === nodeToDelete.id) {
            return {
              ...edge,
              source: nodeToKeep.id, // Reassign children to nodeToKeep
              id: `${nodeToKeep.id}-${edge.target}`, // Update edge ID
            };
          }
          return edge;
        })
        .filter(
          (edge) => edge.source !== nodeToDelete.id && edge.target !== nodeToDelete.id // Remove edges related to nodeToDelete
        )
    );

    // Reset selected nodes and layout
    setLayouted(false);
  };

  return {
    mergeDialogOpen,
    mergeDialogTypes,
    parentSelectionOpen,
    availableParents,
    handleMergeNodes,
    handleParentSelect,
    handleMergeDialogSubmit,
    setMergeDialogOpen,
    setParentSelectionOpen,
    resetNodePosition,
    mergeAndReplaceNode,
  };
};
