import { Autocomplete, Box, Checkbox, Chip, TextField, Typography } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { AllocationContext } from "../AllocationContext";
import theme from "../../../theme/theme";

import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import CustomButton from "../../../components/CustomButton";
import { limitAsPerPackage } from "../helper";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

export default function Vendor({ selectedVendors, setSelectedVendors }) {
  const { vendorList, selectedBG, setVendorTab, selectedRowData } = useContext(AllocationContext);
  const [remainingVendor] = useState(limitAsPerPackage[selectedRowData?.purchasedPackageName]);
  const [reservedVendors, setReservedVendors] = useState([]);

  useEffect(() => {
    const transformedVendors =
      selectedRowData?.buyerlist
        ?.filter((obj) => obj.id === selectedBG?.id)
        ?.flatMap((obj) => obj?.vendors || [])
        ?.map((vendor) => ({
          ...vendor,
          vendorFlag: true,
        })) || [];

    setReservedVendors(transformedVendors);
    setSelectedVendors((prevState) => ({
      ...prevState,
      [selectedBG.id]: [...transformedVendors],
    }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedRowData, vendorList, selectedBG]);

  return (
    <Box sx={{ display: "flex", flexDirection: "column", position: "relative", width: "100%", height: "100%", margin: "auto", backgroundColor: "#f5f5f5", borderRadius: 2, boxShadow: 2 }}>
      <Box sx={{ height: "100%", overflowY: "auto", paddingTop: "15px" }}>
        <Box sx={{ padding: 3, paddingTop: 0 }}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "start",
              width: "100%",
              marginBottom: "10px",
              [theme.breakpoints.down("md")]: { flexDirection: "column", alignItems: "flex-start" },
            }}
          >
            <Typography
              variant="h6"
              gutterBottom
              sx={{ textAlign: "left", textOverflow: "ellipsis", overflow: "hidden", whiteSpace: "nowrap", flex: 1, [theme.breakpoints.down("md")]: { width: "100%" } }}
            >
              Please Select Vendors for Below Buyer Guide:
              <Box sx={{ color: theme.palette.primary.main, fontSize: theme.typography.h4.fontSize, whiteSpace: "break-spaces" }}>{selectedBG?.title}</Box>
            </Typography>
            <Typography variant="h6" gutterBottom sx={{ textAlign: "left", whiteSpace: "nowrap" }}>
              <Typography component="span" variant="inherit" style={{ fontWeight: "bold", marginRight: "5px" }}>
                Remaining Units :
              </Typography>
              {remainingVendor - ((reservedVendors?.filter(Boolean)?.length || 0) + (selectedVendors?.[selectedBG.id]?.length || 0))}
            </Typography>
          </Box>
          <Autocomplete
            multiple
            id="checkboxes-tags"
            limitTags={2}
            options={vendorList}
            disableCloseOnSelect
            value={selectedVendors[selectedBG.id] || []}
            noOptionsText={"No Vendor Available"}
            getOptionLabel={(option) => option.title}
            onChange={(e, value) => {
              console.log(value);
              setSelectedVendors((prevState) => ({
                ...prevState,
                [selectedBG.id]: [
                  ...value.map((vendor) => ({
                    ...vendor,
                    vendorFlag: false,
                  })),
                ],
              }));
            }}
            getOptionDisabled={(option) => {
              return selectedVendors?.[selectedBG?.id]?.length === remainingVendor && !selectedVendors?.[selectedBG?.id]?.some((vendor) => vendor.id === option.id);
            }}
            renderTags={(value, getTagProps) =>
              value.map((option, index) => {
                const { key, ...tagProps } = getTagProps({ index });
                return (
                  <Chip
                    key={key}
                    label={option.title}
                    size="large"
                    {...tagProps}
                    sx={{
                      backgroundColor: theme.palette.primary.light,
                      color: "#000",
                      fontSize: "16px",
                      pointerEvents: reservedVendors.includes(option) ? "none" : "auto", // Prevent interaction with disabled chips
                    }}
                  />
                );
              })
            }
            renderOption={(props, option) => {
              const { key, ...optionProps } = props;
              const isReserved = reservedVendors.some((vendor) => vendor.id === option.id);
              const isChecked = selectedVendors[selectedBG.id]?.some((vendor) => vendor.id === option.id) || false;
              return (
                <li
                  key={option.id}
                  {...optionProps}
                  style={isChecked ? { backgroundColor: theme.palette.primary.light, opacity: 0.9 } : null}
                  onClick={(e) => {
                    e.stopPropagation(); // Prevents closing dropdown on selection

                    if (isReserved) return;
                    // Toggle selection on click
                    if (isChecked) {
                      // Remove the vendor if already selected
                      setSelectedVendors((prevState) => ({
                        ...prevState,
                        [selectedBG.id]: prevState[selectedBG.id]?.filter((vendor) => vendor.id !== option.id),
                      }));
                    } else {
                      // Add the vendor if not selected, including the vendorFlag
                      setSelectedVendors((prevState) => ({
                        ...prevState,
                        [selectedBG.id]: [
                          ...(prevState[selectedBG.id] || []),
                          { ...option, vendorFlag: false }, // Add vendorFlag as true (or as needed)
                        ],
                      }));
                    }
                  }}
                >
                  <Checkbox icon={icon} checkedIcon={checkedIcon} style={{ marginRight: 8 }} checked={isChecked || isReserved} />
                  {option.title}
                </li>
              );
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                label={`Select Vendors`}
                placeholder="Search Vendor"
                sx={{
                  "& .MuiInputLabel-root.Mui-focused": {
                    color: "#000",
                  },
                }}
              />
            )}
          />
        </Box>
      </Box>
      <CustomButton
        sx={{ m: 2, width: "120px", backgroundColor: theme.palette.primary.main }}
        text={"Prescribe"}
        variant={"contained"}
        aria-label="vendor-prescribe-button"
        onClick={() => {
          setVendorTab(false);
        }}
      />
    </Box>
  );
}
