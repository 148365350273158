import React, { useContext, useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { styled, alpha } from "@mui/material/styles";
import { Typography, Button, Box } from "@mui/material";
import { apiService } from "../../utils/utils";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import EditIcon from "@mui/icons-material/Edit";
import GradingOutlinedIcon from "@mui/icons-material/GradingOutlined";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { UserProfileContext } from "../../context/UserContext";
const StyledMenu = styled((props) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "right",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "right",
    }}
    {...props}
  />
))(({ theme }) => ({
  "& .MuiPaper-root": {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 180,

    color:
      theme.palette.mode === "light"
        ? "rgb(55, 65, 81)"
        : theme.palette.grey[300],
    boxShadow:
      "rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
    "& .MuiMenu-list": {
      padding: "4px 0",
    },
    "& .MuiMenuItem-root": {
      "& .MuiSvgIcon-root": {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      "&:active": {
        backgroundColor: alpha(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity
        ),
      },
    },
  },
}));

export default function CustomizedMenus({ prefix, isComplete }) {
  const { currentPackage } = useContext(UserProfileContext);
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = useState(null);
  const [, setIsDeleting] = useState(false);
  const [, setDeleteMsg] = useState("");
  const finalizeButtonStatus = useRef(
    JSON.parse(localStorage.getItem("currentScenario"))?.status
  );
  //const finalizeButtonStatus= useRef("Completed");
  const currentScenarioId = useRef(
    JSON.parse(localStorage.getItem("currentScenario"))?.id
  );
  const editScenario = async (id) => {
    await apiService(
      "https://newtestfuncpython.azurewebsites.net/api/editScenario?",
      {
        method: "POST",
        data: {
          currentScenario: id,
        },
      },
      setIsDeleting,
      setDeleteMsg,
      (error) => {
        // Handle error if needed
        console.error("Failed to create/update users:", error);
      }
    ); // Trigger refresh
  };

  const editCurrentScenario = () => {
    if (
      currentPackage?.package_type !== "Solo Starter" ||
      currentPackage?.package_type
    ) {
      navigate(`${prefix}/edit-scenario/update-use-cases`);
      if (finalizeButtonStatus.current === "Yet To Start") {
        editScenario(currentScenarioId.current);
      }
    }
  };
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event?.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Box sx={{ width: "15%" }}>
      <Button
        sx={{
          borderRadius: "0px 6px 6px 0px",
          borderWidth: "0px 0px 0px 1px",
          borderStyle: "solid",
          padding: "0px -5px",
          width: "100%",
          minWidth: "0px",
        }}
        id="demo-customized-button"
        aria-controls={open ? "demo-customized-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        variant="contained"
        color="primary"
        disableRipple
        disableElevation
        onClick={handleClick}
      >
        <KeyboardArrowDownIcon />
      </Button>
      <StyledMenu
        id="demo-customized-menu"
        MenuListProps={{
          "aria-labelledby": "demo-customized-button",
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
        <MenuItem
          disabled={
            finalizeButtonStatus.current === "Completed" || isComplete
              ? true
              : false
          }
          onClick={() => {
            editCurrentScenario();
            handleClick();
          }}
          disableRipple
        >
          <EditIcon />
          <Typography>Edit Scenario</Typography>
        </MenuItem>
        <MenuItem
          onClick={() => {
            handleClose();
            navigate(`${prefix}/select-scenario`);
          }}
          disableRipple
        >
          <GradingOutlinedIcon fontSize="large" />
          <Typography>Select Other Scenario</Typography>
        </MenuItem>
      </StyledMenu>
    </Box>
  );
}
