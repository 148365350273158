import React, { useContext, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {
  Container,
  Grid,
  Divider,
  Typography,
  RadioGroup,
  FormControlLabel,
  Radio,
  Checkbox,
  Box,
  Button,
  Snackbar,
  Alert,
  // CircularProgress,
} from "@mui/material";

import HeaderNaming from "../../components/HeaderNaming/HeaderNaming.jsx";
import { UserProfileContext } from "../../context/UserContext.jsx";
import { apiService } from "../../utils/utils.js";
import Loader from "../../components/Loader.jsx";
import SaveAndExit from "../../components/reusableButton/SaveAndExit.jsx";
const ScoreAndUtilisation = ({ limitedScoreChecked, addOnScoreChecked, displayLocked, handleLimitedScoreChange, handleAddOnBundlesChange, handleDisplayLockedChange, handleSubmit, prefix }) => {
  return (
    <>
      <form onSubmit={handleSubmit}>
        <Typography data-test-id="name-Display Offer Score / Utilisation" variant="h3" gutterBottom>
          Display Offer Score / Utilisation
        </Typography>
        <Grid container direction="column" spacing={2}>
          <Grid item>
            <FormControlLabel
              data-test-id="name-Display Offer Score"
              control={<Checkbox data-test-id="checkbox-Display Offer Score" checked={limitedScoreChecked} onChange={handleLimitedScoreChange} color="secondary" />}
              label="Display Offer Score"
            />
          </Grid>
          <Grid item>
            <FormControlLabel
              data-test-id="name-Display Offer Utilisation"
              control={<Checkbox data-test-id="checkbox-Display Offer Utilisation" checked={addOnScoreChecked} onChange={handleAddOnBundlesChange} color="secondary" />}
              label="Display Offer Utilisation"
            />
          </Grid>
          {console.log(prefix)}
          {prefix !== "/demo" && (
            <>
              <Divider />
              <Grid item>
                <FormControlLabel data-test-id="" control={<Checkbox data-test-id="" checked={displayLocked} onChange={handleDisplayLockedChange} color="secondary" />} label="Display Locked Offer" />
              </Grid>
            </>
          )}
        </Grid>
      </form>
    </>
  );
};

const Metrics = ({ selectedOption, handleOptionChange }) => {
  return (
    <>
      <div className="another-component">
        <Typography data-test-id="name-Display Overall / Mandatory use cases" variant="h3" gutterBottom>
          Display Overall / Mandatory use cases
        </Typography>
        <RadioGroup
          value={selectedOption.onlyMandatoryUseCases ? "Mandatory" : selectedOption.onlyOverallUseCases ? "Overall" : "Both"}
          onChange={handleOptionChange} //no need of this
        >
          <FormControlLabel
            data-test-id="name-Only display metrics for Mandatory use cases"
            value="Mandatory"
            control={<Radio data-test-id="radio-Only display metrics for Mandatory use cases" color="secondary" />}
            label="Only display metrics for Mandatory use cases"
          />
          <FormControlLabel
            data-test-id="name-Only display metrics for Overall (combination of Mandatory and Optional) use cases"
            value="Overall"
            control={<Radio data-test-id="radio-Only display metrics for Overall (combination of Mandatory and Optional) use cases" color="secondary" />}
            label="Only display metrics for Overall (combination of Mandatory and Optional) use cases"
          />
          {/* <FormControlLabel
            data-test-id="name-Display metrics for both Overall and Mandatory use cases"
            value="Both"
            control={<Radio data-test-id="radio-Display metrics for both Overall and Mandatory use cases" color="secondary" />}
            label="Display metrics for both Overall and Mandatory use cases"
          /> */}
        </RadioGroup>
      </div>
    </>
  );
};

const MandatoryDisplayOption = ({ prefix }) => {
  const { userProfile, currentOrganization, buyerGuideName, scenarioName, currentOrg, currentBuyerGuideId, currentScenarioId, currentPackage, handleCloseNotification, notification, setNotification } =
    useContext(UserProfileContext);
  const data = {
    "End-user Organisation": userProfile?.profileType === "endUser" ? userProfile?.companyName : currentOrganization,
    "Buyer Guide Name": buyerGuideName,
    "Scenario Name": scenarioName,
  };

  const [limitedScoreChecked, setLimitedScoreChecked] = useState(false);
  const localData = JSON.parse(localStorage.getItem(`disPlayLocked-${currentBuyerGuideId}-${currentScenarioId}`));
  const [displayLocked, setDisplayLocked] = useState(localData?.displayLocked);
  const [isLoading, setIsLoading] = useState(false);
  const [addOnScoreChecked, setAddOnScoreChecked] = useState(false);
  // const [selectedOption, setSelectedOption] = useState("Both");
  const [useCaseDisplayOptions, setUseCaseDisplayOptions] = useState({
    onlyMandatoryUseCases: false,
    onlyOverallUseCases: false,
    bothOverallAndMandatoryUseCases: true,
  });

  const navigate = useNavigate();
  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      try {
        const response = await apiService("https://newtestfuncpython.azurewebsites.net/api/getDisplayOption?", {
          method: "POST",
          data: {
            email: userProfile?.email,
            currentOrganisation: currentOrg,
            currentBuyerGuide: currentBuyerGuideId,
            currentScenario: currentScenarioId,
            currentPackage: currentPackage?.id,
          },
        });
        if (response?.displayOfferScoreUtilisation) {
          setLimitedScoreChecked(response?.displayOfferScoreUtilisation?.displayOfferScore);
          setAddOnScoreChecked(response.displayOfferScoreUtilisation?.displayOfferUtilisation);
        }
        if (response?.displayUseCaseMetrics) {
          setUseCaseDisplayOptions({
            onlyMandatoryUseCases: response?.displayUseCaseMetrics?.onlyMandatoryUseCases,
            onlyOverallUseCases: response?.displayUseCaseMetrics?.onlyOverallUseCases,
            bothOverallAndMandatoryUseCases: response?.displayUseCaseMetrics?.bothOverallAndMandatoryUseCases,
          });
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, [userProfile, currentOrg, currentBuyerGuideId, currentScenarioId, currentPackage]);

  const handleLimitedScoreChange = (event) => {
    setLimitedScoreChecked(event.target.checked);
  };
  const handleDisplayLockedChange = (event) => {
    setDisplayLocked(event.target.checked);
  };
  const handleAddOnBundlesChange = (event) => {
    setAddOnScoreChecked(event.target.checked);
  };

  const handleOptionChange = (event) => {
    const value = event.target.value;
    setUseCaseDisplayOptions({
      onlyMandatoryUseCases: value === "Mandatory",
      onlyOverallUseCases: value === "Overall",
      bothOverallAndMandatoryUseCases: value === "Both",
    });
  };

  const saveAndContinue = async () => {
    try {
      setIsLoading(true);
      localStorage.setItem(`disPlayLocked-${currentBuyerGuideId}-${currentScenarioId}`, JSON.stringify({ displayLocked }));
      const response = await apiService("https://newtestfuncpython.azurewebsites.net/api/updateDisplayOption?", {
        method: "POST",
        data: {
          email: userProfile?.email,
          currentOrganisation: currentOrg,
          currentBuyerGuide: currentBuyerGuideId,
          currentScenario: currentScenarioId,
          currentPackage: currentPackage?.id,
          displayOptionsTab: {
            displayOfferScoreUtilisation: {
              displayOfferScore: limitedScoreChecked,
              displayOfferUtilisation: addOnScoreChecked,
            },
            displayUseCaseMetrics: useCaseDisplayOptions,
          },
        },
      });
      setNotification({
        open: true,
        message: response?.message === "Display options updated successfully." ? response?.message : "Failed to Update Display option",
        severity: response?.message === "Display options updated successfully." ? "success" : "error",
      });
    } catch (error) {
      console.error("Error updating data:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    const button = event.target.querySelector('button[type="submit"]');
    button.textContent = "Saved";

    await saveAndContinue(); //no need for this

    setTimeout(() => {
      button.textContent = "Save";
    }, 2000);
  };

  return isLoading ? (
    <Loader open={isLoading} />
  ) : (
    <>
      <Container sx={{ mt: 6, ml: 0 }}>
        <HeaderNaming data={data} />
        <Grid container spacing={2}>
          <Grid item xs={12} sm={5}>
            <ScoreAndUtilisation
              limitedScoreChecked={limitedScoreChecked}
              addOnScoreChecked={addOnScoreChecked}
              displayLocked={displayLocked}
              handleLimitedScoreChange={handleLimitedScoreChange}
              handleAddOnBundlesChange={handleAddOnBundlesChange}
              handleDisplayLockedChange={handleDisplayLockedChange}
              handleSubmit={handleSubmit}
              prefix={prefix}
            />
            <Divider sx={{ display: { xs: "block", sm: "none" }, my: 2 }} />
          </Grid>
          <Grid
            item
            xs={12}
            sm={2}
            md={1}
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Divider
              orientation="vertical"
              sx={{
                height: "100%",
                backgroundColor: "rgba(0, 0, 0, 0)",
                width: 0,
              }}
            />
          </Grid>
          <Grid item xs={12} sm={5}>
            <Metrics selectedOption={useCaseDisplayOptions} handleOptionChange={handleOptionChange} />
          </Grid>
        </Grid>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: 2,
            mt: 2,
            ml: 2,
          }}
        >
          <Button
            style={{ width: "250px" }}
            color="primary"
            variant="contained"
            disableRipple
            onClick={async () => {
              await saveAndContinue();
              navigate(`${prefix}/edit-scenario/scenario-settings/report-settings`);
            }}
          >
            <Typography data-test-id="save&continue" color="white">
              Save & Continue
            </Typography>
          </Button>
          <SaveAndExit
            saveAndContinue={saveAndContinue}
            data={{
              limitedScoreChecked,
              addOnScoreChecked,
            }}
            setIsLoading={setIsLoading}
            prefix={prefix}
          />
        </Box>
      </Container>
      <Snackbar open={notification.open} autoHideDuration={6000} onClose={handleCloseNotification}>
        <Alert onClose={handleCloseNotification} severity={notification.severity}>
          {notification.message}
        </Alert>
      </Snackbar>
    </>
  );
};

export default MandatoryDisplayOption;
