import React, { useState } from "react";
import { Menu, MenuItem } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import CustomButton from "../CustomButton";

const HoverDropdown = ({ filterOptions, parentKey, grandparent }) => {
  const [anchorEl, setAnchorEl] = useState(null); // Tracks the anchor element for the menu
  const [isMenuHovered, setIsMenuHovered] = useState(false); // Tracks if the menu is hovered
  const { label, ...rest } = filterOptions;
  const open = Boolean(anchorEl); // Determines if the menu is open
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const handleClick = (event) => {
    if (anchorEl !== event.currentTarget) {
      setAnchorEl(event.currentTarget); // Set the anchor element when button is clicked/hovered
    }
  };
  const prefix = pathname.split("/")[1];

  const handleClose = () => {
    setAnchorEl(null); // Close the menu
    setIsMenuHovered(false); // Reset menu hover state
  };
  const lastIndexValueOfURL = pathname.split("/").filter(Boolean).at(-1);

  const shouldSkipValue = {
    "comparative-analysis": ["Vendor Details", "Offer Details"],
    "vendor-details": ["Offer Details"],
  };

  return (
    <div>
      {/* CustomButton changes color based on menu hover state */}
      <CustomButton
        aria-controls={open ? "hover-menu" : undefined}
        aria-haspopup="true"
        onMouseOver={handleClick}
        onClick={handleClick}
        text={label}
        sx={{
          width: "auto",
          outline: "none",
          border: "none",
          padding: "0px",
          heigth: "max-content",
          background: "none",
          fontWeight: "normal",
          color: isMenuHovered || open ? "black" : "gray", // Change color based on hover or open state
          boxShadow: "none", // Remove default shadow
          "&:hover": {
            boxShadow: "none", // Ensure no shadow on hover
          },
        }}
      />

      <Menu
        id="hover-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-Link",
          onMouseEnter: () => setIsMenuHovered(true), // Set hover state when menu is hovered
          onMouseLeave: () => {
            setIsMenuHovered(false); // Reset hover state when cursor leaves menu
            handleClose();
          },
        }}
      >
        {Object.entries(rest).map(([key, value]) => {
          if (typeof value === "object" && value !== null) {
            // Handle when value is an object (sub-menu)
            return (
              <MenuItem key={key} disableRipple>
                <HoverDropdown filterOptions={value} parentKey={key} grandparent={parentKey} />
              </MenuItem>
            );
          } else {
            // Handle when value is not an object (menu item)
            return (prefix === "demo" && value === "Available Buyer Guide") || shouldSkipValue[lastIndexValueOfURL]?.includes(value) ? null : (
              <MenuItem
                key={key}
                selected={lastIndexValueOfURL === key}
                onClick={() => {
                  if (lastIndexValueOfURL !== key) {
                    handleClose();
                    navigate(grandparent ? `${prefix}/${grandparent}/${parentKey}/${key}` : `${prefix}/${parentKey}/${key}`);
                  } else {
                    handleClose();
                  }
                }}
                sx={{
                  color: lastIndexValueOfURL === key ? "#000" : "gray",
                  fontWeight: lastIndexValueOfURL === key ? "bold" : "normal",
                  textDecoration: "none",
                  ":hover": { color: "gray" },
                  ":visited": { color: "gray" },
                  ":active": { color: "gray" },
                }}
              >
                {value}
              </MenuItem>
            );
          }
        })}
      </Menu>
    </div>
  );
};

export default HoverDropdown;
