import React, { useState, useRef, useContext } from "react";
import HeaderNaming from "../components/HeaderNaming/HeaderNaming";
import { Box, Typography, Grid, Checkbox, Radio, Button } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { apiService } from "../utils/utils";
import { UserProfileContext } from "../context/UserContext";
const models = [
  { id: "M1", name: "BERT" },
  { id: "M2", name: "all-MiniLM-L6-v2" },
  { id: "M3", name: "GPT-4o" },
  { id: "M4", name: "OpenAI" },
];
const parameters = [
  { id: "P1", name: "Name" },
  { id: "P2", name: "Description" },
  { id: "P3", name: "Path" },
];
function ModelAndParameter() {
  const { userProfile, prefix } = useContext(UserProfileContext);
  //const localStorageData = JSON.parse(localStorage.getItem("mappingModelData"));
  //const mappingData = localStorageData?.mappingModelData;
  const localStorageBuyerGuideData = JSON.parse(
    localStorage.getItem("currentbuyerGuideData")
  );
  const buyerGuideId = localStorageBuyerGuideData?.buyerGuideId;
  const localStorageVendorData = JSON.parse(
    localStorage.getItem("currentVendorData")
  );
  const vendorId = localStorageVendorData?.vendorId;
  const localStorageCurrentOfferData = JSON.parse(
    localStorage.getItem("currentOfferData")
  );
  const OfferId = localStorageCurrentOfferData?.offerId;
  const modelList = useRef(models);
  const ParameterList = useRef(parameters);
  const navigate = useNavigate();
  const [, setIsLoading] = useState(true);
  const [selectedModelId, setSelectedModelId] = useState(null);
  const [modelAndParameterSelection, setModelAndParameterSelection] = useState({
    "Select Embedding Model": null,
    "Mapping Parameters": {
      Name: true,
      Description: false,
      Path: false,
    },
    "Question Based Mapping": false,
  });
  const headerData = {
    "Buyer Guide": localStorageBuyerGuideData?.buyerGuideName,
    "Vendor Name": localStorageVendorData?.vendorName,
    "Offer Name": localStorageCurrentOfferData?.offerName,
  };
  const performSimilarityAnalysis = async () => {
    try {
      setIsLoading(true);
      const response = await apiService(
        "https://newtestfuncpython.azurewebsites.net/api/performSimilarityAnalysis",
        {
          method: "POST",
          data: {
            email: userProfile?.email,
            currentBuyerGuideId: buyerGuideId,
            currentVendorId: vendorId,
            currentOfferId: OfferId,
            modelAndParameterSelection: modelAndParameterSelection,
          },
        }
      );
      console.log(response);
    } catch (error) {
      console.log("request Error:", error);
    } finally {
      setIsLoading(false);
    }
  };
  return (
    <Box>
      <HeaderNaming data={headerData} />
      <Box sx={{ display: "flex", flexDirection: "column" }}>
        <Grid container spacing={2}>
          <Grid
            item
            xs={12}
            sx={{
              border: "solid 1px black",
              borderRadius: "10px",
              marginTop: "10px",
            }}
          >
            <Grid container spacing={1}>
              <Grid item xs={4} alignItems="center">
                <Typography variant="h6">Select Embedding Model :</Typography>
              </Grid>
              <Grid item xs={8}>
                <Grid container spacing={1} alignItems="center">
                  {modelList.current?.map((item, index) => {
                    return (
                      <Grid
                        container
                        key={`${item?.id}-${index}`}
                        alignItems="center"
                      >
                        <Grid item xs={2} md={1}>
                          <Radio
                            checked={item.id === selectedModelId ? true : false}
                            onClick={() => {
                              setSelectedModelId(item?.id);
                              setModelAndParameterSelection((prev) => {
                                return {
                                  ...prev,
                                  "Select Embedding Model": item?.name,
                                };
                              });
                            }}
                          />
                        </Grid>
                        <Grid item xs={10} md={11}>
                          {item?.name}
                        </Grid>
                      </Grid>
                    );
                  })}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid
            item
            xs={12}
            sx={{
              border: "solid 1px black",
              borderRadius: "10px",
              marginTop: "10px",
            }}
          >
            <Grid container spacing={1}>
              <Grid item xs={4} alignItems="center">
                <Typography variant="h6"> Mapping Parameters : </Typography>
              </Grid>
              <Grid item xs={8}>
                <Grid container spacing={2} alignItems="center">
                  {ParameterList.current?.map((item, index) => {
                    return (
                      <Grid
                        container
                        key={`${item?.id}-${index}`}
                        alignItems="center"
                      >
                        <Grid item xs={2} md={1}>
                          <Checkbox
                            onClick={() => {
                              if (
                                modelAndParameterSelection[
                                  "Mapping Parameters"
                                ][item?.name]
                              ) {
                                setModelAndParameterSelection((prev) => {
                                  return {
                                    ...prev,
                                    "Mapping Parameters": {
                                      ...prev["Mapping Parameters"],
                                      [item?.name]: false,
                                    },
                                  };
                                });
                              } else {
                                setModelAndParameterSelection((prev) => {
                                  return {
                                    ...prev,
                                    "Mapping Parameters": {
                                      ...prev["Mapping Parameters"],
                                      [item?.name]: true,
                                    },
                                  };
                                });
                              }
                            }}
                            checked={
                              modelAndParameterSelection["Mapping Parameters"][
                                item?.name
                              ]
                            }
                            disabled={item?.name === "Name"}
                          />
                        </Grid>
                        <Grid item xs={10} md={11}>
                          {item?.name}
                        </Grid>
                      </Grid>
                    );
                  })}
                  <Grid container alignItems="center">
                    <Grid
                      item
                      xs={11}
                      md={4}
                      sx={{ borderTop: "1px solid gray" }}
                    ></Grid>
                    <Grid item xs={1} md={8}></Grid>
                    <Grid item xs={2} md={1}>
                      <Checkbox
                        onClick={() => {
                          if (
                            modelAndParameterSelection["Question Based Mapping"]
                          ) {
                            setModelAndParameterSelection((prev) => {
                              return {
                                ...prev,
                                "Question Based Mapping": true,
                              };
                            });
                          } else {
                            setModelAndParameterSelection((prev) => {
                              return {
                                ...prev,
                                "Question Based Mapping": false,
                              };
                            });
                          }
                        }}
                      />{" "}
                    </Grid>
                    <Grid item xs={10} md={10}>
                      Question Based Mapping
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Box>
      <Box sx={{ marginTop: "20px" }}>
        <Button
          sx={{ width: "250px" }}
          color="primary"
          variant="contained"
          disableRipple
          onClick={async () => {
            localStorage.setItem(
              "mappingModelData",
              JSON.stringify({ mappingModelData: modelAndParameterSelection })
            );
            await performSimilarityAnalysis();
            navigate(`${prefix}/usecase-to-feature`);
          }}
        >
          Preform Similarity Analysis
        </Button>
      </Box>
    </Box>
  );
}

export default ModelAndParameter;
