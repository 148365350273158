import React, { useState, useEffect, useContext, useCallback } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import TreeComponent from "./TreeComponent";
import TreeMapComponent from "./TreeMapComponent";
import "./MainComponent.css";
import { UserProfileContext } from "../context/UserContext";
import {
  TextField,
  Button,
  MenuItem,
  Select,
  FormControl,
  InputLabel,
  IconButton,
} from "@mui/material";
import FullscreenIcon from "@mui/icons-material/Fullscreen";
import FullscreenExitIcon from "@mui/icons-material/FullscreenExit";

const dummyData = [
  {
    buyerGuideId: "4:525b76d3-e272-4bc1-b9a4-573178e548ed:13",
    buyerGuideName: "DO NOT MAP: Zero Trust Network Access (ZTNA) Solution",
    importance: 10,
    "Use Case": [
      {
        useCaseId: "4:525b76d3-e272-4bc1-b9a4-573178e548ed:4484",
        useCaseName: "Authentication ",
        importance: 7,
        "Use Case": [
          {
            useCaseId: "4:525b76d3-e272-4bc1-b9a4-573178e548ed:1245",
            useCaseName: "Dynamic Account Validity Checks",
            importance: 5,
            "Use Case": [
              {
                useCaseId: "4:525b76d3-e272-4bc1-b9a4-573178e548ed:2538",
                useCaseName: "Alerts",
                importance: 3,
              },
              {
                useCaseId: "4:525b76d3-e272-4bc1-b9a4-573178e548ed:2535",
                useCaseName: " Audit",
                importance: 4,
              },
            ],
          },
        ],
      },
      {
        useCaseId: "4:525b76d3-e272-4bc1-b9a4-573178e548ed:4484",
        useCaseName: "Okta",
        importance: 7,
        "Use Case": [
          {
            useCaseId: "4:525b76d3-e272-4bc1-b9a4-573178e548ed:1245",
            useCaseName: "Validity Checks",
            importance: 5,
            "Use Case": [
              {
                useCaseId: "4:525b76d3-e272-4bc1-b9a4-573178e548ed:2538",
                useCaseName: " Intelligence Alerts",
                importance: 3,
              },
              {
                useCaseId: "4:525b76d3-e272-4bc1-b9a4-573178e548ed:2535",
                useCaseName: "Credential Usage Audit",
                importance: 4,
              },
            ],
          },
        ],
      },
    ],
  },
  {
    buyerGuideId: "4:525b76d3-e272-4bc1-b9a4-573178e548ed:13",
    buyerGuideName: "Prasad Trust Solution",
    importance: 9,
    "Use Case": [
      {
        useCaseId: "4:525b76d3-e272-4bc1-b9a4-573178e548ed:4484",
        useCaseName: "Robust Mechanisms",
        importance: 8,
        "Use Case": [
          {
            useCaseId: "4:525b76d3-e272-4bc1-b9a4-573178e548ed:1245",
            useCaseName: "Dynamic Account Validity Checks",
            importance: 5,
            "Use Case": [
              {
                useCaseId: "4:525b76d3-e272-4bc1-b9a4-573178e548ed:2538",
                useCaseName: "Alerts",
                importance: 2,
              },
              {
                useCaseId: "4:525b76d3-e272-4bc1-b9a4-573178e548ed:2535",
                useCaseName: "Credential Usage Audit",
                importance: 4,
              },
            ],
          },
        ],
      },
    ],
  },
  {
    buyerGuideId: "4:525b76d3-e272-4bc1-b9a4-573178e548ed:13",
    buyerGuideName: "Wada Pav Trust Solution",
    importance: 8,
    "Use Case": [
      {
        useCaseId: "4:525b76d3-e272-4bc1-b9a4-573178e548ed:4484",
        useCaseName: "Robust User Authentication Mechanisms",
        importance: 6,
        "Use Case": [
          {
            useCaseId: "4:525b76d3-e272-4bc1-b9a4-573178e548ed:1245",
            useCaseName: "Dynamic Account Validity Checks",
            importance: 4,
            "Use Case": [
              {
                useCaseId: "4:525b76d3-e272-4bc1-b9a4-573178e548ed:2538",
                useCaseName: "Integrated Threat  Alerts",
                importance: 3,
              },
              {
                useCaseId: "4:525b76d3-e272-4bc1-b9a4-573178e548ed:2535",
                useCaseName: "Audit",
                importance: 2,
              },
            ],
          },
        ],
      },
    ],
  },
  {
    buyerGuideId: "4:525b76d3-e272-4bc1-b9a4-573178e548ed:13",
    buyerGuideName: "Google Trust Solution",
    importance: 8,
    "Use Case": [
      {
        useCaseId: "4:525b76d3-e272-4bc1-b9a4-573178e548ed:4484",
        useCaseName: "google User Authentication Mechanisms",
        importance: 6,
        "Use Case": [
          {
            useCaseId: "4:525b76d3-e272-4bc1-b9a4-573178e548ed:1245",
            useCaseName: "google Account Validity Checks",
            importance: 4,
            "Use Case": [
              {
                useCaseId: "4:525b76d3-e272-4bc1-b9a4-573178e548ed:2538",
                useCaseName: "Integrated Threat Intelligence Alerts",
                importance: 3,
              },
              {
                useCaseId: "4:525b76d3-e272-4bc1-b9a4-573178e548ed:2535",
                useCaseName: "Audit",
                importance: 2,
              },
            ],
          },
        ],
      },
    ],
  },
  {
    buyerGuideId: "4:525b76d3-e272-4bc1-b9a4-573178e548ed:13",
    buyerGuideName: "Microsoft Pav Trust Solution",
    importance: 20,
    "Use Case": [
      {
        useCaseId: "4:525b76d3-e272-4bc1-b9a4-573178e548ed:4484",
        useCaseName: "Microsoft User Authentication Mechanisms",
        importance: 6,
        "Use Case": [
          {
            useCaseId: "4:525b76d3-e272-4bc1-b9a4-573178e548ed:1245",
            useCaseName: "Microsoft Account Validity Checks",
            importance: 4,
            "Use Case": [
              {
                useCaseId: "4:525b76d3-e272-4bc1-b9a4-573178e548ed:2538",
                useCaseName: "Microsoft Threat Intelligence Alerts",
                importance: 3,
              },
              {
                useCaseId: "4:525b76d3-e272-4bc1-b9a4-573178e548ed:2535",
                useCaseName: "Credential",
                importance: 2,
              },
            ],
          },
        ],
      },
    ],
  },
];

function MainComponent() {
  const [, setJsonInput] = useState("");
  const [parsedData, setParsedData] = useState(null);
  const [loading, setLoading] = useState(false);
  const location = useLocation();
  const { userProfile, prefix } = useContext(UserProfileContext);
  const navigate = useNavigate();
  const [elementId, setElementId] = useState(null);
  const [description, setDescription] = useState("");
  const [featureCount, setFeatureCount] = useState("");
  const [useCaseCount, setUseCaseCount] = useState("");
  const [treeType, setTreeType] = useState("collapsible");
  const [isFullscreen, setIsFullscreen] = useState(false);

  const isFromVendorOfferMenu = location.state?.fromVendorOfferMenu || false;
  const isFromAddNewOffer = location.state?.fromAddNewOffer || false;
  const isFromGeneratedFeatures =
    location.state?.fromGeneratedFeatures || false;
  const flag1 = location.state?.flag1 || false;
  const flag2 = location.state?.flag2 || false;

  const showFeatureButtons =
    isFromVendorOfferMenu || isFromAddNewOffer || isFromGeneratedFeatures;

  useEffect(() => {
    const initialData =
      location.state?.buyerGuideData ||
      location.state?.analysisData ||
      location.state?.data ||
      null;
    if (initialData) {
      const json = JSON.stringify(initialData, null, 2);
      setJsonInput(json);
      setParsedData(initialData);
    }
  }, [location.state]);

  const handleGenerateUseCases = async () => {
    if (!elementId) {
      alert("Please select a rectangle before generating use cases.");
      return;
    }

    const isMatching = elementId === parsedData?.[0]?.buyerGuideId;
    const currentBuyerGuideId = isMatching
      ? elementId
      : parsedData?.[0]?.buyerGuideId;
    const reqBody = {
      email: userProfile.email,
      currentBuyerGuideId: currentBuyerGuideId,
      selectedUseCaseId: elementId,
    };

    if (description.trim()) {
      reqBody.userPrompt = description;
    } else {
      reqBody.userPrompt = "";
    }

    try {
      setLoading(true);
      const response = await fetch(
        "https://newtestfuncpython.azurewebsites.net/api/generateUseCasesWithAI",
        {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify(reqBody),
        }
      );
      if (!response.ok) throw new Error("Network response was not ok.");
      const data = await response.json();
      navigate(`${prefix}/sub-use-cases`, {
        state: { data, currentBuyerGuideId },
      });
    } catch (error) {
      console.error("Error generating use cases:", error);
      alert("Failed to generate use cases: " + error.message);
    } finally {
      setLoading(false);
    }
  };

  const handleReview = async () => {
    if (!elementId) {
      alert("Please select a rectangle before reviewing use cases.");
      return;
    }

    const isMatching = elementId === parsedData?.[0]?.buyerGuideId;
    const currentBuyerGuideId = isMatching
      ? elementId
      : parsedData?.[0]?.buyerGuideId;
    const reviewPayload = {
      email: userProfile.email,
      currentBuyerGuideId: currentBuyerGuideId,
      selectedUseCaseId: elementId,
    };

    try {
      setLoading(true);
      const response = await fetch(
        "https://newtestfuncpython.azurewebsites.net/api/reviewSubUseCasesForAnalyst",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(reviewPayload),
        }
      );
      const data = await response.json();
      navigate(`${prefix}/sub-use-cases`, {
        state: { data, currentBuyerGuideId },
      });
    } catch (error) {
      console.error("Error reviewing use cases:", error);
      alert("Failed to review use cases: " + error.message);
    } finally {
      setLoading(false);
    }
  };

  const handleGenerateFeatures = async () => {
    if (!elementId) {
      alert("Please select a rectangle before generating features.");
      return;
    }

    const offerId = parsedData?.[0]?.offerId;
    const isMatching = elementId === offerId;
    const currentOfferId = isMatching ? elementId : parsedData?.[0]?.offerId;
    const storedVendorId = localStorage.getItem("currentVendorId");

    const reqBody = {
      email: userProfile.email,
      currentFeatureId: elementId,
      currentOfferId: currentOfferId,
      userPrompt: description,
      currentVendorId: storedVendorId,
    };

    try {
      setLoading(true);
      const response = await fetch(
        "https://newtestfuncpython.azurewebsites.net/api/generateFeaturesWithAi",
        {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify(reqBody),
        }
      );
      if (!response.ok) throw new Error("Network response was not ok.");
      const data = await response.json();
      navigate(`${prefix}/generated-features`, {
        state: { data, currentOfferId },
      });
    } catch (error) {
      console.error("Error generating features:", error);
      alert("Failed to generate features: " + error.message);
    } finally {
      setLoading(false);
    }
  };

  const handleReviewFeatures = async () => {
    if (!elementId) {
      alert("Please select a rectangle before reviewing features.");
      return;
    }

    const offerId = parsedData?.[0]?.offerId;
    const isMatching = elementId === offerId;
    const currentOfferId = isMatching ? elementId : parsedData?.[0]?.offerId;

    const reviewPayload = {
      email: userProfile.email,
      currentOfferId: currentOfferId,
      currentFeatureId: elementId,
    };

    try {
      setLoading(true);
      const response = await fetch(
        "https://newtestfuncpython.azurewebsites.net/api/reviewOfferFeatures",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(reviewPayload),
        }
      );

      if (!response.ok) throw new Error("Network response was not ok.");
      const data = await response.json();
      navigate(`${prefix}/review-vendor-offer`, {
        state: { data, currentOfferId },
      });
    } catch (error) {
      console.error("Error reviewing features:", error);
      alert("Failed to review features: " + error.message);
    } finally {
      setLoading(false);
    }
  };

  const handleMapFeatures = async () => {
    if (!elementId) {
      alert("Please select a rectangle before mapping features.");
      return;
    }
    try {
      setLoading(true);
      const storedVendorId = localStorage.getItem("currentVendorId");
      const currentbuyerGuideData = JSON.parse(
        localStorage.getItem("currentbuyerGuideData")
      );
      const offerId = parsedData?.[0]?.offerId;
      const currentOfferId = elementId === offerId ? elementId : offerId;

      const storedMappingModelData = JSON.parse(
        localStorage.getItem("mappingModelData")
      );
      const filterData = JSON.parse(localStorage.getItem("filterData"));

      const payload = {
        email: userProfile.email,
        currentBuyerGuideId: currentbuyerGuideData?.buyerGuideId,
        currentVendorId: storedVendorId,
        currentOfferId: currentOfferId,
        selectedFeatureId: elementId,
        currentPageNumber: 1,
        highestMatches: Number(featureCount),
        recommendationsPerPage: 10,
        modelAndParameterSelection: storedMappingModelData?.mappingModelData,
        filtersOnRecommendations: filterData?.filtersOnRecommendations,
      };

      const response = await fetch(
        "https://newtestfuncpython.azurewebsites.net/api/mappingRecommendationForSepecificFeature",
        {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify(payload),
        }
      );

      if (!response.ok) throw new Error("Network response was not ok.");

      const data = await response.json();
      navigate(`${prefix}/feature-recommendation`, {
        state: {
          pageType: "specificFeature",
          data: data,
          payload: payload,
          endPoint: "mappingRecommendationForSepecificFeature",
        },
      });
    } catch (error) {
      alert("Failed to map features: " + error.message);
    } finally {
      setLoading(false);
    }
  };

  const handleMapUseCases = async () => {
    if (!elementId) {
      alert("Please select a rectangle before mapping use cases.");
      return;
    }
    try {
      setLoading(true);

      const isMatching = elementId === parsedData?.[0]?.buyerGuideId;
      const currentBuyerGuideId = isMatching
        ? elementId
        : parsedData?.[0]?.buyerGuideId;
      const currentVendorData = JSON.parse(
        localStorage.getItem("currentVendorData")
      );
      const currentOfferData = JSON.parse(
        localStorage.getItem("currentOfferData")
      );
      const storedMappingModelData = JSON.parse(
        localStorage.getItem("mappingModelData")
      );
      const filterData = JSON.parse(localStorage.getItem("filterData"));

      const payload = {
        email: userProfile.email,
        currentBuyerGuideId: currentBuyerGuideId,
        currentVendorId: currentVendorData?.vendorId,
        currentOfferId: currentOfferData?.offerId,
        selectedUseCaseId: elementId,
        currentPageNumber: 1,
        highestMatches: Number(useCaseCount),
        recommendationsPerPage: 10,
        modelAndParameterSelection: storedMappingModelData?.mappingModelData,
        filtersOnRecommendations: filterData?.filtersOnRecommendations,
      };

      const response = await fetch(
        "https://newtestfuncpython.azurewebsites.net/api/mappingRecommendationForSpecificUseCase",
        {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify(payload),
        }
      );

      if (!response.ok) throw new Error("Network response was not ok.");

      const data = await response.json();

      navigate(`${prefix}/usecase-recommendations`, {
        state: {
          pageType: "specificUseCase",
          data: data,
          payload: payload,
          endPoint: "mappingRecommendationForSpecificUseCase",
          highestMatches: Number(useCaseCount),
        },
      });
    } catch (error) {
      alert("Failed to map use cases: " + error.message);
    } finally {
      setLoading(false);
    }
  };

  const handleToggleFullscreen = () => {
    if (!isFullscreen) {
      document.documentElement
        .requestFullscreen()
        .then(() => setIsFullscreen(true));
    } else {
      document.exitFullscreen().then(() => setIsFullscreen(false));
    }
  };

  const onNodeClick = useCallback((elementId) => {
    setElementId(elementId);
  }, []);

  return (
    <div className="MainComponent">
      <div className="top-bar">
        <FormControl variant="outlined">
          <InputLabel id="tree-type-label">Tree Type</InputLabel>
          <Select
            labelId="tree-type-label"
            value={treeType}
            onChange={(e) => setTreeType(e.target.value)}
            label="Tree Type"
            style={{
              marginTop: "10px",
              marginRight: "15px",
              marginLeft: "15px",
            }}
          >
            <MenuItem value="collapsible">Collapsible Tree</MenuItem>
            <MenuItem value="treemap">TreeMap</MenuItem>
          </Select>
        </FormControl>

        {!flag1 && !flag2 && (
          <TextField
            multiline
            rows={1}
            value={description}
            onChange={(e) => setDescription(e.target.value)}
            label="Enter description for use case generation"
            variant="outlined"
            style={{
              marginTop: "10px",
              marginRight: "15px",
              marginLeft: "15px",
            }}
          />
        )}

        {flag1 ? (
          <div className="flag-input-container">
            <TextField
              type="number"
              onChange={(e) => setFeatureCount(Number(e.target.value))}
              label="How many Features do you want to map per Use Case?"
              variant="outlined"
              margin="normal"
              style={{
                marginTop: "15px",
                marginRight: "15px",
                marginLeft: "15px",
              }}
            />
            <Button
              variant="contained"
              color="primary"
              style={{
                marginTop: "15px",
                marginRight: "15px",
                marginLeft: "15px",
              }}
              onClick={handleMapFeatures}
            >
              Map Features
            </Button>
            <Button
              className="review-button"
              variant="contained"
              color="secondary"
              onClick={() => navigate(`${prefix}/usecase-to-feature`)}
              style={{
                marginTop: "15px",
                marginRight: "15px",
                marginLeft: "15px",
              }}
            >
              Exit to Main Mapping Page
            </Button>
          </div>
        ) : flag2 ? (
          <div className="flag-input-container">
            <TextField
              type="number"
              onChange={(e) => setUseCaseCount(Number(e.target.value))}
              label="How many Use Cases do you want to map per Feature?"
              variant="outlined"
              margin="normal"
              style={{
                marginTop: "15px",
                marginRight: "15px",
                marginLeft: "15px",
              }}
            />
            <Button
              variant="contained"
              color="primary"
              style={{
                marginTop: "15px",
                marginRight: "15px",
                marginLeft: "15px",
              }}
              onClick={handleMapUseCases}
            >
              Map Use Cases
            </Button>
            <Button
              className="review-button"
              variant="contained"
              color="secondary"
              onClick={() => navigate(`${prefix}/usecase-to-feature`)}
              style={{
                marginTop: "15px",
                marginRight: "15px",
                marginLeft: "15px",
              }}
            >
              Exit to Main Mapping Page
            </Button>
          </div>
        ) : (
          <>
            {showFeatureButtons ? (
              <>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleGenerateFeatures}
                  style={{ marginTop: "10px" }}
                >
                  Generate Features
                </Button>
                <Button
                  variant="contained"
                  className="review-button"
                  onClick={handleReviewFeatures}
                  style={{
                    marginTop: "15px",
                    marginRight: "15px",
                    marginLeft: "15px",
                  }}
                >
                  Review Features
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => {}}
                  style={{
                    marginTop: "15px",
                    marginRight: "15px",
                    marginLeft: "15px",
                  }}
                >
                  Finalize Features
                </Button>
              </>
            ) : (
              <>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleGenerateUseCases}
                  style={{
                    marginTop: "6px",
                    marginRight: "15px",
                    marginLeft: "15px",
                  }}
                >
                  Generate Use Cases
                </Button>
                <Button
                  variant="contained"
                  className="review-button"
                  onClick={handleReview}
                  style={{
                    marginTop: "6px",
                    marginRight: "15px",
                    marginLeft: "15px",
                  }}
                >
                  Review Use Cases
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => {}}
                  style={{
                    marginTop: "6px",
                    marginRight: "15px",
                    marginLeft: "15px",
                  }}
                >
                  Publish Buyer Guide
                </Button>
              </>
            )}
          </>
        )}

        <IconButton
          aria-label="fullscreen"
          onClick={handleToggleFullscreen}
          style={{ marginLeft: "auto" }}
        >
          {isFullscreen ? <FullscreenExitIcon /> : <FullscreenIcon />}
        </IconButton>
      </div>

      {loading && <div className="loading-spinner"></div>}

      {!loading && (
        <>
          {treeType === "collapsible" ? (
            parsedData && (
              <TreeComponent
                data={parsedData}
                onUpdateJson={setParsedData}
                onNodeClick={onNodeClick}
              />
            )
          ) : (
            <TreeMapComponent data={dummyData} />
          )}
        </>
      )}
    </div>
  );
}

export default MainComponent;
