import React from "react";
import { Menu, MenuItem } from "@mui/material";
import { useTheme } from "@emotion/react";

const NodeMenu = ({ menuPosition, closeMenu, onAction, selectedNode, selectedNodes, edges }) => {
  const handleAction = (action) => {
    onAction(action);
    closeMenu();
  };
  const theme = useTheme();
  let hasDescendants = false;

  // Early exit loop
  for (let i = 0; i < edges.length; i++) {
    if (edges[i].source === selectedNode?.id) {
      hasDescendants = true;
      break; // Exit as soon as a descendant is found
    }
  }
  const overlappedNode = selectedNodes?.[0];
  const overlappingNode = selectedNodes?.[1];
  return (
    <Menu
      id="node-context-menu"
      anchorReference="anchorPosition"
      anchorPosition={menuPosition ? { top: menuPosition.y, left: menuPosition.x } : undefined}
      open={Boolean(menuPosition)}
      onClose={closeMenu}
      sx={{ fontFamily: theme.typography.fontFamily }}
    >
      <MenuItem sx={{ display: selectedNodes.length > 1 ? "none" : "block" }} onClick={() => handleAction("deleteNodeRetainChildren")}>
        Delete <b>{`${selectedNode?.data?.label || ""} `}</b>
        Node Only
      </MenuItem>

      {edges.some((edge) => edge.source === selectedNode?.id) && (
        <MenuItem sx={{ display: selectedNodes.length > 1 ? "none" : "block" }} onClick={() => handleAction("deleteNodeAndDescendants")}>
          Delete Delete <b>{`${selectedNode?.data?.label || ""} `}</b> Node and All Descendants
        </MenuItem>
      )}

      {/* ------------------------------------------------------ */}
      {edges.some((edge) => edge.source === selectedNode?.id) && (
        <MenuItem sx={{ display: selectedNodes.length < 1 ? "none" : "block" }} onClick={() => handleAction("reparentWithDescendants")}>
          Reparent <b>{`${overlappingNode?.data?.label || ""} `}</b> Node with All Descendants to <b>{`${overlappedNode?.data?.label} `}</b>
        </MenuItem>
      )}

      {edges.some((edge) => edge.source !== selectedNode?.id) && (
        <MenuItem sx={{ display: selectedNodes.length < 1 ? "none" : "block" }} onClick={() => handleAction("reparentChildlessNodeToParent")}>
          Reparent Node to Target Node
        </MenuItem>
      )}

      {edges.some((edge) => edge.source === selectedNode?.id) && (
        <MenuItem sx={{ display: selectedNodes.length < 1 ? "none" : "block" }} onClick={() => handleAction("reassignDescendantsToTargetNode")}>
          Reassign All Descendants to Trarget Node
        </MenuItem>
      )}

      {/* ------------------------------------------------------ */}

      <MenuItem onClick={() => handleAction("mergeBothNodes")} sx={{ display: selectedNodes.length < 1 ? "none" : "block" }}>
        Merge Both Nodes
      </MenuItem>

      {!hasDescendants && (
        <MenuItem onClick={() => handleAction("mergeAndReplace")} sx={{ display: selectedNodes.length < 1 ? "none" : "block" }}>
          Merge And Replace the Target Node
        </MenuItem>
      )}

      {hasDescendants && (
        <MenuItem onClick={() => handleAction("mergeAndReplace")} sx={{ display: selectedNodes.length < 1 ? "none" : "block" }}>
          Merge And Replace <b>{overlappedNode?.data?.label || ""}</b> with <b> {overlappingNode?.data?.label || ""}</b> Node with Descendants
        </MenuItem>
      )}
    </Menu>
  );
};

export default NodeMenu;
