import React, { useContext, useEffect, useState } from "react";
import {
  Typography,
  Grid,
  Slider,
  TextField,
  Button,
  Alert,
  Snackbar,
  CircularProgress,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { Box } from "@mui/material";
import HeaderNaming from "../../components/HeaderNaming/HeaderNaming.jsx";
import { UserProfileContext } from "../../context/UserContext.jsx";
import { apiService } from "../../utils/utils.js";
import SaveAndExit from "../../components/reusableButton/SaveAndExit.jsx";
// const data = {
//   'End-user Organisation Name': 'dummyEndOrg1',
//   'Buyer Guide Name': 'DummyBuyerGuide',
//   'Scenario Name': 'dummyScenarioName'
// };

const ThresholdOption = ({ prefix }) => {
  const navigate = useNavigate();
  const {
    userProfile,
    currentOrganization,
    handleCloseNotification,
    buyerGuideName,
    scenarioName,
    notification,
    currentBuyerGuideId,
    currentOrg,
    currentScenarioId,
    currentPackage,
    setNotification,
  } = useContext(UserProfileContext);
  const data = {
    "End-user Organisation":
      userProfile?.profileType === "endUser"
        ? userProfile?.companyName
        : currentOrganization,
    "Buyer Guide Name": buyerGuideName,
    "Scenario Name": scenarioName,
  };
  const [isLoading, setIsLoading] = useState(false);
  const [minOfferUtilizationOverall, setMinOfferUtilizationOverall] = useState(
    parseInt(localStorage.getItem("minOfferUtilizationOverall")) || 0
  );
  const [minOfferUtilizationMandatory, setMinOfferUtilizationMandatory] =
    useState(
      parseInt(localStorage.getItem("minOfferUtilizationMandatory")) || 0
    );
  const [minOfferRelevanceOverall, setMinOfferRelevanceOverall] = useState(
    parseInt(localStorage.getItem("minOfferRelevanceOverall")) || 0
  );
  const [minOfferRelevanceMandatory, setMinOfferRelevanceMandatory] = useState(
    parseInt(localStorage.getItem("minOfferRelevanceMandatory")) || 0
  );
  const [useCaseThreshold, setUseCaseThreshold] = useState(
    JSON.parse(localStorage.getItem("useCaseThreshold")) || [0, 5]
  );
  const [offerScoreThreshold, setOfferScoreThreshold] = useState(
    parseInt(localStorage.getItem("offerScoreThreshold")) || 50
  );

  const [editField, setEditField] = useState(null);

  const spanWidth = "auto";

  const handleResetToDefault = () => {
    setMinOfferUtilizationOverall(0);
    setMinOfferUtilizationMandatory(0);
    setMinOfferRelevanceOverall(0);
    setMinOfferRelevanceMandatory(0);
    setUseCaseThreshold([0, 5]);
    setOfferScoreThreshold(50);

    localStorage.setItem("minOfferUtilizationOverall", 0);
    localStorage.setItem("minOfferUtilizationMandatory", 0);
    localStorage.setItem("minOfferRelevanceOverall", 0);
    localStorage.setItem("minOfferRelevanceMandatory", 0);
    localStorage.setItem("useCaseThreshold", JSON.stringify([0, 5]));
    localStorage.setItem("offerScoreThreshold", 50);
  };

  const handleFieldEdit = (field) => () => {
    setEditField(field);
  };

  const handleInputChange = (setValue, localStorageKey) => (event) => {
    const newValue =
      event.target.value === "" ? "" : Number(event.target.value);
    if (!isNaN(newValue)) {
      setValue(newValue);
      localStorage.setItem(localStorageKey, newValue);
    }
  };
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await apiService(
          "https://newtestfuncpython.azurewebsites.net/api/getThresholdOption?",
          {
            method: "POST",
            data: {
              email: userProfile?.email,
              currentOrganisation: currentOrg,
              currentBuyerGuide: currentBuyerGuideId,
              currentScenario: currentScenarioId,
              currentPackage: currentPackage?.id,
            },
          }
        );
        // Set state with response data
        setMinOfferUtilizationOverall(
          response.thresholdOptions?.minOfferUtilizationOverall || 0
        );
        setMinOfferUtilizationMandatory(
          response.thresholdOptions?.minOfferUtilizationMandatory || 0
        );
        setMinOfferRelevanceOverall(
          response.thresholdOptions?.minOfferRelevanceOverall || 0
        );
        setMinOfferRelevanceMandatory(
          response.thresholdOptions?.minOfferRelevanceMandatory || 0
        );
        setOfferScoreThreshold(
          response?.thresholdOptions?.offerScoreThresholdRelevance || 50
        );

        // Save data in local storage
        localStorage.setItem(
          "minOfferUtilizationOverall",
          response.thresholdOptions?.minOfferUtilizationOverall || 0
        );
        localStorage.setItem(
          "minOfferUtilizationMandatory",
          response.thresholdOptions?.minOfferUtilizationMandatory || 0
        );
        localStorage.setItem(
          "minOfferRelevanceOverall",
          response.thresholdOptions?.minOfferRelevanceOverall || 0
        );
        localStorage.setItem(
          "offerScoreThreshold",
          JSON.stringify(
            response?.thresholdOptions?.offerScoreThresholdRelevance || 50
          )
        );
      } catch (error) {
        // console.error("Error fetching data:", error);
      }
    };

    console.log("calling");
    fetchData();
  }, [
    userProfile,
    currentOrg,
    currentPackage?.id,
    currentBuyerGuideId,
    currentScenarioId,
  ]);

  // const handleRangeInputChange = (index) => (event) => {
  //   const newValue =
  //     event.target.value === "" ? "" : parseFloat(event.target.value);
  //   if (!isNaN(newValue)) {
  //     const newRange = [...useCaseThreshold];
  //     newRange[index] = newValue;
  //     setUseCaseThreshold(newRange);
  //     localStorage.setItem("useCaseThreshold", JSON.stringify(newRange));
  //   }
  // };

  const handleBlur = () => {
    setEditField(null);
  };

  const renderEditableValue = (field, value, setValue, localStorageKey) =>
    editField === field ? (
      <TextField
        value={value}
        onChange={handleInputChange(setValue, localStorageKey)}
        onBlur={handleBlur}
        autoFocus
        inputProps={{ style: { width: "50px", padding: 0 } }}
        style={{ margin: 0 }}
        variant="standard"
        type="number"
      />
    ) : (
      <span onClick={handleFieldEdit(field)} style={{ cursor: "pointer" }}>
        {value}
      </span>
    );

  // const renderRangeEditableValue = (field, value, index) =>
  //   editField === `${field}-${index}` ? (
  //     <TextField
  //       value={value}
  //       onChange={handleRangeInputChange(index)}
  //       onBlur={handleBlur}
  //       autoFocus
  //       inputProps={{ style: { width: "50px", padding: 0 }, step: "0.1" }}
  //       style={{ margin: 0 }}
  //       variant="standard"
  //       type="number"
  //     />
  //   ) : (
  //     <span
  //       onClick={handleFieldEdit(`${field}-${index}`)}
  //       style={{ cursor: "pointer" }}
  //     >
  //       {value}
  //     </span>
  //   );

  const marks = [
    { value: 0, label: "0%" },
    { value: 100, label: "100%" },
  ];

  // const useCaseMarks = [
  //   useCaseThreshold[0] > 0.8 ? { value: 0, label: "0" } : null,
  //   useCaseThreshold[1] < 4.3 ? { value: 5, label: "5" } : null,
  //   { value: useCaseThreshold[0] / 2, label: "Not required" },
  //   {
  //     value: (useCaseThreshold[1] + useCaseThreshold[0]) / 2,
  //     label: "Optional",
  //   },
  //   { value: (useCaseThreshold[1] + 5) / 2, label: "Mandatory" },
  // ].filter((mark) => mark !== null);

  const offerScoremarks = [
    {
      value: offerScoreThreshold / 2,
      label: `Relevance:${offerScoreThreshold}`,
    },
    {
      value: (100 + offerScoreThreshold) / 2,
      label: `Utilisation:${100 - offerScoreThreshold}`,
    },
  ];

  const commonSliderStyles = {
    "& .MuiSlider-track": {
      border: "none",
      height: 8,
      backgroundColor: "#C8C8C8",
    },
    "& .MuiSlider-rail": {
      height: 8,
      boxShadow: "inset 0px 0px 4px -2px #000",
    },
    "& .MuiSlider-valueLabel": {
      fontSize: 14,
      fontWeight: "normal",
      top: -6,
      backgroundColor: "unset",
      color: "black",
    },
  };

  // const useCaseSliderStyles = {
  //   "& .MuiSlider-track": {
  //     height: "8px",
  //   },
  //   "& .MuiSlider-rail": {
  //     height: "8px",
  //     opacity: 1,
  //     background: `linear-gradient(to right, #C8C8C8, #C8C8C8 ${
  //       (useCaseThreshold[0] / 5) * 100
  //     }%, #4f40ba ${(useCaseThreshold[1] / 5) * 100}%, #4f40ba);`,
  //   },
  //   "& .MuiSlider-valueLabel": {
  //     fontSize: 14,
  //     fontWeight: "normal",
  //     top: -6,
  //     backgroundColor: "unset",
  //     color: "black",
  //     visibility: "visible",
  //     "&::before": {
  //       display: "none",
  //     },
  //   },
  //   "& .MuiSlider-markLabel": {
  //     color: "black",
  //   },
  // };

  const offerScoreSliderStyles = {
    "& .MuiSlider-track": {
      height: "8px",
    },
    "& .MuiSlider-rail": {
      height: "8px",
      opacity: 1,
      backgroundColor: "#4f40ba",
      color: "black",
    },
    "& .MuiSlider-valueLabel": {
      fontSize: 14,
      fontWeight: "normal",
      top: -6,
      backgroundColor: "unset",
    },
    "& .MuiSlider-markLabel": {
      color: "black",
    },
  };
  const saveAndContinue = async () => {
    try {
      const response = await apiService(
        "https://newtestfuncpython.azurewebsites.net/api/updateThresholdOption?",
        {
          method: "POST",
          data: {
            email: userProfile?.email,
            currentOrganisation: currentOrg,
            currentBuyerGuide: currentBuyerGuideId,
            currentScenario: currentScenarioId,
            currentPackage: currentPackage?.id,
            thresholdOptions: {
              minOfferUtilizationOverall: minOfferUtilizationOverall,
              minOfferUtilizationMandatory: minOfferUtilizationMandatory,
              minOfferRelevanceOverall: minOfferRelevanceOverall,
              minOfferRelevanceMandatory: minOfferRelevanceMandatory,
              offerScoreThresholdRelevance: offerScoreThreshold,
              offerScoreThresholdUtilization: 100 - offerScoreThreshold,
            },
          },
        }
      );
      setNotification({
        open: true,
        message:
          response?.message === "Threshold options updated successfully."
            ? response?.message
            : "Failed to Save Bundle option",
        severity:
          response?.message === "Threshold options updated successfully."
            ? "success"
            : "error",
      });
    } catch (error) {
      console.error("Error updating data:", error);
    }
  };

  return isLoading ? (
    <Box
      height="80vh"
      width="100%"
      justifyContent="center"
      alignItems="center"
      display="flex"
    >
      <CircularProgress />
    </Box>
  ) : (
    <>
      <HeaderNaming data={data} />
      <div
        className="threshold-option"
        style={{ width: "100%", position: "relative" }}
      >
        <Typography
          data-test-id="name-Threshold Option"
          variant="h3"
          gutterBottom
          marginTop={-1.7}
        >
          Threshold Option
        </Typography>
        <Grid container spacing={2} alignItems="center" display={"flex"} p={1}>
          <Grid item md={5} xs={12}>
            <Typography
              data-test-id="name-Minimum Offer Utilization (Overall Use Cases):"
              variant="body1"
              gutterBottom
            >
              <span
                style={{
                  width: `${spanWidth}`,
                  display: "inline-block",
                  marginRight: "2px",
                }}
              >
                Minimum Offer Utilization (Overall Use Cases):
              </span>
              {renderEditableValue(
                "minOfferUtilizationOverall",
                minOfferUtilizationOverall,
                setMinOfferUtilizationOverall,
                "minOfferUtilizationOverall"
              )}
              %
            </Typography>
          </Grid>
          <Grid item md={5} xs={12}>
            <Slider
              data-test-id="slider-Minimum Offer Utilization (Overall Use Cases):"
              value={minOfferUtilizationOverall}
              onChange={(e, newValue) => {
                setMinOfferUtilizationOverall(newValue);
                localStorage.setItem("minOfferUtilizationOverall", newValue);
              }}
              aria-labelledby="min-offer-utilization-slider"
              min={0}
              max={100}
              valueLabelDisplay="auto"
              track="inverted"
              marks={marks}
              sx={commonSliderStyles}
            />
          </Grid>

          <Grid item md={5} xs={12}>
            <Typography variant="body1" gutterBottom>
              <span
                data-test-id="name-Minimum Offer Utilization (Mandatory Use Cases):"
                style={{
                  width: `${spanWidth}`,
                  display: "inline-block",
                  marginRight: "2px",
                }}
              >
                Minimum Offer Utilization (Mandatory Use Cases):
              </span>
              {renderEditableValue(
                "minOfferUtilizationMandatory",
                minOfferUtilizationMandatory,
                setMinOfferUtilizationMandatory,
                "minOfferUtilizationMandatory"
              )}
              %
            </Typography>
          </Grid>

          <Grid item md={5} xs={12}>
            <Slider
              data-test-id="slider-Minimum Offer Utilization (Mandatory Use Cases):"
              value={minOfferUtilizationMandatory}
              onChange={(e, newValue) => {
                setMinOfferUtilizationMandatory(newValue);
                localStorage.setItem("minOfferUtilizationMandatory", newValue);
              }}
              aria-labelledby="min-offer-utilization-slider"
              min={0}
              max={100}
              valueLabelDisplay="auto"
              track="inverted"
              marks={marks}
              sx={commonSliderStyles}
            />
          </Grid>

          <Grid item md={5} xs={12}>
            <Typography
              data-test-id="name-Minimum Offer Relevance (Overall Use Cases):"
              variant="body1"
              gutterBottom
            >
              <span
                style={{
                  width: `${spanWidth}`,
                  display: "inline-block",
                  marginRight: "2px",
                }}
              >
                Minimum Offer Relevance (Overall Use Cases):
              </span>
              {renderEditableValue(
                "minOfferRelevanceOverall",
                minOfferRelevanceOverall,
                setMinOfferRelevanceOverall,
                "minOfferRelevanceOverall"
              )}
              %
            </Typography>
          </Grid>

          <Grid item md={5} xs={12}>
            <Slider
              data-test-id="slider-Minimum Offer Relevance (Overall Use Cases):"
              value={minOfferRelevanceOverall}
              onChange={(e, newValue) => {
                setMinOfferRelevanceOverall(newValue);
                localStorage.setItem("minOfferRelevanceOverall", newValue);
              }}
              aria-labelledby="min-offer-relevance-overall-slider"
              min={0}
              max={100}
              valueLabelDisplay="auto"
              track="inverted"
              marks={marks}
              sx={commonSliderStyles}
            />
          </Grid>

          <Grid item md={5} xs={12}>
            <Typography
              data-test-id="name-Minimum Offer Relevance (Mandatory Use Cases):"
              variant="body1"
              gutterBottom
            >
              <span
                style={{
                  width: `${spanWidth}`,
                  display: "inline-block",
                  marginRight: "2px",
                }}
              >
                Minimum Offer Relevance (Mandatory Use Cases):
              </span>
              {renderEditableValue(
                "minOfferRelevanceMandatory",
                minOfferRelevanceMandatory,
                setMinOfferRelevanceMandatory,
                "minOfferRelevanceMandatory"
              )}
              %
            </Typography>
          </Grid>

          <Grid item md={5} xs={12}>
            <Slider
              data-test-id="slider-Minimum Offer Relevance (Mandatory Use Cases):"
              value={minOfferRelevanceMandatory}
              onChange={(e, newValue) => {
                setMinOfferRelevanceMandatory(newValue);
                localStorage.setItem("minOfferRelevanceMandatory", newValue);
              }}
              aria-labelledby="min-offer-relevance-mandatory-slider"
              min={0}
              max={100}
              valueLabelDisplay="auto"
              track="inverted"
              marks={marks}
              sx={commonSliderStyles}
            />
          </Grid>

          {/* <Grid item md={5} xs={12}>
            <Typography variant="body1" gutterBottom>
              <span data-test-id="name-Use Case Threshold:" style={{ width: `${spanWidth}`, display: "inline-block" }}>
                Use Case Threshold:
              </span>
              {renderRangeEditableValue(
                "useCaseThreshold",
                useCaseThreshold[0],
                0
              )}{" "}
              -{" "}
              {renderRangeEditableValue(
                "useCaseThreshold",
                useCaseThreshold[1],
                1
              )}
            </Typography>
          </Grid>
          <Grid item md={5} xs={12}>
            <Slider
            data-test-id="slider-Use Case Threshold:"
              value={useCaseThreshold}
              onChange={(e, newValue) => {
                setUseCaseThreshold(newValue);
                localStorage.setItem(
                  "useCaseThreshold",
                  JSON.stringify(newValue)
                );
              }}
              aria-labelledby="use-case-threshold-slider"
              min={0}
              max={5}
              step={0.1}
              marks={useCaseMarks}
              sx={useCaseSliderStyles}
              valueLabelDisplay="on"
            />
          </Grid> */}

          <Grid item md={5} xs={12}>
            <Typography
              data-test-id="name-Offer Score Threshold:"
              variant="body1"
              gutterBottom
            >
              <span
                style={{
                  width: `${spanWidth}`,
                  display: "inline-block",
                  marginRight: "2px",
                }}
              >
                Offer Score Threshold:
              </span>
              {renderEditableValue(
                "offerScoreThreshold",
                offerScoreThreshold,
                setOfferScoreThreshold,
                "offerScoreThreshold"
              )}
              :{100 - offerScoreThreshold}
            </Typography>
          </Grid>

          <Grid item md={5} xs={12}>
            <Slider
              data-test-id="slider-Offer Score Threshold:"
              value={offerScoreThreshold}
              onChange={(e, newValue) => {
                setOfferScoreThreshold(newValue);
                localStorage.setItem("offerScoreThreshold", newValue);
              }}
              aria-labelledby="offer-score-threshold-slider"
              min={0}
              max={100}
              sx={offerScoreSliderStyles}
              marks={offerScoremarks}
            />
          </Grid>
        </Grid>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: 2,
            mt: 2,
            ml: 2,
          }}
        >
          <Button
            style={{ width: "250px", marginTop: "20px" }}
            color="primary"
            variant="contained"
            disableRipple
            onClick={handleResetToDefault}
            sx={{ mt: 2 }}
          >
            <Typography data-test-id="button-Reset to Default" color="white">
              Reset to Default
            </Typography>
          </Button>
          <Button
            style={{ width: "250px" }}
            color="primary"
            variant="contained"
            disableRipple
            onClick={() => {
              saveAndContinue();
              navigate(
                `${prefix}/edit-scenario/scenario-settings/display-option`
              );
            }}
          >
            <Typography data-test-id="save&continue" color="white">
              Save & Continue
            </Typography>
          </Button>

          <SaveAndExit
            saveAndContinue={saveAndContinue}
            data={{
              minOfferRelevanceMandatory,
              minOfferRelevanceOverall,
              minOfferUtilizationMandatory,
              minOfferUtilizationOverall,
              offerScoreThreshold,
              useCaseThreshold,
            }}
            setIsLoading={setIsLoading}
            prefix={prefix}
          />
        </Box>
      </div>
      <Snackbar
        open={notification.open}
        autoHideDuration={6000}
        onClose={handleCloseNotification}
      >
        <Alert
          onClose={handleCloseNotification}
          severity={notification.severity}
        >
          {notification.message}
        </Alert>
      </Snackbar>
    </>
  );
};

export default ThresholdOption;
