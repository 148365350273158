import React, { useContext } from "react";
import { Button, Typography } from "@mui/material";
import { UserProfileContext } from "../../context/UserContext";
import { apiService } from "../../utils/utils";
import { useNavigate } from "react-router-dom";
function SaveAndExit({ saveAndContinue, data, setIsLoading, prefix }) {
  const {
    userProfile,
    currentScenarioId,
    currentBuyerGuideId,
    currentOrg,
    currentPackage,
  } = useContext(UserProfileContext);
  const navigate = useNavigate();
  const payload = {
    email: userProfile?.email,
    currentOrganisation: currentOrg,
    currentBuyerGuide: currentBuyerGuideId,
    currentScenario: currentScenarioId,
    currentPackage: currentPackage?.id,
  };
  const handleSaveAndExit = async () => {
    const getGridCoordinates = async () => {
      const response = await apiService(
        "https://newtestfuncpython.azurewebsites.net/api/getGridCoordinates?",
        {
          method: "POST",
          data: {
            ...payload,
          },
        }
      );
      return response;
    };
    const getOfferScore = async () => {
      const response = await apiService(
        "https://newtestfuncpython.azurewebsites.net/api/getOfferScore?",
        {
          method: "POST",
          data: {
            ...payload,
          },
        }
      );
      return response;
    };
    const getOfferUtilization = async () => {
      const response = await apiService(
        "https://newtestfuncpython.azurewebsites.net/api/offerUtilization?",
        {
          method: "POST",
          data: {
            ...payload,
          },
        }
      );
      return response;
    };
    const getOfferRelevance = async () => {
      const response = await apiService(
        "https://newtestfuncpython.azurewebsites.net/api/getOfferRelevance?",
        {
          method: "POST",
          data: {
            ...payload,
          },
        }
      );
      return response;
    };

    saveAndContinue().then(async () => {
      try {
        setIsLoading(true);
        const [response1, response2] = await Promise.all([
          getOfferRelevance(),
          getOfferUtilization(),
        ]);
        if (response1 && response2) {
          // Navigate to the next page after successful API calls
          const response3 = await getOfferScore();
          if (response3) {
            const reponse4 = await getGridCoordinates();
            if (reponse4) {
              navigate(`${prefix}/results/comparative-analysis`, {
                state: {
                  ...data,
                },
              });
            }
          }
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setIsLoading(false);
      }
    });
  };
  return (
    <Button
      data-testid="dashboard"
      style={{ width: "250px" }}
      color="secondary"
      variant="contained"
      disableRipple
      onClick={async () => {
        await handleSaveAndExit();
      }}
    >
      <Typography
        data-test-id="dashboard"
        data-testid="save&exit"
        color="white"
      >
        Save & Exit to Dashboard
      </Typography>
    </Button>
  );
}

export default SaveAndExit;
