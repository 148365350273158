import React, { useContext } from "react";
import { Navigate, useLocation } from "react-router-dom";
import { UserProfileContext } from "../context/UserContext";
import Loader from "../components/Loader";
const PrivateRoute = ({ children }) => {
  const location = useLocation();
  const { pathname } = location;
  const pathParts = pathname.split("/");
  const prefix = `/${pathParts[1]}`;
  const { userProfile, isProfileLoading } = useContext(UserProfileContext);
  // Show a loading indicator while the profile is being fetched
  if (isProfileLoading) {
    return <Loader open={isProfileLoading} />;
  }

  // Check if userProfile is fully loaded and contains required properties
  return userProfile &&
    userProfile?.companyName &&
    userProfile?.jobTitle &&
    userProfile?.profileType ? (
    children
  ) : (
    <Navigate to={`${prefix}/users`} />
  );
};

export default PrivateRoute;
