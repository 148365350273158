import React from "react";
import { Box, IconButton, TextField, Typography } from "@mui/material";
import { Delete as DeleteIcon } from "@mui/icons-material";
import { useTheme } from "@emotion/react";
import CustomButton from "../../../components/CustomButton";
import PopupAction from "../../../components/PopupAction";
import useUserUnit from "./Hook/useUserUnit";

const UserUnits = ({ id, setAllocatedData, maxFields, handleClose, allocatedData }) => {
  const theme = useTheme();

  // Using the custom hook
  const { formData, popup, response, buttonLoading, handleAdd, handleSubmit, handleButtonClick, handleFieldChange, handleDeleteRow, validateEmail } = useUserUnit({
    maxFields,
    totalQuantity: () => formData.reduce((total, item) => total + item.combinationUnits, 0),
    allocatedData,
    id,
    handleClose,
    setAllocatedData,
  });

  const renderFormRow = (index) => (
    <Box key={index} sx={{ display: "flex", alignItems: "center", gap: 2, width: "100%", mb: 2 }}>
      <Box sx={{ display: "flex", gap: "10px", [theme.breakpoints.down("md")]: { flexDirection: "column" }, width: "100%" }}>
        <TextField
          fullWidth
          label={`Email - ${index + 1}`}
          value={formData[index].emailId}
          onChange={(e) => handleFieldChange(index, "emailId", e.target.value)}
          variant="outlined"
          sx={{ flex: 1 }}
          type="email"
          size="medium"
          error={!validateEmail(formData[index].emailId) && formData[index].emailId !== ""}
          slotProps={{ inputLabel: { color: "black" } }}
          helperText={!validateEmail(formData[index].emailId) && formData[index].emailId !== "" ? "Enter a valid email" : ""}
        />
        <TextField
          fullWidth
          label="Quantity"
          sx={{ [theme.breakpoints.up("md")]: { width: "20%" } }}
          value={formData[index].combinationUnits}
          onChange={(e) => handleFieldChange(index, "combinationUnits", e.target.value)} // Value is stored as number
          variant="outlined"
          type="number"
          size="medium"
          placeholder="1"
          slotProps={{ htmlInput: { min: 1, max: maxFields }, inputLabel: { color: "black" } }}
        />
      </Box>
      <IconButton onClick={() => handleDeleteRow(index)} aria-label="delete" disabled={formData.length <= 1}>
        <DeleteIcon />
      </IconButton>
    </Box>
  );

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        gap: 3,
        padding: 2,
        backgroundColor: "#f5f5f5",
        borderRadius: 2,
        boxShadow: 2,
        margin: "auto",
        width: "100%",
        height: "100%",
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          width: "100%",
          marginBottom: "10px",
          [theme.breakpoints.down("md")]: { flexDirection: "column", alignItems: "flex-start" },
        }}
      >
        <Typography variant="h6" gutterBottom sx={{ textAlign: "left", textOverflow: "ellipsis", overflow: "hidden", whiteSpace: "nowrap", [theme.breakpoints.down("md")]: { width: "100%" } }}>
          Please Provide Email and Quantity
        </Typography>
        <Typography variant="h6" gutterBottom sx={{ textAlign: "left", whiteSpace: "nowrap", color: maxFields < 0 ? "red" : "black" }}>
          <Typography component="span" variant="inherit" style={{ fontWeight: "bold", marginRight: "5px", color: "inherit" }}>
            Remaining Units :
          </Typography>
          {maxFields - formData.reduce((total, item) => total + item.combinationUnits, 0)}
        </Typography>
      </Box>
      {formData.map((_, index) => renderFormRow(index))}
      <Box
        sx={{
          display: "flex",
          [theme.breakpoints.down("md")]: { flexDirection: "column" },
          gap: "10px",
        }}
      >
        <CustomButton
          variant="contained"
          onClick={() => handleAdd(formData[formData.length - 1].emailId)}
          sx={{ flex: 1, backgroundColor: theme.palette.primary.main }}
          disabled={buttonLoading || !validateEmail(formData[formData.length - 1]?.emailId)}
          text={buttonLoading ? "Checking Email..." : "Add"}
        />
        <CustomButton
          variant="contained"
          onClick={handleSubmit}
          sx={{ flex: 1, backgroundColor: theme.palette.primary.main }}
          text={"Submit"}
          disabled={formData.reduce((total, item) => total + item.combinationUnits, 0) !== maxFields || formData.some((item) => !validateEmail(item.emailId))}
        />
      </Box>
      <PopupAction open={popup} title={"Allocate Units to a New Email"} extraCss={{ width: "50%", height: "30%" }} showCross={false}>
        <Box style={{ textAlign: "center", padding: "10px", position: "relative", display: "flex", flexDirection: "column", alignItems: "center" }}>
          <Typography variant="h6" gutterBottom>
            Email:
            <Typography variant="p" gutterBottom sx={{ color: "red", marginX: "5px" }}>
              '{response?.email}'
            </Typography>
            does not exist in our database, but you can still allocate units to this email. Would you like to proceed?
          </Typography>
          <Box sx={{ gap: "10px", position: "absolute", bottom: 0, width: "100%", display: "flex", justifyContent: "center" }}>
            <CustomButton
              onClick={() => {
                handleButtonClick(true);
              }}
              sx={{ backgroundColor: theme.palette.primary.main }}
              text={"Yes"}
            />

            <CustomButton
              onClick={() => {
                handleButtonClick(false);
              }}
              sx={{ backgroundColor: theme.palette.secondary.main }}
              text={"No"}
            />
          </Box>
        </Box>
      </PopupAction>
    </Box>
  );
};

export default UserUnits;
