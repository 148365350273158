import { Close } from "@mui/icons-material";
import { Box, IconButton, Modal, Typography } from "@mui/material";
import React from "react";
import { useTheme } from "@emotion/react";
import PropTypes from "prop-types";

const PopupAction = React.forwardRef(function PopupAction({ children, open, title, handleClose = () => {}, extraCss = {}, showCross = true }, ref) {
  const theme = useTheme();

  return (
    <Modal
      open={open}
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        marginTop: "40px",
        width: "100%",
        height: "100%",
      }}
      aria-labelledby="popup-title"
      aria-describedby="popup-description"
    >
      <Box
        sx={{
          borderRadius: "10px",
          padding: "10px",
          overflow: "hidden",
          backgroundColor: "#fff",
          maxHeight: "90%",
          display: "flex",
          flexDirection: "column",
          [theme.breakpoints.down("md")]: { width: "100%" },
          ...extraCss,
        }}
      >
        <Box
          sx={{
            backgroundColor: theme.palette.primary.main,
            display: "flex",
            position: "relative",
            justifyContent: "space-between",
            gap: "30px",
            borderRadius: "5px 5px 0px 0px",
            alignItems: "center",
            // marginBottom: "10px",
          }}
        >
          <Typography id="popup-title" variant="h2" textAlign={"center"} sx={{ ...theme.typography.h3, color: "#fff", paddingY: "5px" }} flex={1}>
            {title}
          </Typography>
          {showCross && (
            <IconButton aria-label="close" onClick={handleClose} sx={{ position: "absolute", right: 0 }}>
              <Close sx={{ color: "white" }} />
            </IconButton>
          )}
        </Box>
        <Box sx={{ overflowY: "auto", height: "100%", paddingY: "5px", display: "flex", backgroundColor: "#f5f5f5", borderRadius: "0px 0px 5px 5px" }}>{children}</Box>
      </Box>
    </Modal>
  );
});

PopupAction.propTypes = {
  children: PropTypes.node.isRequired,
  open: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
  extraCss: PropTypes.object,
};

export default PopupAction;
