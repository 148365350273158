import React, { useEffect } from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Outlet,
  Navigate,
  useLocation,
  useNavigate,
} from "react-router-dom";
import {
  MsalProvider,
  AuthenticatedTemplate,
  useMsal,
  UnauthenticatedTemplate,
} from "@azure/msal-react";
import { ThemeProvider } from "@mui/material/styles";
import theme from "./theme/theme";
import { Watermark } from "@hirohe/react-watermark";
import Users from "./pages/Users";
import ShareReport from "./pages/ShareReport";
import UpdateUseCase from "./pages/TopNavPages/UpdateUseCase";
import SavedReports from "./pages/SavedReports.jsx";
import ViewReport from "./pages/ViewReports.jsx";
import ReportSettings from "./pages/ReportSettings.jsx";
import CollaborateUseCase from "./pages/TopNavPages/CollaborateUseCase";
import ComparativeAnalysis from "./pages/TopNavPages/DashBoard/ComparativeAnalysis";
import VendorDetails from "./pages/TopNavPages/DashBoard/VendorDetails";
import OfferDetails from "./pages/TopNavPages/DashBoard/OfferDetails";
import StartNewUseCase from "./pages/StartNewUseCase";
import VendorOptions from "./pages/FineTuneTopNav/VendorOptions";
import FineTune from "./pages/FineTuneTopNav/FineTune";
import UpdateUseCasesFineTune from "./pages/FineTuneTopNav/UpdateUseCasesFineTune";
import { loginRequest } from "./auth-config";
import Login from "./pages/Login";
import Layout from "./layout/Layout";
import EndUserPackages from "./pages/EndUserPackages";
import SelectEndUser from "./pages/SelectEndUser"; // Updated import name
import SelectBuyer from "./pages/SelectBuyer";
import InterestedBuyer from "./pages/InterestedBuyer";
import SelectScenario from "./pages/SelectScenario";
import PurchasePackSection from "./pages/PurchangePackSection";
import SubscriptionDetails from "./pages/SubscriptionDetails";
import PrivateRoute from "./routes/PrivateRoute";
import { UserProfileProvider } from "./context/UserContext.jsx";
import { StaticReportProvider } from "./context/StaticReportContext.jsx";
import { ComparitiveContextProvider } from "./context/ComparitiveContext.jsx";
import BundleSelection from "./pages/FineTuneTopNav/BundleSelection.jsx";
import ThresholdOption from "./pages/FineTuneTopNav/ThresholdOption.jsx";
import MandatoryDisplayOption from "./pages/FineTuneTopNav/MandatoryDisplayOption.jsx";
import ReportOptions from "./pages/FineTuneTopNav/ReportOptions.jsx";
import UsePurchasedPackage from "./pages/UsePurchasedPackage.jsx";
import WelcomePage from "./pages/Welcome.jsx";
import MainComponent from "../src/Analysis-Tool/MainComponent.js";
import AnalystWelcome from "./Analysis-Tool/AnalystWelcome.jsx";
import { CreateNewBuyerGuide } from "./Analysis-Tool/CreateNewBuyerGuide.jsx";
import BuyerGuideMenu from "./Analysis-Tool/BuyerGuideMenu.js";
import ReviewResultPage from "./Analysis-Tool/ReviewResultPage.js";
import StaticReport from "./pages/StaticReport.jsx";
import ModelAndParameter from "./Analysis-Tool/Model&Parameter.jsx";
import UsecaseToFeature from "./Analysis-Tool/UsecaseToFeature.jsx";
import VendorOfferPage from "./Analysis-Tool/VendorOfferPage.js";
import { AddNewOffer } from "./Analysis-Tool/AddNewOffer.js";
import VendorOfferMenu from "./Analysis-Tool/VendorOfferMenu.js";
import BuyerGuideMappingOneToOne from "./Analysis-Tool/BuyerGuideMappingOneToOne.js";
import MappingType from "./Analysis-Tool/MapingType.js";
import ReviewVendorOfferPage from "./Analysis-Tool/ReviewVendorOfferPage.js";
import GeneratedFeatures from "./Analysis-Tool/GeneratedFeatures.js";
import MappingRecommendation from "./Analysis-Tool/MappingRecommendation.jsx";
import VendorOfferMenuMapping from "./Analysis-Tool/VendorOfferMenuMapping.js";
import VendorOfferPageMapping from "./Analysis-Tool/VendorOfferPageMapping.js";
import AllocationWrapper from "./pages/Allocation/AllocationWrapper.jsx";
import UseCaseTree from "./Analysis-Tool/UseCaseTree/UseCaseTree.jsx";
import LaunchingSoon from "./pages/LaunchingSoon/LaunchingSoon.jsx";
import LaunchingSoonForLogOut from "./pages/LaunchingSoon/LaunchingSoonForLogOut.jsx";
const Pages = () => {
  const { instance } = useMsal();
  console.log(instance);
  const activeAccount = instance.getActiveAccount();
  const location = useLocation();
  const navigate = useNavigate();
  const handleSignInRedirect = async () => {
    localStorage.setItem("redirectPath", location.pathname);
    instance.loginRedirect({ ...loginRequest });
  };

  const handleSignUpRedirect = async () => {
    localStorage.setItem("redirectPath", location.pathname);
    instance.loginRedirect({ ...loginRequest, prompt: "create" });
  };

  const handleSignInRedirectForEmployee = async () => {
    localStorage.setItem("redirectPath", location.pathname);
    instance.loginRedirect({
      ...loginRequest,
      authority:
        "https://login.microsoftonline.com/smartdecisionai.onmicrosoft.com",
    });
    sessionStorage.setItem("userType", "employee");
  };
  useEffect(() => {
    const handleAuthCallback = async () => {
      try {
        if (activeAccount) {
          const response = await instance.handleRedirectPromise();
          if (response) {
            // Process the authentication response
            const redirectPath = localStorage.getItem("redirectPath") || "/";
            const firstPathSegment = `/${redirectPath.split("/")[1]}`;

            // Navigate to the saved path or a default one
            if (firstPathSegment === "/demo") {
              navigate("/demo");
            } else if (firstPathSegment === "/assistant") {
              navigate("/assistant");
            } else if (firstPathSegment === "/dev") {
              navigate("/dev");
            } else {
              navigate("/");
            }
          }
        }
      } catch (error) {
        console.error("Authentication Error:", error);
      }
    };

    handleAuthCallback();
    // eslint-disable-next-line
  }, []);
  const renderRoutes = (prefix) => (
    <>
      <Route path={`${prefix}/users`} element={<Users prefix={prefix} />} />
      <Route
        path={`${prefix}`}
        element={
          <PrivateRoute>
            <Watermark
              text={
                prefix === "/demo"
                  ? `\u00A0\u00A0\u00A0Only For Demo Purpose`
                  : null
              }
              opacity={0.45}
            >
              <WelcomePage prefix={prefix} />
            </Watermark>
          </PrivateRoute>
        }
      />
      <Route
        path={`${prefix}/start-new-use-case`}
        element={
          <Watermark
            text={
              prefix === "/demo"
                ? `\u00A0\u00A0\u00A0Only For Demo Purpose`
                : null
            }
            opacity={0.45}
          >
            <StartNewUseCase prefix={prefix} />
          </Watermark>
        }
      />

      <Route
        path={`${prefix}/sharereport`}
        element={
          <PrivateRoute>
            <Watermark
              text={
                prefix === "/demo"
                  ? `\u00A0\u00A0\u00A0Only For Demo Purpose`
                  : null
              }
              opacity={0.45}
            >
              <ShareReport prefix={prefix} />
            </Watermark>
          </PrivateRoute>
        }
      />
      <Route
        path={`${prefix}/select-end-user-org`}
        element={
          <PrivateRoute>
            <Watermark
              text={
                prefix === "/demo"
                  ? `\u00A0\u00A0\u00A0Only For Demo Purpose`
                  : null
              }
              opacity={0.45}
            >
              <SelectEndUser prefix={prefix} />
            </Watermark>
          </PrivateRoute>
        }
      />
      <Route
        path={`${prefix}/add-change-use-cases`}
        element={
          <PrivateRoute>
            <Watermark
              text={
                prefix === "/demo"
                  ? `\u00A0\u00A0\u00A0Only For Demo Purpose`
                  : null
              }
              opacity={0.45}
            >
              <UpdateUseCase prefix={prefix} />
            </Watermark>
          </PrivateRoute>
        }
      />
      <Route
        path={`${prefix}/collaborate-on-use-cases`}
        element={
          <PrivateRoute>
            <Watermark
              text={
                prefix === "/demo"
                  ? `\u00A0\u00A0\u00A0Only For Demo Purpose`
                  : null
              }
              opacity={0.45}
            >
              <CollaborateUseCase prefix={prefix} />
            </Watermark>
          </PrivateRoute>
        }
      />
      <Route path={`${prefix}/results`} element={<Outlet />}>
        <Route
          index
          element={
            <PrivateRoute>
              <Watermark
                text={
                  prefix === "/demo"
                    ? `\u00A0\u00A0\u00A0Only For Demo Purpose`
                    : null
                }
                opacity={0.45}
              >
                <Navigate to="comparative-analysis" replace />
              </Watermark>
            </PrivateRoute>
          }
        />
        <Route
          path="comparative-analysis"
          element={
            <PrivateRoute>
              <Watermark
                text={
                  prefix === "/demo"
                    ? `\u00A0\u00A0\u00A0Only For Demo Purpose`
                    : null
                }
                opacity={0.45}
              >
                <ComparativeAnalysis prefix={prefix} />
              </Watermark>
            </PrivateRoute>
          }
        />
        <Route
          path="vendor-details"
          element={
            <PrivateRoute>
              <Watermark
                text={
                  prefix === "/demo"
                    ? `\u00A0\u00A0\u00A0Only For Demo Purpose`
                    : null
                }
                opacity={0.45}
              >
                <VendorDetails prefix={prefix} />
              </Watermark>
            </PrivateRoute>
          }
        />
        <Route
          path="offer-details"
          element={
            <PrivateRoute>
              <Watermark
                text={
                  prefix === "/demo"
                    ? `\u00A0\u00A0\u00A0Only For Demo Purpose`
                    : null
                }
                opacity={0.45}
              >
                <OfferDetails prefix={prefix} />
              </Watermark>
            </PrivateRoute>
          }
        />
      </Route>
      <Route
        path={`${prefix}/select-scenario`}
        element={
          <PrivateRoute>
            <Watermark
              text={
                prefix === "/demo"
                  ? `\u00A0\u00A0\u00A0Only For Demo Purpose`
                  : null
              }
              opacity={0.45}
            >
              <SelectScenario prefix={prefix} />
            </Watermark>
          </PrivateRoute>
        }
      />
      <Route path={`${prefix}/edit-scenario`} element={<Outlet />}>
        <Route index element={<Navigate to="vendor-options" replace />} />
        <Route
          path="vendor-options"
          element={
            <PrivateRoute>
              <Watermark
                text={
                  prefix === "/demo"
                    ? `\u00A0\u00A0\u00A0Only For Demo Purpose`
                    : null
                }
                opacity={0.45}
              >
                <VendorOptions prefix={prefix} />
              </Watermark>
            </PrivateRoute>
          }
        />
        <Route
          path="update-use-cases"
          element={
            <PrivateRoute>
              <Watermark
                text={
                  prefix === "/demo"
                    ? `\u00A0\u00A0\u00A0Only For Demo Purpose`
                    : null
                }
                opacity={0.45}
              >
                <UpdateUseCasesFineTune prefix={prefix} />
              </Watermark>
            </PrivateRoute>
          }
        />
        <Route path="scenario-settings" element={<Outlet />}>
          <Route index element={<Navigate to="bundle-option" replace />} />
          <Route
            path="bundle-option"
            element={
              <PrivateRoute>
                <Watermark
                  text={
                    prefix === "/demo"
                      ? `\u00A0\u00A0\u00A0Only For Demo Purpose`
                      : null
                  }
                  opacity={0.45}
                >
                  <BundleSelection prefix={prefix} />
                </Watermark>
              </PrivateRoute>
            }
          />
          <Route
            path="threshold-option"
            element={
              <PrivateRoute>
                <Watermark
                  text={
                    prefix === "/demo"
                      ? `\u00A0\u00A0\u00A0Only For Demo Purpose`
                      : null
                  }
                  opacity={0.45}
                >
                  <ThresholdOption prefix={prefix} />
                </Watermark>
              </PrivateRoute>
            }
          />
          <Route
            path="display-option"
            element={
              <PrivateRoute>
                <Watermark
                  text={
                    prefix === "/demo"
                      ? `\u00A0\u00A0\u00A0Only For Demo Purpose`
                      : null
                  }
                  opacity={0.45}
                >
                  <MandatoryDisplayOption prefix={prefix} />
                </Watermark>
              </PrivateRoute>
            }
          />
          <Route
            path="report-settings"
            element={
              <PrivateRoute>
                <Watermark
                  text={
                    prefix === "/demo"
                      ? `\u00A0\u00A0\u00A0Only For Demo Purpose`
                      : null
                  }
                  opacity={0.45}
                >
                  <ReportOptions prefix={prefix} />
                </Watermark>
              </PrivateRoute>
            }
          />
        </Route>
      </Route>

      <Route path={`${prefix}/select-buyer-guide`} element={<Outlet />}>
        <Route
          index
          element={
            <Navigate
              to={`${prefix}/select-buyer-guide/available-buyer-guides`}
              replace
            />
          }
        />
        <Route
          path={`${prefix}/select-buyer-guide/available-buyer-guides`}
          element={
            <PrivateRoute>
              <Watermark
                text={
                  prefix === "/demo"
                    ? `\u00A0\u00A0\u00A0Only For Demo Purpose`
                    : null
                }
                opacity={0.45}
              >
                <SelectBuyer prefix={prefix} />
              </Watermark>
            </PrivateRoute>
          }
        />
        <Route
          path={`${prefix}/select-buyer-guide/buyer-guide-of-interest`}
          element={
            <PrivateRoute>
              <Watermark
                text={
                  prefix === "/demo"
                    ? `\u00A0\u00A0\u00A0Only For Demo Purpose`
                    : null
                }
                opacity={0.45}
              >
                <InterestedBuyer prefix={prefix} />
              </Watermark>
            </PrivateRoute>
          }
        />
      </Route>
      <Route
        path={`${prefix}/claim-single-package`}
        element={
          <PrivateRoute>
            <Watermark
              text={
                prefix === "/demo"
                  ? `\u00A0\u00A0\u00A0Only For Demo Purpose`
                  : null
              }
              opacity={0.45}
            >
              <UsePurchasedPackage prefix={prefix} />
            </Watermark>
          </PrivateRoute>
        }
      />
      <Route
        path={`${prefix}/finetune`}
        element={
          <PrivateRoute>
            <Watermark
              text={
                prefix === "/demo"
                  ? `\u00A0\u00A0\u00A0Only For Demo Purpose`
                  : null
              }
              opacity={0.45}
            >
              <FineTune prefix={prefix} />
            </Watermark>
          </PrivateRoute>
        }
      />
      <Route
        path={`${prefix}/packages`}
        element={
          <PrivateRoute>
            <Watermark
              text={
                prefix === "/demo"
                  ? `\u00A0\u00A0\u00A0Only For Demo Purpose`
                  : null
              }
              opacity={0.45}
            >
              <EndUserPackages prefix={prefix} />
            </Watermark>
          </PrivateRoute>
        }
      />
      <Route
        path={`${prefix}/available-units-for-allocation`}
        element={
          <PrivateRoute>
            <Watermark
              text={
                prefix === "/demo"
                  ? `\u00A0\u00A0\u00A0Only For Demo Purpose`
                  : null
              }
              opacity={0.45}
            >
              <AllocationWrapper prefix={prefix} />
            </Watermark>
          </PrivateRoute>
        }
      />
      <Route
        path={`${prefix}/purchase-section`}
        element={
          <PrivateRoute>
            <Watermark
              text={
                prefix === "/demo"
                  ? `\u00A0\u00A0\u00A0Only For Demo Purpose`
                  : null
              }
              opacity={0.45}
            >
              <PurchasePackSection prefix={prefix} />
            </Watermark>
          </PrivateRoute>
        }
      />
      <Route
        path={`${prefix}/my-subscriptions`}
        element={
          <PrivateRoute>
            <Watermark
              text={
                prefix === "/demo"
                  ? `\u00A0\u00A0\u00A0Only For Demo Purpose`
                  : null
              }
              opacity={0.45}
            >
              <SubscriptionDetails prefix={prefix} />
            </Watermark>
          </PrivateRoute>
        }
      />
      <Route
        path={`${prefix}/view-reports`}
        element={
          <PrivateRoute>
            <Watermark
              text={
                prefix === "/demo"
                  ? `\u00A0\u00A0\u00A0Only For Demo Purpose`
                  : null
              }
              opacity={0.45}
            >
              <ViewReport prefix={prefix} />
            </Watermark>
          </PrivateRoute>
        }
      />
      <Route
        path={`${prefix}/saved-reports`}
        element={
          <PrivateRoute>
            <Watermark
              text={
                prefix === "/demo"
                  ? `\u00A0\u00A0\u00A0Only For Demo Purpose`
                  : null
              }
              opacity={0.45}
            >
              <SavedReports prefix={prefix} />
            </Watermark>
          </PrivateRoute>
        }
      />
      <Route
        path={`${prefix}/analyst-welcome`}
        element={
          <PrivateRoute>
            <Watermark
              text={
                prefix === "/demo"
                  ? `\u00A0\u00A0\u00A0Only For Demo Purpose`
                  : null
              }
              opacity={0.45}
            >
              <AnalystWelcome prefix={prefix} />
            </Watermark>
          </PrivateRoute>
        }
      />
      <Route
        path={`${prefix}/use-case-tree`}
        element={
          <PrivateRoute>
            <Watermark
              text={
                prefix === "/demo"
                  ? `\u00A0\u00A0\u00A0Only For Demo Purpose`
                  : null
              }
              opacity={0.45}
            >
              <UseCaseTree prefix={prefix} />
            </Watermark>
          </PrivateRoute>
        }
      />

      <Route
        path={`${prefix}/create-new-buyer-guide`}
        element={
          <PrivateRoute>
            <Watermark
              text={
                prefix === "/demo"
                  ? `\u00A0\u00A0\u00A0Only For Demo Purpose`
                  : null
              }
              opacity={0.45}
            >
              <CreateNewBuyerGuide prefix={prefix} />
            </Watermark>
          </PrivateRoute>
        }
      />
      <Route
        path={`${prefix}/analysis`}
        element={
          <PrivateRoute>
            <Watermark
              text={
                prefix === "/demo"
                  ? `\u00A0\u00A0\u00A0Only For Demo Purpose`
                  : null
              }
              opacity={0.45}
            >
              <MainComponent prefix={prefix} />
            </Watermark>
          </PrivateRoute>
        }
      />
      <Route
        path={`${prefix}/buyer-guide-menu`}
        element={
          <PrivateRoute>
            <Watermark
              text={
                prefix === "/demo"
                  ? `\u00A0\u00A0\u00A0Only For Demo Purpose`
                  : null
              }
              opacity={0.45}
            >
              <BuyerGuideMenu prefix={prefix} />
            </Watermark>
          </PrivateRoute>
        }
      />
      <Route
        path={`${prefix}/sub-use-cases`}
        element={
          <PrivateRoute>
            <Watermark
              text={
                prefix === "/demo"
                  ? `\u00A0\u00A0\u00A0Only For Demo Purpose`
                  : null
              }
              opacity={0.45}
            >
              <ReviewResultPage prefix={prefix} />
            </Watermark>
          </PrivateRoute>
        }
      />
      <Route
        path={`${prefix}/report-settings`}
        element={
          <PrivateRoute>
            <Watermark
              text={
                prefix === "/demo"
                  ? `\u00A0\u00A0\u00A0Only For Demo Purpose`
                  : null
              }
              opacity={0.45}
            >
              <ReportSettings prefix={prefix} />
            </Watermark>
          </PrivateRoute>
        }
      />
      <Route
        path={`${prefix}/show-static-report`}
        element={
          <PrivateRoute>
            <Watermark
              text={
                prefix === "/demo"
                  ? `\u00A0\u00A0\u00A0Only For Demo Purpose`
                  : null
              }
              opacity={0.45}
            >
              <StaticReport prefix={prefix} />
            </Watermark>
          </PrivateRoute>
        }
      />
      <Route
        path={`${prefix}/model-parameter`}
        element={
          <PrivateRoute>
            <Watermark
              text={
                prefix === "/demo"
                  ? `\u00A0\u00A0\u00A0Only For Demo Purpose`
                  : null
              }
              opacity={0.45}
            >
              <ModelAndParameter prefix={prefix} />
            </Watermark>
          </PrivateRoute>
        }
      />
      <Route
        path={`${prefix}/usecase-to-feature`}
        element={
          <PrivateRoute>
            <Watermark
              text={
                prefix === "/demo"
                  ? `\u00A0\u00A0\u00A0Only For Demo Purpose`
                  : null
              }
              opacity={0.45}
            >
              <UsecaseToFeature prefix={prefix} />
            </Watermark>
          </PrivateRoute>
        }
      />
      <Route
        path={`${prefix}/vendor-offer-page`}
        element={
          <PrivateRoute>
            <Watermark
              text={
                prefix === "/demo"
                  ? `\u00A0\u00A0\u00A0Only For Demo Purpose`
                  : null
              }
              opacity={0.45}
            >
              <VendorOfferPage prefix={prefix} />
            </Watermark>
          </PrivateRoute>
        }
      />
      <Route
        path={`${prefix}/vendor-offer-menu`}
        element={
          <PrivateRoute>
            <Watermark
              text={
                prefix === "/demo"
                  ? `\u00A0\u00A0\u00A0Only For Demo Purpose`
                  : null
              }
              opacity={0.45}
            >
              <VendorOfferMenu prefix={prefix} />
            </Watermark>
          </PrivateRoute>
        }
      />
      <Route
        path={`${prefix}/add-new-offer`}
        element={
          <PrivateRoute>
            <Watermark
              text={
                prefix === "/demo"
                  ? `\u00A0\u00A0\u00A0Only For Demo Purpose`
                  : null
              }
              opacity={0.45}
            >
              <AddNewOffer prefix={prefix} />
            </Watermark>
          </PrivateRoute>
        }
      />
      <Route
        path={`${prefix}/review-vendor-offer`}
        element={
          <PrivateRoute>
            <Watermark
              text={
                prefix === "/demo"
                  ? `\u00A0\u00A0\u00A0Only For Demo Purpose`
                  : null
              }
              opacity={0.45}
            >
              <ReviewVendorOfferPage prefix={prefix} />
            </Watermark>
          </PrivateRoute>
        }
      />
      <Route
        path={`${prefix}/mapping-type`}
        element={
          <PrivateRoute>
            <Watermark
              text={
                prefix === "/demo"
                  ? `\u00A0\u00A0\u00A0Only For Demo Purpose`
                  : null
              }
              opacity={0.45}
            >
              <MappingType prefix={prefix} />
            </Watermark>
          </PrivateRoute>
        }
      />
      <Route
        path={`${prefix}/buyer-guide-mapping-one-to-one`}
        element={
          <PrivateRoute>
            <Watermark
              text={
                prefix === "/demo"
                  ? `\u00A0\u00A0\u00A0Only For Demo Purpose`
                  : null
              }
              opacity={0.45}
            >
              <BuyerGuideMappingOneToOne prefix={prefix} />
            </Watermark>
          </PrivateRoute>
        }
      />
      <Route
        path={`${prefix}/vendor-offer-page-mapping`}
        element={
          <PrivateRoute>
            <Watermark
              text={
                prefix === "/demo"
                  ? `\u00A0\u00A0\u00A0Only For Demo Purpose`
                  : null
              }
              opacity={0.45}
            >
              <VendorOfferPageMapping prefix={prefix} />
            </Watermark>
          </PrivateRoute>
        }
      />
      <Route
        path={`${prefix}/vendor-offer-menu-mapping`}
        element={
          <PrivateRoute>
            <Watermark
              text={
                prefix === "/demo"
                  ? `\u00A0\u00A0\u00A0Only For Demo Purpose`
                  : null
              }
              opacity={0.45}
            >
              <VendorOfferMenuMapping prefix={prefix} />
            </Watermark>
          </PrivateRoute>
        }
      />
      <Route
        path={`${prefix}/generated-features`}
        element={
          <PrivateRoute>
            <Watermark
              text={
                prefix === "/demo"
                  ? `\u00A0\u00A0\u00A0Only For Demo Purpose`
                  : null
              }
              opacity={0.45}
            >
              <GeneratedFeatures prefix={prefix} />
            </Watermark>
          </PrivateRoute>
        }
      />
      <Route
        path={`${prefix}/overall-recommendations`}
        element={
          <PrivateRoute>
            <Watermark
              text={
                prefix === "/demo"
                  ? `\u00A0\u00A0\u00A0Only For Demo Purpose`
                  : null
              }
              opacity={0.45}
            >
              <MappingRecommendation prefix={prefix} />
            </Watermark>
          </PrivateRoute>
        }
      />
      <Route
        path={`${prefix}/usecase-recommendations`}
        element={
          <PrivateRoute>
            <Watermark
              text={
                prefix === "/demo"
                  ? `\u00A0\u00A0\u00A0Only For Demo Purpose`
                  : null
              }
              opacity={0.45}
            >
              <MappingRecommendation prefix={prefix} />
            </Watermark>
          </PrivateRoute>
        }
      />
      <Route
        path={`${prefix}/feature-recommendation`}
        element={
          <PrivateRoute>
            <Watermark
              text={
                prefix === "/demo"
                  ? `\u00A0\u00A0\u00A0Only For Demo Purpose`
                  : null
              }
              opacity={0.45}
            >
              <MappingRecommendation prefix={prefix} />
            </Watermark>
          </PrivateRoute>
        }
      />
    </>
  );

  return (
    <>
      <AuthenticatedTemplate>
        {activeAccount ? (
          <Layout>
            <Routes>
              <Route path="/" element={<LaunchingSoon />} />

              {/* Render routes for /demo */}
              {renderRoutes("/demo")}
              {/* Render routes for /assistant */}
              {renderRoutes("/assistant")}
              {/* Render routes for /dev */}
              {renderRoutes("/dev")}
              {/* Catch-all route */}
              <Route path="*" element={<>Not Found</>} />
            </Routes>
          </Layout>
        ) : null}
      </AuthenticatedTemplate>
      <UnauthenticatedTemplate>
        <Routes>
          {/* Redirect "/" to "/demo" */}
          <Route path="/" element={<LaunchingSoonForLogOut />} />
          {/* Render routes for /demo */}
          <Route
            path={`/assistant/*`}
            element={
              <Login
                prevPath={location.pathname}
                handleSignInRedirect={handleSignInRedirect}
                handleSignUpRedirect={handleSignUpRedirect}
                handleSignInRedirectForEmployee={
                  handleSignInRedirectForEmployee
                }
              />
            }
          />
          <Route
            path={`/demo/*`}
            element={
              <Login
                prevPath={location.pathname}
                handleSignInRedirect={handleSignInRedirect}
                handleSignUpRedirect={handleSignUpRedirect}
                handleSignInRedirectForEmployee={
                  handleSignInRedirectForEmployee
                }
              />
            }
          />
          <Route
            path={`/dev/*`}
            element={
              <Login
                prevPath={location.pathname}
                handleSignInRedirect={handleSignInRedirect}
                handleSignUpRedirect={handleSignUpRedirect}
                handleSignInRedirectForEmployee={
                  handleSignInRedirectForEmployee
                }
              />
            }
          />
          {/* Catch-all route */}
          <Route path="*" element={<>Not Found</>} />
        </Routes>
      </UnauthenticatedTemplate>
    </>
  );
};

function App({ msalInstance }) {
  return (
    <Router>
      <ThemeProvider theme={theme}>
        <MsalProvider instance={msalInstance}>
          <UserProfileProvider>
            <ComparitiveContextProvider>
              <StaticReportProvider>
                <Pages />
              </StaticReportProvider>
            </ComparitiveContextProvider>
          </UserProfileProvider>
        </MsalProvider>
      </ThemeProvider>
    </Router>
  );
}

export default App;
