import React, { useEffect } from "react";
import { ReactFlow, ReactFlowProvider, Controls, Background, BackgroundVariant, MiniMap } from "@xyflow/react";
import { Box } from "@mui/material";
import useTreeMapData from "./hooks/useTreeMapData";
import { useElkLayout } from "./hooks/useElkLayout";
import { useTreeGraph } from "./hooks/useTreeGraph";
import { useMergeNodes } from "./hooks/useMergeNodes";
import { RootNode, ChildrenNode } from "./Node";
import data from "./data.json";
import "@xyflow/react/dist/style.css";
import MergeNodesDialog from "./UI/MergeNodesDialog";
import ConnectWithParent from "./UI/ConnectWithParent";
import NodeMenu from "./UI/Menu";
import useDragAndDropFeature from "./hooks/useDragAndDropFeature";
import useCaseTree from "./hooks/useCaseTree";
import useMenu from "./hooks/useMenu";
import useParentChange from "./hooks/useParentChange";
import useDeleteNode from "./hooks/useDeleteNode";
import useHidden from "./hooks/useHidden";

const nodeTypes = {
  rootNode: RootNode,
  childNode: ChildrenNode,
};
const UseCaseTree = () => {
  const { initialnodes, initialedges } = useTreeMapData(data);
  const { getLayoutedElements } = useElkLayout();
  const { nodes, edges, onNodesChange, onEdgesChange, onConnect, setNodes, setEdges } = useTreeGraph(initialnodes, initialedges, getLayoutedElements);
  const { menuPosition, setMenuPosition, selectedNode, setSelectedNode, selectedNodes, setSelectedNodes, layouted, setLayouted, underProcess, setUnderProcess, handleNodeClick } = useCaseTree();
  const { onNodeContextMenu, closeMenu } = useMenu(setSelectedNode, setMenuPosition);
  const { onNodeDragStop } = useDragAndDropFeature(nodes, setSelectedNodes, setMenuPosition, setSelectedNode);
  const { getMaxDepth } = useHidden(setNodes, setLayouted, nodes, edges);

  // Options hooks
  const {
    mergeDialogOpen,
    mergeDialogTypes,
    parentSelectionOpen,
    availableParents,
    handleMergeNodes,
    handleParentSelect,
    setMergeDialogOpen,
    handleMergeDialogSubmit,
    setParentSelectionOpen,
    mergeAndReplaceNode,
  } = useMergeNodes(nodes, edges, setNodes, setEdges, selectedNodes, setLayouted, setUnderProcess);
  const { reparentNodeWithDescendants, reassignDirectAndIndirectDescendants } = useParentChange(setEdges, selectedNodes, setLayouted);
  const { deleteNodeAndDescendants, deleteNodeRetainDescendant } = useDeleteNode(setNodes, selectedNode, setEdges, setSelectedNode, setMenuPosition, edges, setLayouted);

  // console.log(nodes)
  useEffect(() => {
    const layoutNodes = async () => {
      if (!layouted) {
        await getLayoutedElements(nodes, edges, setNodes);
        setLayouted(true);
      }
    };
    layoutNodes();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [layouted, initialnodes]);

  useEffect(() => {
    if (!underProcess) {
      setSelectedNodes([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [underProcess]);

  const handleAction = (action) => {
    switch (action) {
      case "deleteNodeAndDescendants":
        deleteNodeAndDescendants();
        setSelectedNodes([]);
        break;
      case "deleteNodeRetainChildren":
        deleteNodeRetainDescendant();
        setSelectedNodes([]);
        break;
      // -------------------------------------------
      case "reparentWithDescendants":
        reparentNodeWithDescendants();
        setSelectedNodes([]);
        break;
      case "reparentChildlessNodeToParent":
        reparentNodeWithDescendants();
        setSelectedNodes([]);
        break;
      case "reassignDescendantsToTargetNode":
        reassignDirectAndIndirectDescendants();
        setSelectedNodes([]);
        break;
      // -------------------------------------------
      case "mergeBothNodes":
        handleMergeNodes();
        setSelectedNodes([]);
        break;
      case "mergeAndReplace":
        mergeAndReplaceNode();
        setSelectedNodes([]);
        break;
      default:
        break;
    }
  };

  return (
    <ReactFlowProvider>
      <Box style={{ height: "85vh", position: "relative", pointerEvents: "all" }}>
        <ReactFlow
          minZoom={0.2}
          maxZoom={2}
          deleteKeyCode={null}
          nodes={nodes.map((node) => ({
            ...node,
            data: {
              ...node.data,
              getMaxDepth: getMaxDepth, // Add getMaxDepth to each node
            },
          }))}
          edges={edges}
          onNodesChange={onNodesChange}
          onEdgesChange={onEdgesChange}
          onConnect={onConnect}
          nodeTypes={nodeTypes}
          style={{ background: "#F7F9FB" }}
          onNodeContextMenu={onNodeContextMenu}
          onNodeDragStop={onNodeDragStop}
          elementsSelectable={true}
          panOnDrag={true}
          onNodeClick={handleNodeClick}
          multiSelectionKeyCode="Shift"
          onPaneClick={null}
          onPaneDoubleClick={null}
          zoomOnDoubleClick={false}
        >
          <Background color="#000" variant={BackgroundVariant.Dots} />
          <Controls />
          <MiniMap maskColor="rgb(0,0,0, 0.1)" />
        </ReactFlow>
        <NodeMenu menuPosition={menuPosition} closeMenu={closeMenu} onAction={handleAction} selectedNode={selectedNode} selectedNodes={selectedNodes} nodes={nodes} edges={edges} />
        <ConnectWithParent open={parentSelectionOpen} parents={availableParents} onClose={() => setParentSelectionOpen(false)} onSelect={handleParentSelect} />
        <MergeNodesDialog
          open={mergeDialogOpen}
          onClose={() => {
            setMergeDialogOpen(false);
            setLayouted(false);
          }}
          onSubmit={handleMergeDialogSubmit}
          types={mergeDialogTypes}
        />
      </Box>
    </ReactFlowProvider>
  );
};

export default UseCaseTree;
