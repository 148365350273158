import React, { useState } from "react";
import PopupAction from "../../../components/PopupAction";
import CustomButton from "../../../components/CustomButton";
import { Box, MenuItem, Select, TextField } from "@mui/material";
import { useTheme } from "@emotion/react";
export default function MergeNodesDialog({ open, onClose, onSubmit, types }) {
  const [label, setLabel] = useState("");
  const [type, setType] = useState("");
  const theme = useTheme();
  const handleSubmit = () => {
    if (label) {
      onSubmit({ label, type });
    }
  };
  return (
    <PopupAction showCross={false} open={open} title={"Configure Merged Node"} extraCss={{ minWidth: "30%", maxWidth: "80%" }}>
      <Box sx={{ display: "flex", flexDirection: "column", width: "100%", gap: "10px" }}>
        <Box sx={{ display: "flex", flexDirection: "column", gap: "10px" }}>
          <TextField fullWidth margin="dense" label="Node Label" value={label} onChange={(e) => setLabel(e.target.value)} />
          {types.length > 0 && (
            <Select fullWidth margin="dense" value={type} onChange={(e) => setType(e.target.value)} displayEmpty>
              <MenuItem value="" disabled>
                Select Node Type
              </MenuItem>
              {types.map((t) => (
                <MenuItem key={t} value={t}>
                  {t}
                </MenuItem>
              ))}
            </Select>
          )}
        </Box>
        <Box sx={{ gap: "10px", display: "flex", justifyContent: "center", alignItems: "center" }}>
          <CustomButton onClick={handleSubmit} disabled={!label || (Array.isArray(type) && type.length === 0)} text={"Submit"} sx={{ backgroundColor: theme.palette.primary.main }} />
          <CustomButton onClick={onClose} text={"Cancel"} sx={{ backgroundColor: theme.palette.secondary.main }} />
        </Box>
      </Box>
    </PopupAction>
  );
}
