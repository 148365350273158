export default function useDeleteNode(setNodes, selectedNode, setEdges, setSelectedNode, setMenuPosition, edges, setLayouted) {
  const findDescendants = (nodeId, edges) => {
    const directChildren = edges.filter((edge) => edge.source === nodeId).map((edge) => edge.target);
    return directChildren.reduce((acc, childId) => [...acc, childId, ...findDescendants(childId, edges)], []);
  };

  const deleteNode = () => {
    if (selectedNode) {
      // Update nodes by filtering out the selected node
      setNodes((prevNodes) => prevNodes.filter((node) => node.id !== selectedNode.id));
      // Update edges by filtering out connections to/from the selected node
      setEdges((prevEdges) => prevEdges.filter((edge) => edge.source !== selectedNode.id && edge.target !== selectedNode.id));
      // Reset selected node and menu position
      setSelectedNode(null);
      setMenuPosition(null);
      setLayouted(false);
    }
  };

  const deleteNodeAndDescendants = () => {
    if (selectedNode) {
      const descendants = findDescendants(selectedNode.id, edges);

      // Update nodes by removing all descendants
      setNodes((prevNodes) => prevNodes.filter((node) => !descendants.includes(node.id)));

      // Update edges by removing connections related to descendants
      setEdges((prevEdges) => prevEdges.filter((edge) => !descendants.includes(edge.source) && !descendants.includes(edge.target)));

      // Reuse deleteNode to handle the selected node removal
      deleteNode();
    }
  };

  const deleteNodeRetainDescendant = () => {
    if (selectedNode) {
      // Find parent nodes of the selected node
      const parentEdges = edges.filter((edge) => edge.target === selectedNode.id);
      const parentIds = parentEdges.map((edge) => edge.source);

      // Find children of the selected node
      const childEdges = edges.filter((edge) => edge.source === selectedNode.id);
      const childIds = childEdges.map((edge) => edge.target);

      // If the selected node has a parent, reassign its children to the parent(s)
      if (parentIds.length > 0) {
        parentIds.forEach((parentId) => {
          childIds.forEach((childId) => {
            setEdges((prevEdges) => [...prevEdges, { id: `${parentId}-${childId}`, source: parentId, target: childId, animated: true, type: "default" }]);
          });
        });
      }

      // Remove edges connected to the selected node
      setEdges((prevEdges) => prevEdges.filter((edge) => edge.source !== selectedNode.id && edge.target !== selectedNode.id));

      // Remove the selected node
      setNodes((prevNodes) => prevNodes.filter((node) => node.id !== selectedNode.id));

      // Clear the selected node state
      setSelectedNode(null);
      setMenuPosition(null);
      setLayouted(false);
    }
  };

  return {
    deleteNodeAndDescendants,
    deleteNodeRetainDescendant,
  };
}
