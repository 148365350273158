import React, { useContext } from "react";
import { Box } from "@mui/material";
import DataGridTable from "../../components/DataGridTable";
import Loader from "../../components/Loader";
import theme from "../../theme/theme";
import CustomButton from "../../components/CustomButton";
import { AllocationContext } from "./AllocationContext";
import { Popups } from "./Popups";
import { useAllocation } from "./hooks/useAllocation";
import { columnData } from "./ColumnData";

export default function Allocation({ popover, setPopover }) {
  const { allocatedData, userProfile, handelClose, handlePreviewClose, handelAllocationOfPrescribedUnits, handelUserResponse, previewPopover, setPreviewPopover } = useAllocation(setPopover);
  const { setSectionData, vendorPopup, vendorTab, setVendorTab, loading, sectionData, popoverContent, setPopoverContent, setAllocatedData } = useContext(AllocationContext);

  const updatedColumn = columnData(setSectionData, userProfile, setPopover, setPopoverContent, setAllocatedData);

  return loading ? (
    <Loader open={loading} />
  ) : (
    <Box
      sx={{
        width: "100%",
        marginTop: 4,
        borderRadius: "10px",
        display: "flex",
        flexDirection: "column",
        height: "100%",
        gap: "10px",
      }}
    >
      <Box
        sx={{
          "& .super-app-theme--header": {
            backgroundColor: theme.palette.primary.main,
            borderBottom: "none",
            height: "fit-content",
          },
          boxShadow: 1,
          borderRadius: "10px",
        }}
      >
        <DataGridTable rows={sectionData} columns={updatedColumn} page={"allocation"} />
      </Box>
      <CustomButton
        text={"Allocate"}
        onClick={handelAllocationOfPrescribedUnits}
        variant={"contained"}
        sx={{ backgroundColor: theme.palette.primary.main, marginTop: "10px" }}
        disabled={allocatedData.length === 0 || allocatedData?.some((item) => !item.userList || item.userList.length === 0)}
      />
      <CustomButton
        text={"Preview"}
        onClick={() => {
          setPreviewPopover(true);
        }}
        variant={"contained"}
        sx={{ backgroundColor: theme.palette.secondary.main, marginTop: "10px" }}
        disabled={allocatedData.length === 0 || allocatedData?.some((item) => !item.userList || item.userList.length === 0)}
      />

      <Popups
        popover={popover}
        previewPopover={previewPopover}
        vendorPopup={vendorPopup}
        vendorTab={vendorTab}
        popoverContent={popoverContent}
        allocatedData={allocatedData}
        handelClose={handelClose}
        handlePreviewClose={handlePreviewClose}
        setAllocatedData={setAllocatedData}
        handelUserResponse={handelUserResponse}
        setVendorTab={setVendorTab}
        handelAllocationOfPrescribedUnits={handelAllocationOfPrescribedUnits}
      />
    </Box>
  );
}

/**
 * front to back
  record =
[
    {
        "id": "4:34da1ea9-71dd-41f1-9959-64fc52b2fd41:32",
        "purchasedPackageName": "All Available",
        "userUnits": 1,
        "userList": [{ "emailId": "Nitin@gmail.com", "combinationUnits": 1 }],
        "euoFlag":false,
        "euoList": ["NitinEuO1"],
        "bgList": [
            {
                "id": "4:34da1ea9-71dd-41f1-9959-64fc52b2fd41:132",
                "title": "Ucbg_1"
                vendors:[] //array of vendors id
            },
            {
                "id": "4:34da1ea9-71dd-41f1-9959-64fc52b2fd41:133",
                "title": "Ucbg_2"
                vendrs:[] //array of vendors id
            },
            {
                "id": "4:34da1ea9-71dd-41f1-9959-64fc52b2fd41:134",
                "title": "Ucbg_3"
            },
            {
                "id": "4:34da1ea9-71dd-41f1-9959-64fc52b2fd41:135",
                "title": "Ucbg_4"
            },
            {
                "id": "4:34da1ea9-71dd-41f1-9959-64fc52b2fd41:136",
                "title": "Ucbg_5"
            }           
            ],
        "totalUnits": 5,
        "allocationType": "Partially Prescribed (UCBG)"
    }
]
users:["harsh@gmail.com","kishan@gmail.com"]


JSON for checking user existence

userList : ["harsh@gmail.com","kishan@gmail.com"]
[
  {
    email:"harsh@gmail.com",
    status:true
  },{
    email:"harsh@gmail.com",
    status:true
  }
]
 */
