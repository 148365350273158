import React, { useContext } from "react";
import { useNavigate } from "react-router-dom";
import CommonForm from "./reusable/CommonForm";
import { UserProfileContext } from "../context/UserContext";

export const AddNewOffer = () => {
  const navigate = useNavigate();
  const VendorId = localStorage.getItem("currentVendorId");
  // console.log("vendor ID for Add New Offer  -->", VendorId);
  const { userProfile, prefix } = useContext(UserProfileContext);

  const handleCreateOfferAI = async (name, version, description) => {
    try {
      // Define the payload for the createOffer API
      const payload = {
        email: userProfile.email,
        currentVendorId: VendorId,
        newOfferName: name,
        version: parseInt(version),
      };

      // Call the createOffer API
      const response = await fetch(
        "https://newtestfuncpython.azurewebsites.net/api/createOffer",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(payload),
        }
      );

      // Parse the response
      const data = await response.json();

      if (response.ok) {
        const { offerId } = data;

        // Store the offerId in localStorage
        // localStorage.setItem('selectedOfferId', offerId);

        // Call the getExistingOfferFeatures API with the stored offerId
        const analysisResponse = await fetch(
          "https://newtestfuncpython.azurewebsites.net/api/getExistingOfferFeatures",
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              email: userProfile.email,
              currentOfferId: offerId,
            }),
          }
        );

        const analysisData = await analysisResponse.json();

        if (analysisResponse.ok) {
          // Navigate to the JsonInput page and pass the fetched data
          navigate(`${prefix}/analysis`, {
            state: { analysisData, fromAddNewOffer: true },
          });
          // console.log("Analysis Data-->", analysisData);
        } else {
          console.error("Failed to fetch analysis data:", analysisData);
        }
      } else {
        console.error("Failed to create offer:", data);
      }
    } catch (error) {
      console.error("Error creating offer:", error);
    }
  };

  const handleExtractFeaturesNonAI = async (documentLink, analystPrompt) => {
    // Implement functionality for non-AI extraction
  };

  return (
    <CommonForm
      title="Add New Offer"
      nameLabel="Name of the Offer:"
      versionLabel="Version:"
      descriptionLabel="Description:"
      radioLabel="Generate with AI?"
      documentLinkLabel="Document Link:"
      analystPromptLabel="Analyst Prompt:"
      buttonLabelAI="Add New Offer"
      buttonLabelNonAI="Extract Features"
      handleSubmitAI={handleCreateOfferAI}
      handleSubmitNonAI={handleExtractFeaturesNonAI}
    />
  );
};
