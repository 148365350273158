import React, { useState } from "react";
import PopupAction from "../../../components/PopupAction";
import CustomButton from "../../../components/CustomButton";
import { Box, MenuItem, Select } from "@mui/material";
import { useTheme } from "@emotion/react";
export default function ConnectWithParent({ open, onClose, onSelect, parents }) {
  const [selectedParentId, setSelectedParentId] = useState("");
  const theme = useTheme();
  const handleSubmit = () => {
    if (selectedParentId) {
      onSelect(selectedParentId);
    }
  };
  return (
    <PopupAction showCross={false} open={open} title={"Configure Merged Node"} extraCss={{ minWidth: "30%", maxWidth: "80%" }}>
      <Box sx={{ display: "flex", flexDirection: "column", width: "100%", gap: "10px" }}>
        <Select fullWidth value={selectedParentId} onChange={(e) => setSelectedParentId(e.target.value)} displayEmpty>
          <MenuItem value="" disabled>
            Select a Parent Node
          </MenuItem>
          {parents.map((parent) => (
            <MenuItem key={parent.id} value={parent.id}>
              {parent.label}
            </MenuItem>
          ))}
        </Select>
        <Box sx={{ gap: "10px", display: "flex", justifyContent: "center", alignItems: "center" }}>
          <CustomButton onClick={handleSubmit} disabled={!selectedParentId} text={"Submit"} sx={{ backgroundColor: theme.palette.primary.main }} />
          <CustomButton onClick={onClose} text={"Cancel"} sx={{ backgroundColor: theme.palette.secondary.main }} />
        </Box>
      </Box>
    </PopupAction>
  );
}
